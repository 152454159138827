import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>
        <span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB51JYLihf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECEhExQf/aAAgBAQABBQLESVWqoeuH/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AWR//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAABABETH/2gAIAQEABj8CIxW//8QAGhAAAwADAQAAAAAAAAAAAAAAAAERMUFhUf/aAAgBAQABPyGTtQuGPhxEhetiwf/aAAwDAQACAAMAAAAQ1x//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EMhjt//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EKr/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhMUFRYYH/2gAIAQEAAT8QxVXu4DCK7Q7Agz9gna1nTmk58SjoJ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "crowd wide",
            "title": "crowd wide",
            "src": "/static/dc4f34821b8edd872efc62a4eb76074b/6a068/crowd_wide.jpg",
            "srcSet": ["/static/dc4f34821b8edd872efc62a4eb76074b/f93b5/crowd_wide.jpg 300w", "/static/dc4f34821b8edd872efc62a4eb76074b/b4294/crowd_wide.jpg 600w", "/static/dc4f34821b8edd872efc62a4eb76074b/6a068/crowd_wide.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div"><strong parentName="figcaption">{`A CROWD OF 13,200 PEOPLE`}</strong>{` heard the Gospel during the God Loves You Tour in Kraków—and more than 730 professed decisions for Jesus Christ.`}</figcaption>{`
  `}<p parentName="div">{`
   `}<strong parentName="p">{`Nyasha* was among the hundreds who boldly walked to the front.`}</strong>{` The 25-year-old 
  college student had seen an ad for the God Loves You Tour and asked her friend to 
  attend with her. Nyasha is new to Poland after moving from Zimbabwe just a few months
   `}<span parentName="p" {...{
            "className": "image-float-left"
          }}>{`
        `}<span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "960px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABnyitTCIO/wD/xAAaEAACAgMAAAAAAAAAAAAAAAABAgAhAxIT/9oACAEBAAEFAhTU86hJsSEJONrb/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BGf/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AUn/xAAZEAADAAMAAAAAAAAAAAAAAAAAARECIXH/2gAIAQEABj8CqFqcIhujyGf/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhYTFBUZH/2gAIAQEAAT8hOPUeGLVKi6Ok1aFoBPIwXIfYBFGdT//aAAwDAQACAAMAAAAQZ+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QhGv/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EHskw//EABwQAAICAwEBAAAAAAAAAAAAAAERADEhQVFh8P/aAAgBAQABPxASHzE92vRHo7BEGCc/XFhhtoE+nFw/1BMQH1Q5nl2TZ7cKl5M2n//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "counsel krakow men",
                "title": "counsel krakow men",
                "src": "/static/0bec12104cb3aa0b68d18153629ac7e5/6a068/counsel_krakow_men.jpg",
                "srcSet": ["/static/0bec12104cb3aa0b68d18153629ac7e5/f93b5/counsel_krakow_men.jpg 300w", "/static/0bec12104cb3aa0b68d18153629ac7e5/b4294/counsel_krakow_men.jpg 600w", "/static/0bec12104cb3aa0b68d18153629ac7e5/6a068/counsel_krakow_men.jpg 960w"],
                "sizes": "(max-width: 960px) 100vw, 960px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>{`
    `}</span>{`
  ago—and when her friend turned her down, she was worried about attending alone. She 
  came anyway and listened closely to the Good News. The Holy Spirit worked in Nyasha’s
  heart and mind, and she prayed to place her faith and trust in Jesus Christ. 
  `}</p>{`
    `}<p parentName="div">{`
     `}<strong parentName="p">{`“I feel light, I feel free,” she said afterward. “I need Jesus in my life.” `}</strong>{`
    One of our prayer volunteers gave Nyasha a new Bible and invited her to attend 
    church with her the following Sunday. Please join us in praying for her—and for 
    every person who made a life-changing decision for the Lord in Kraków. Right now, 
    hundreds of churches are working hard to follow up with these brothers and sisters. 
    Ask God to strengthen them as they minister too. 
    `}</p>{`
    `}<p parentName="div">{`
     While I was in Poland, I went to Auschwitz, where more than 1.3 million people
    were deported during the Holocaust—and most of these were Jews who were later killed 
    there. As I stood in the chamber where men, women, and children were executed years 
    ago, I thought about the alarming
     recent rise of antisemitism.`}<strong parentName="p">{` The same evil that led 
    to these atrocities is thriving in the world today. The only solution to the depravity 
    of the human heart is the transforming power of the Gospel.`}</strong>{` I’m grateful for every 
    opportunity the Lord gives me to continue proclaiming His truth—and for believers like 
    you who make this ministry possible.
     `}</p>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAD/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAWBMjNDMj//EABsQAAICAwEAAAAAAAAAAAAAAAECACEDBBAR/9oACAEBAAEFAjseTM7vx2sWDP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAARATEQIUH/2gAIAQEABj8C0kXKKFwjH//EABoQAQADAQEBAAAAAAAAAAAAAAEAETEhQZH/2gAIAQEAAT8hVYjnfY44TBnIevyjvZBTP//aAAwDAQACAAMAAAAQV/8A/8QAFxEBAAMAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxAMuP/EABYRAQEBAAAAAAAAAAAAAAAAABEAAf/aAAgBAgEBPxBcm//EAB0QAQEAAwACAwAAAAAAAAAAAAERACExQYFRcdH/2gAIAQEAAT8QkBHprWj1g6zKjCz1jDoP2XEKCivyRya9UnfGJ1N74/uf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "fg auschwitz",
            "title": "fg auschwitz",
            "src": "/static/d2170b19c37ae44132f399a40b4d8808/6a068/fg_auschwitz.jpg",
            "srcSet": ["/static/d2170b19c37ae44132f399a40b4d8808/f93b5/fg_auschwitz.jpg 300w", "/static/d2170b19c37ae44132f399a40b4d8808/b4294/fg_auschwitz.jpg 600w", "/static/d2170b19c37ae44132f399a40b4d8808/6a068/fg_auschwitz.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div"><strong parentName="figcaption">{`FRANKLIN GRAHAM `}</strong>{`spent time at Auschwitz, where more than 1.3 million men, women, and children— most of them Jews—were deported during the Holocaust.`}</figcaption>{`
    
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "evangelistic-crusades"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span></h3>
        <h4 parentName="div">{`POINTING PEOPLE TO THE GOD OF ALL COMFORT`}</h4>{`
    `}<p parentName="div">{`
     Before the God Loves You Tour in Kraków, our Billy Graham Rapid Response Team 
    (BG-RRT) chaplains hosted`}<em parentName="p">{` God’s Hope in Crisis`}</em>{` trainings for local pastors.
    `}</p>{`
    `}<p parentName="div">{`
     This training is designed to equip Christians—leaders and laypeople—to share 
    the love of Jesus Christ with people suffering in times of crisis.`}<strong parentName="p">{` Over the last 
    two years, our chaplains have helped equip many pastors in Poland to teach their 
    congregations about what to say—and not say—to their hurting loved ones, neighbors, 
    and co-workers.`}</strong>{` Please pray with us that God will embolden all those who attended 
    to faithfully share His love in the wake of tragedy.
    `}</p>{`
    `}<p parentName="div">{`
     BG-RRT chaplains have also been working hard at home to share God’s love 
    with hurting people—including those in Burnsville, Minnesota, where two law 
    enforcement officers and a firefighter were shot and killed during a domestic 
    dispute.`}<strong parentName="p">{` While at a makeshift memorial for the officers, a couple of chaplains 
    met a group of four friends.`}</strong>{` When the conversation turned to faith, they all 
    admitted they didn’t know the Lord—and had never heard the Gospel. By the time 
    the chaplains had finished talking, all four were in tears. They each prayed 
    with “`}<em parentName="p">{`repentance that leads to salvation without regret`}</em>{`” (2 Corinthians 7:10, ESV). 
    In the following days, a local Bible-teaching church followed up with the four 
    friends so they can grow closer to the Lord.
    `}</p>{`
    `}<p parentName="div">{`
     As part of our ministry to law enforcement, we are hosting several 
    upcoming weekend retreats. These special times are designed to renew and 
    encourage officers and their spouses through fellowship, worship, and 
    teaching from God’s Word.
    `}</p>
      </div>
      <div>
        <h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "evangelistic-crusades"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`EVANGELISTIC CRUSADES`}</span></h3>
        <h4 parentName="div">{`SHARING THE GOSPEL IN PORTUGAL’S SECOND CITY`}</h4>{`
 `}<p parentName="div">{`
   `}<strong parentName="p">{`As I write, my son Will is preparing to preach the Good News in Porto, Portugal`}</strong>{`. Like Kraków, many people in this city call themselves Christians—but for the vast majority, it is just a cultural identity. Will you pray that men, women, and children from all over this city will 
  “`}<em parentName="p">{`be born again to a living hope through the resurrection of Jesus Christ from the dead`}</em>{`” (1 Peter 1:3, ESV) 
  during the Celebration? `}<strong parentName="p">{`So far, more than 2,000 believers from more than 100 churches have trained to pray with those who want to make a decision for Jesus Christ. Please pray for these individuals too.`}</strong>{` Ask God to give them wisdom and boldness as they share His truth.
  `}</p>{`
  `}<p parentName="div">{`
        Thank you for your commitment to sharing the Good News of Jesus Christ with a broken, hurting world. You are critical to this evangelistic ministry, and I thank God for you.
    `}</p>
      </div>
      <br></br>
      <div>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham`}</p>{`
    `}<p parentName="div">{`President & CEO`}</p>{`
    `}<br parentName="div"></br>{`
    `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>
      </div>
      <hr></hr>
      <div {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
    `}</p>{`
     `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`INTERNATIONAL GOD LOVES YOU TOURS`}</span>{` | Through your gift for the`}<strong parentName="p">{` International God Loves You Tours`}</strong>{`, you can help share the life-changing power of His Gospel with individuals in cities around the world, including Birmingham, England, and Glasgow, Scotland, this June.
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM `}</span>{` | Your contribution in support of the`}<strong parentName="p">{` Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the Will Graham Celebration in Porto, Portugal, and the Gold Coast Look Up Celebration in Australia.
    `}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      