export const styles = {
  main: {
    flex: '1 0 auto',
    lineHeight: '1.5',
    boxSizing: 'inherit',
    display: 'block',
    fontFamily: 'proxima-nova',
  },
};

export default styles;
