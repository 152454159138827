import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`As we enter into the Thanksgiving season, `}<strong parentName="p">{`I want to personally thank you for your partnership in evangelism`}</strong>{`—and for your prayers for the thousands of people affected by recent hurricanes. Many of them live close to my family home in Western North Carolina. Thousands more are reeling from the paths of destruction Helene and Milton cut across the Southeast.`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/oct/rrt-asheville-pray.jpg",
          "alt": ""
        }}></img>{`
  `}<figcaption parentName="div">{`Asheville, NC`}</figcaption>{`
  `}</div>{`
  `}<p parentName="div"><strong parentName="p">{`Our Billy Graham Rapid Response Team (BG-RRT) chaplains have deployed to several states, including Florida and North Carolina.`}</strong>{` They are ministering to people who have lost everything—homes, livelihoods, pets, and most tragically, loved ones. Many of these individuals are traumatized from the devastation they experienced. I’m so thankful for the crisis-trained chaplains who are taking the time to offer a listening ear and point people to Jesus Christ. You and I can join with believers across our nation to help provide them with physical and spiritual resources—but He is the only One who can heal their hearts.`}</p>{`
  `}<p parentName="div">{`We saw this happen when chaplains met Steven* in the high country along the North Carolina and Tennessee border. They parked next to his car that had clearly been flooded in the storm and began talking to him. They discussed the storm and his physical needs. During the course of the conversation, `}<strong parentName="p">{`Steven admitted that he lived in fear, isolated and alone.`}</strong>{` The chaplains gently shared the truth of Jesus—His love, forgiveness, and power over all things—and asked if he wanted to find true peace. `}<strong parentName="p">{`Steven surrendered his life to Christ, and the transformation was visible.`}</strong>{` Steven went from being dark and depressed—unable to look the chaplains in the eyes—to smiling clearly, full of love and joy.`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/oct/rrt-asheville-chaplain.jpg",
          "alt": ""
        }}></img>{`
  `}<figcaption parentName="div">{`Crisis-trained chaplains minister to those affected by Hurricane Helene, which claimed hundreds of lives and destroyed countless homes across the Southeast.`}</figcaption>{`
  `}</div>{`
  `}<p parentName="div">{`In Florida, chaplains met Evelyn,* whose home had been damaged by falling trees. After a brief initial conversation, they left a copy of “Steps to Peace With God.” Evelyn’s dependence on an oxygen tank meant she had to spend as much time inside as possible. She decided to read the pamphlet—and prayed on her own to receive Jesus Christ. When chaplains returned some time later, they were thrilled as she excitedly told them about her life-changing decision. They gave her a new Bible and prayed with her.`}</p>{`
  `}<p parentName="div">{`We thank God that the Billy Graham Training Center at The Cove—located in Asheville, North Carolina—was not seriously damaged in the hurricane. We’ve paused regular ministry events to house chaplains, Samaritan’s Purse relief workers, and North Carolina State Highway Patrol troopers while they serve across the region in response to the storm and massive flooding. Will you pray for these men and women and all those affected by the storm?`}</p>{`
  `}<p parentName="div">{`Every day, we’re seeing souls saved for eternity through BG-RRT—and other ministries. You are a major part of this Gospel work through your prayerful support.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      