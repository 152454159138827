const europeanCountries = [
  'AX',
  'AL',
  'AD',
  'AM',
  'AT',
  'AZ',
  'BY',
  'GG',
  'JE',
  'BA',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FO',
  'FI',
  'FR',
  'GE',
  'DE',
  'GI',
  'GR',
  'GL',
  'HU',
  'IS',
  'IE',
  'IM',
  'IT',
  'KZ',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'MK',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'TR',
  'UA',
  'GB',
];

export default europeanCountries;
