import React from 'react';

const donationHeaderWedInWord = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart to draw closer to Him, and it’s our prayer
        that <em>Wednesday in the Word</em> will help you “walk in newness of
        life” (Romans 6:4, NASB). A link to your first{' '}
        <em>Wednesday in the Word</em> is headed to your inbox now. And you will
        begin receiving the weekly devotional email this coming Wednesday
        morning.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer the hope of
        Jesus Christ through materials like this because of friends like you.
        The Lord is using the gifts and prayers of other believers to reach
        people across the globe with His love—and there are so many more who
        need to hear the Good News. Will you give them the opportunity to hear
        it?
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift of $50 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderWedInWord;
