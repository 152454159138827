import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentGLYT from "../../../../src/content/god-loves-you-tours/main-content-glyt.md";
import LearnMoreGLYT from "../../../../src/content/god-loves-you-tours/learn-more-details-glyt.md";
import "../../../../src/content/god-loves-you-tours/prayer-letter-glyt.scss";
import * as React from 'react';
export default {
  SingleDonationForm,
  MainContentGLYT,
  LearnMoreGLYT,
  React
};