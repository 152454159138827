import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationLoResponse = () => (
  <div>
    <Block>
      <p>ຂອບໃຈ! ແບບຟອມຂອງທ່ານໄດ້ຖືກສົ່ງໄປແລ້ວ.</p>
    </Block>
  </div>
);

export default customConfirmationLoResponse;
