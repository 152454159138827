import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "main-wrap"
    }}>{`
    `}<div parentName="div" {...{
        "className": "content-wrapper"
      }}>{`
        `}<div parentName="div" {...{
          "className": "margins"
        }}>{`
            `}<div parentName="div" {...{
            "className": "content-container english"
          }}>{`
            `}<h1 parentName="div" {...{
              "className": "top-title"
            }}><span parentName="h1">{`TIME OF HOPE FESTIVAL`}</span></h1>{`
            `}<h1 parentName="div" {...{
              "className": "bottom-title"
            }}>{`WITH WILL GRAHAM`}</h1>{`
                `}<div parentName="div" {...{
              "className": "text"
            }}>{`
                    `}<p parentName="div">{`The Time of Hope Festival with Will Graham will take place at the Tondiraba Arena, in the city of Tallinn, Estonia, Saturday and Sunday, October 18-19, 2025. It will feature a message of hope from evangelist Will Graham and a free concert by top Christian artists. Churches in the city of Tallinn and throughout Estonia are working together to host this special outreach for the community.`}</p>{`
                    `}<div parentName="div" {...{
                "className": "event-date"
              }}>{`
                        `}<p parentName="div">{`OCTOBER`}</p>{`
                        `}<p parentName="div"><span parentName="p">{`18`}</span>{`&`}<span parentName="p">{`19`}</span></p>{`
                        `}<p parentName="div">{`2025`}</p>{`
                    `}</div>{`
                    `}<p parentName="div" {...{
                "className": "text-location"
              }}>{`Tondiraba Arena, Tallinn, Estonia`}</p>{`
                `}</div>{`
            `}</div>{`
            `}<div parentName="div" {...{
            "className": "feat-img",
            "id": "desktop"
          }}>{`
                `}<img parentName="div" {...{
              "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/10/will-graham.png",
              "alt": "Will Graham"
            }}></img>{`
            `}</div>{`
        `}</div>{`
    `}</div>{`
    `}<div parentName="div" {...{
        "className": "pattern-block",
        "id": "desktop"
      }}>{` `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      