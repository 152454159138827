import { NewNames as NewNamesForm } from '@bgea-js/form-templates';
import customConfirmationBiblicalGuidance from "../../../../src/content/biblical-guidance-donate/customConfirmation";
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import "../../../../src/content/biblical-guidance-donate/biblical-guidance-donate.scss";
import * as React from 'react';
export default {
  NewNamesForm,
  customConfirmationBiblicalGuidance,
  SingleDonationForm,
  React
};