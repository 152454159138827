import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAEDAgQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAfL6YqzRgKT3MQg//8QAGhAAAwADAQAAAAAAAAAAAAAAAAECEBESMv/aAAgBAQABBQLQoXFrVDeIK9EvP//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BRt//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwFj/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAAhEg/9oACAEBAAY/AoLbyWX/AP/EABoQAAMBAAMAAAAAAAAAAAAAAAABESExQWH/2gAIAQEAAT8hQjFPtFRQx8Jt+IwhaNGNIQq2g9Y3p//aAAwDAQACAAMAAAAQu9w//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQIVH/2gAIAQMBAT8QbR2FH//EABgRAAMBAQAAAAAAAAAAAAAAAAERIQAQ/9oACAECAQE/EEUwEvf/xAAbEAEBAQADAQEAAAAAAAAAAAABEQAhMVFBYf/aAAgBAQABPxAPu7KEpJ3JgZAnmJp8U9G5AepRJzoBzeMgv3Nk8moUvW6kVszi+OSt/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Franklin Graham at the podium.",
            "title": "Franklin Graham at the podium.",
            "src": "/static/7e8618569cab1ed0d08cc2c1c4018f85/c08c5/19416_fg_podium_tour_230404.jpg",
            "srcSet": ["/static/7e8618569cab1ed0d08cc2c1c4018f85/f93b5/19416_fg_podium_tour_230404.jpg 300w", "/static/7e8618569cab1ed0d08cc2c1c4018f85/b4294/19416_fg_podium_tour_230404.jpg 600w", "/static/7e8618569cab1ed0d08cc2c1c4018f85/c08c5/19416_fg_podium_tour_230404.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</div>{`
    `}<p parentName="div">{`The new year is only days away, and people are making resolutions they believe will change their lives in 2024 through things like diets, budgets, or exercise.`}</p>{`
    `}<p parentName="div">{`The best these can offer is temporary satisfaction. Only a relationship with Jesus Christ, who `}<em parentName="p">{`“is the same yesterday and today and forever”`}</em>{` (Hebrews 13:8, ESV) will satisfy their longing hearts and bring true peace, hope, and joy.`}</p>{`
    `}<p parentName="div">{`God has given us many opportunities in 2024 to tell people about the peace He alone offers—and I’m so thankful for friends like you who partner with us in this critical mission.`}</p>{`
    `}<p parentName="div">{`The Lord has opened the door for me to preach His truth around the world—from our country’s southern border during the God Loves You Frontera Tour at the beginning of the year to Kraków, Poland, in April. I’ll also hold a two-day, evangelistic Festival in Mexico City in February, and I’ll return to the United Kingdom next summer for God Loves You Tour events in Birmingham and Glasgow.`}</p>{`
    `}<p parentName="div">{`Will you begin praying now for these Gospel outreaches? The challenges in these cities are vastly different, but people’s desperate need for Jesus Christ is the same.
`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      