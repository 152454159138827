import React from 'react';
import hpCustomModal from "./customModalText"

const customModalHeaderBGF = ({ firstName }) => (
  <div>
  <h2>
    Good news{' '} {firstName}! Your Biblical Guidance bundle should deliver in 4 to 6 weeks.
  </h2>
  {/* <br></br> */}
  {hpCustomModal}
</div>
);

export default customModalHeaderBGF;
