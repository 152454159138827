import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div {...{
        "id": "invited-to-the-cross"
      }}>{`
    `}<h4 parentName="div">{`Invited to the Cross`}</h4>{`
    `}<p parentName="div">{` When Karen heard that Franklin Graham would be sharing the Gospel during the God Loves You Tour event in Glasgow, she immediately thought of her daughter’s mother-in-law, Teresa.* “I heard she was interested in Christianity,” she said. “`}<strong parentName="p">{`I knew exactly who I was going to invite.`}</strong>{`” Teresa agreed to attend, and the two listened closely as Franklin preached the Good News. At the end of the event, he invited all those who wanted to make a decision for the Lord to walk forward. With Karen by her side, Teresa eagerly went ahead, ready to place her faith and trust in Jesus Christ. “`}<strong parentName="p">{`I want Jesus,” she said.`}</strong>{` Both women left that night, filled with “`}<em parentName="p">{`fresh joy in the Lord`}</em>{`” (Isaiah 29:19, ESV).`}</p>{`
    `}<div parentName="div">{`
        `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB5F2M1cQD/8QAGRABAAIDAAAAAAAAAAAAAAAAAQASESFB/9oACAEBAAEFAqkqYqRN8J//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwGq/8QAFxABAAMAAAAAAAAAAAAAAAAAEAARMf/aAAgBAQAGPwI2U//EAB0QAAICAQUAAAAAAAAAAAAAAAABESExQVFhsfH/2gAIAQEAAT8hfmLKd9GwaUtCLvkwP//aAAwDAQACAAMAAAAQC9//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QS0v/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Qhl//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhQTFhcZGx/9oACAEBAAE/EA2KnUAqtnGDbcBKVPEJQqQjssuA+JMzz7n/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "glasgow crowd 240602 tp1 7119 enhanced",
              "title": "glasgow crowd 240602 tp1 7119 enhanced",
              "src": "/static/820de41d652e251b453849169aa756b5/6a068/glasgow-crowd_240602_tp1_7119-enhanced.jpg",
              "srcSet": ["/static/820de41d652e251b453849169aa756b5/f93b5/glasgow-crowd_240602_tp1_7119-enhanced.jpg 300w", "/static/820de41d652e251b453849169aa756b5/b4294/glasgow-crowd_240602_tp1_7119-enhanced.jpg 600w", "/static/820de41d652e251b453849169aa756b5/6a068/glasgow-crowd_240602_tp1_7119-enhanced.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
        `}<figcaption parentName="div">{`MORE THAN 7,600 PEOPLE gathered to hear the Good News in Glasgow, Scotland, and over 520 responded by making public decisions for Jesus Christ.`}</figcaption>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "id": "curious-about-christianity"
        }}>{`
    `}<h4 parentName="div">{`Curious About Christianity`}</h4>{`
    `}<p parentName="div">{` Ian* attended the tour stop in Glasgow out of curiosity. His wife is a Christian—but he was not and he did not attend church with her. Now in his 50s, `}<strong parentName="p">{`he wanted to hear more about Jesus Christ.`}</strong>{` Ian heard Franklin Graham’s message from Mark 10, about Bartimaeus—a blind man whose sight was restored when he called out to Jesus in faith. Franklin explained that each of us are spiritually blind apart from a personal relationship with the Savior and then urged all those who longed for that relationship to come forward. 
     `}<span parentName="p" {...{
              "className": "image-float-left"
            }}>{`
        `}<span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "960px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABXJrs9ZLDf//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMSERP/2gAIAQEAAQUCosPkQWVLSqq2YLOTU//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/ARZ//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AYF//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAERASMVFh/9oACAEBAAY/Am257lbG4AFVvTg1j//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExYUGR/9oACAEBAAE/IXCBGeoSLIVXyfXjMSxuyNUC63BUbx2f/9oADAMBAAIAAwAAABBj3//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8Q0IrP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EHESwv/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExUWFBgcH/2gAIAQEAAT8QHyShQ2+9xg0Q68d58weAlo7Xn1c08aOAJXiQyVZTrwUVH//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "ian glasgow 240602 tp2 7551 enhanced",
                  "title": "ian glasgow 240602 tp2 7551 enhanced",
                  "src": "/static/1ad77aaacbd20048a9024b44f8800186/6a068/ian_glasgow_240602_tp2_7551-enhanced.jpg",
                  "srcSet": ["/static/1ad77aaacbd20048a9024b44f8800186/f93b5/ian_glasgow_240602_tp2_7551-enhanced.jpg 300w", "/static/1ad77aaacbd20048a9024b44f8800186/b4294/ian_glasgow_240602_tp2_7551-enhanced.jpg 600w", "/static/1ad77aaacbd20048a9024b44f8800186/6a068/ian_glasgow_240602_tp2_7551-enhanced.jpg 960w"],
                  "sizes": "(max-width: 960px) 100vw, 960px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span>{`
    `}</span>{`
    `}<strong parentName="p">{`“You come to Jesus,” he said.`}</strong>{` “Let Him heal your heart—let Him set you free tonight.” Ian was visibly emotional as he did just that. `}<strong parentName="p">{`He prayed 
    a heartfelt prayer of repentance and faith before one of our prayer counselors gave him a new Bible`}</strong>{` and discipleship materials. Please pray that in the coming days, Ian will earnestly seek “`}<em parentName="p">{`Jesus, the founder and perfecter of our faith, who for the joy that was set before him endured the cross`}</em>{`” (Hebrews 12:2, ESV).`}</p>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "id": "hungry-for-gods-word"
        }}>{`
    `}<h4 parentName="div">{`Hungry for God’s Word`}</h4>{`
    `}<p parentName="div">{` 
    Bilyana* is originally from Bulgaria. She attended the tour event in Birmingham, where she was deeply touched by the Gospel message.`}<strong parentName="p">{` When Franklin Graham gave the invitation, Bilyana eagerly stood and walked forward to “`}<em parentName="strong">{`repent and believe in the gospel`}</em>{`”`}</strong>{` 
    (Mark 1:15, ESV). The front of the arena 
    `}<span parentName="p" {...{
              "className": "image-float-right"
            }}>{`
        `}<span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "960px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABWibMTiIj/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxESMv/aAAgBAQABBQJAObcBWzmB2nt9n//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFtf//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AUf/xAAZEAADAQEBAAAAAAAAAAAAAAAAARExECH/2gAIAQEABj8Ca09dcIKOEbxc/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/IUah0InCYLiF12Idr0m3ysJaf//aAAwDAQACAAMAAAAQND//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EA3kAYN//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBB/9oACAECAQE/EJDl/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITGhQWGBkf/aAAgBAQABPxBo1HAE9HWaSmGrXSLeDfjBKzTqb/TLRHfkYBiukLicjn//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "birmingham praise lady",
                  "title": "birmingham praise lady",
                  "src": "/static/938bd53654a5a80bf79ad1f9a18b5fc1/6a068/birmingham-praise-lady.jpg",
                  "srcSet": ["/static/938bd53654a5a80bf79ad1f9a18b5fc1/f93b5/birmingham-praise-lady.jpg 300w", "/static/938bd53654a5a80bf79ad1f9a18b5fc1/b4294/birmingham-praise-lady.jpg 600w", "/static/938bd53654a5a80bf79ad1f9a18b5fc1/6a068/birmingham-praise-lady.jpg 960w"],
                  "sizes": "(max-width: 960px) 100vw, 960px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span>{`
    `}</span>{`
    was packed, but Bilyana eventually connected with a local pastor who was serving as a prayer counselor. He talked to her about the life-changing decision she had just made, and she told him how eager she was to begin reading God’s Word—but explained that she cannot read English very well. Bilyana asked him if there was any way he could help her get a Bible in one of the Slovak languages she is able to read. The pastor took down all of her information so we can help connect her with a Bible-believing church. He promised to get a copy of God’s Word—along with discipleship resources—to her as soon as possible. `}<strong parentName="p">{`He prayed with Bilyana and congratulated her on her new life in Christ.`}</strong>{`
    `}</p>{`
    `}</div>{`
     `}<div parentName="div" {...{
          "id": "she-counted-the-cost"
        }}>{`
    `}<h4 parentName="div" {...{
            "id": "mending-marriages-in-alaska"
          }}>{`She Counted the Cost`}</h4>{`
    `}<p parentName="div">{` June* was invited to the God Loves You Tour event in Birmingham, England, by friends from church. She had just started attending, but `}<strong parentName="p">{`when they invited her to ride with them on a bus, she agreed.`}</strong>{` June listened closely to the Gospel message that Franklin shared. As people on every side of her walked forward to surrender their lives to the Lord she remained at her seat. The Holy Spirit had started working in her heart as she heard the Good News that “`}<em parentName="p">{`God shows his love for us in that while we were still sinners, Christ died for us`}</em>{`” (Romans 5:8, ESV) but she wasn’t ready to make a decision. The next morning she went back to church—and `}<strong parentName="p">{` something changed. June freely prayed to embrace salvation`}</strong>{` in Jesus Christ. Will you pray that she will learn to follow Him as she continues to attend church, read her Bible, and pray?`}</p>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "id": "from-friends-to-sisters"
        }}>{`
    `}<h4 parentName="div" {...{
            "id": "from-friends-to-sisters"
          }}>{`From Friends to Sisters`}</h4>{`
    `}<p parentName="div">{` 
    Meghan* and Ella* were among the hundreds of people who 
    `}<span parentName="p" {...{
              "className": "image-float-right"
            }}>{`
        `}<span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "960px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHi3BCIB//EABoQAAIDAQEAAAAAAAAAAAAAAAECABESISL/2gAIAQEAAQUCfh8aYVNQHrm2/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxABAAEFAQAAAAAAAAAAAAAAAQACEBESMYH/2gAIAQEABj8C1lIewzFet//EABoQAQEBAQADAAAAAAAAAAAAAAERACFBUYH/2gAIAQEAAT8hFR2YIFKH1rgBZhPA+T1rSlmdXf/aAAwDAQACAAMAAAAQN9//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8Q1CFv/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAECAQE/EBjL/8QAGxAAAgMBAQEAAAAAAAAAAAAAAREAITFBUaH/2gAIAQEAAT8QAssY4QfYQ8QZXoQQi9nyzURgIbDT0R5SGwVXkyO0fk//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "yuma counsel 240209 RN2 8581",
                  "title": "yuma counsel 240209 RN2 8581",
                  "src": "/static/79e4c2bee0a858aa801a45419b54f5cc/6a068/yuma-counsel_240209_RN2_8581.jpg",
                  "srcSet": ["/static/79e4c2bee0a858aa801a45419b54f5cc/f93b5/yuma-counsel_240209_RN2_8581.jpg 300w", "/static/79e4c2bee0a858aa801a45419b54f5cc/b4294/yuma-counsel_240209_RN2_8581.jpg 600w", "/static/79e4c2bee0a858aa801a45419b54f5cc/6a068/yuma-counsel_240209_RN2_8581.jpg 960w"],
                  "sizes": "(max-width: 960px) 100vw, 960px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span>{`
    `}</span>{`
    listened to Franklin Graham share the Gospel of Jesus Christ in Yuma, Arizona, during the ninth stop of the God Loves You Frontera Tour. The two young friends had come together, and by the end of the night, they were deeply touched by the message of “`}<em parentName="p">{`repentance toward God and of faith in our Lord Jesus Christ`}</em>{`” (Acts 20:21, ESV). `}<strong parentName="p">{`Meghan and Ella both stood in response to the invitation, and Jan, one of our prayer counselors, walked over to talk with the girls.`}</strong>{` They cried tears of joy and hugged one another as they told her they had just prayed to surrender their lives to the Lord. Jan told them `}<strong parentName="p">{`they were now sisters in Christ`}</strong>{` and gave them new Bibles and discipleship materials to help them grow in their new faith.
    `}</p>{`
    `}</div>
      </div>
      <div>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAByOlEQJf/xAAYEAEAAwEAAAAAAAAAAAAAAAABAAISMf/aAAgBAQABBQKuRtYZkj200k//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAADAAMAAAAAAAAAAAAAAAAAEBEhMVH/2gAIAQEABj8Cq2Tiwf/EABsQAQEBAAIDAAAAAAAAAAAAAAERACExUWFx/9oACAEBAAE/IaYnyZngCkm9jeQzpjOR7cpkBv/aAAwDAQACAAMAAAAQM+//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QQY2//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EBSdv//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFxMWGBkf/aAAgBAQABPxBDCszwGrj1GyBx3uItWfIUYBK9MsjBQa0RhUOp/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "yuma crowd 240209 rn1 8280 enhanced",
            "title": "yuma crowd 240209 rn1 8280 enhanced",
            "src": "/static/191468a6499c5e00a094153b10b53cab/6a068/yuma-crowd_240209_rn1_8280-enhanced.jpg",
            "srcSet": ["/static/191468a6499c5e00a094153b10b53cab/f93b5/yuma-crowd_240209_rn1_8280-enhanced.jpg 300w", "/static/191468a6499c5e00a094153b10b53cab/b4294/yuma-crowd_240209_rn1_8280-enhanced.jpg 600w", "/static/191468a6499c5e00a094153b10b53cab/6a068/yuma-crowd_240209_rn1_8280-enhanced.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`YUMA, ARIZONA, marked the ninth stop of the God Loves You Frontera Tour. Altogether, more than 58,000 people heard the Gospel during the 10-city tour and over 5,200 made decisions for Jesus Christ.`}</figcaption>
      </div>{`
 `}<div {...{
        "className": "sigiture-container"
      }}>{`
   `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": "Franklin Graham signature."
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham | President & CEO`}</p>{`
     `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>{`
     `}<hr parentName="div"></hr>{`
  `}</div>
      <div {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects"
        }}><span parentName="h4">{`GOD LOVES YOU TOUR`}</span>{` | HOW YOU CAN HELP`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    God works through people like you To change the world by the power of the Gospel.
   `}</h4>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`PRAY`}</span>{` | Ask God to continue drawing men and women to Himself through God Loves You Tour events next year around the world. Please also pray that all those who make decisions for Him would become bold witnesses for Jesus Christ in their families and communities.`}</p>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`GIVE`}</span>{` | When you give, you make it possible to proclaim the Good News of Jesus Christ. Each `}<strong parentName="p">{`God Loves You Tour`}</strong>{` event reaches people far from God with His truth.`}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      