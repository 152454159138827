import React from 'react';

const donationHeaderAbcGospel = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your "Gospel Alphabet PDF" is on its way to your
      inbox.
    </h2>
    <br></br>
    <div>
      <p>
        Thank you for wanting to teach the younger generation about God's Word.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        Before you go, we want you to know that the Billy Graham Evangelistic
        Association can offer resources like this one because of people who
        generously support the ministry. So many people still need to hear about
        the hope of Jesus and the practical wisdom, comfort and direction the
        Bible offers.
      </p>
      <p>
        Will you help share the Gospel with adults and children alike around the
        world?
      </p>
      <blockquote className="modalBlockquote">
        Your gift today will help share the Gospel with people around the world
        who are far from God and equip them with powerful Biblical resources to
        become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderAbcGospel;
