import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/05/Porta-emotion_blend-PLTR1_240402_RN2_3583-v2_INET.jpg",
            "alt": "Woman hugging a girl."
          }}></img>{`
    `}</div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "proclaiming-peace-in-portugal"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`Evangelistic Crusades`}</span></h3>{`
    `}<h4 parentName="div">{`Proclaiming Peace in Portugal`}</h4>{`
    `}<p parentName="div">{` I’ve just returned from Portugal, where I had the blessing of preaching the Gospel at the `}<strong parentName="p">{`Celebration of Hope (Celebração da Esperança) in Porto`}</strong>{`. This historic coastal city is a sight to behold, with centuries-old buildings and iconic cobblestone roads.`}</p>{`
    `}<p parentName="div">{` It’s a very religious city, and 80% of the population registers as Catholic (at least nominally so). At the one-night event on April 26, I shared that religion cannot save you. It may give you some comfort for a moment, but it can’t offer the eternal joy and peace that comes from personally knowing the Savior Jesus Christ.`}</p>{`
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/05/Porta-counsel_edit_240402_RN2_3680_INET.jpg",
            "alt": "A volunteer praying with another man."
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{` More than 6,100 people filled the arena, and many huddled outside in the rain in an overflow section as they watched on a large screen. At the invitation to begin a relationship with Jesus Christ, over 480 prayerfully surrendered their lives to Him. Among them was Andreza, who was filled with joy after responding to the Gospel. “Jesus means everything to me. He is the strength, the life, the salvation, the foundation, the companion, the friend, the shield,” she beamed. `}<strong parentName="p">{`“From today on, a lot of things will change in my life, because after what I felt here, there’s no way I can continue to be the same person.”`}</strong></p>{`
    `}<p parentName="div">{` In addition to those in the arena, there were more than 16,000 views of the event online, with over 230 people indicating a decision for Jesus.`}</p>{`
    `}<p parentName="div">{` Please join me in praying for each of those who—like Andreza—responded in faith and placed their eternities into the loving hands of the Savior.`}</p>{`
    `}<p parentName="div">{` I would also appreciate your prayers as I look ahead to the Gold Coast Look Up Celebration in Australia on June 29. We’ve seen incredible ministry in Australia throughout the history of the Billy Graham Evangelistic Association, including my grandfather’s historic 1959 campaign across the country and my father’s six-city tour in 2019. Will you join us in praying, “Do it again, Lord,” in 2024?`}</p>{`
    `}<div parentName="div">{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/05/Porto-crowd_June-Pltr_240402_LR1_8451_FLAT.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div">{`AS 6,170 PACKED PORTO’S SUPER BOCK ARENA, more than 480 responded to the Gospel and surrendered their lives to Jesus Christ.`}</figcaption>{`
    `}</div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "sharing-the-king-of-kings-in-the-uk"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`International God Loves You Tour`}</span></h3>{`
    `}<h4 parentName="div">{`Sharing the King of Kings in the U.K.`}</h4>{`
    `}<p parentName="div">{` As you know, my father has already held outreaches this year in Mexico City, Mexico, in 10 cities along the southern U.S. border, and in Kraków, Poland. In a matter of weeks, he will continue his yearslong effort to reach the United Kingdom as he brings the `}<strong parentName="p">{`God Loves You Tour U.K. to Birmingham, England, on June 15 and Glasgow, Scotland, on June 22.`}</strong></p>{`
    `}<p parentName="div">{` As we’ve worked in these two cities, we’ve found churches that are passionate about Jesus and a culture that is desperate for the hope and truth that the Gospel provides. Combined, more than 650 churches have been represented at pre-event gatherings—including training classes where nearly 2,600 local Christians have been equipped to share their faith.`}</p>{`
    `}<p parentName="div">{` We believe `}<strong parentName="p">{`God is setting the stage to do something big in the U.K., and we need your prayers`}</strong>{` as we proclaim the Good News of Jesus—`}<em parentName="p">{`“the way, the truth, and the life”`}</em>{`—who said, `}<em parentName="p">{`“No one comes to the Father except through Me”`}</em>{` (John 14:6, NKJV).`}</p>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "the-comfort-of-christ-in-charlotte"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`NATIONAL LAW ENFORCEMENT MINISTRY`}</span></h3>{`
    `}<h4 parentName="div">{`The Comfort of Christ in Charlotte`}</h4>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/05/RRT_officer_pray_truck_240412_LR2_4167_INET.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div">{`BG-RRT CHAPLAINS offer the comfort of Christ with those in the midst of crisis—whether man-made or natural disasters.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{` In late April, tragedy struck in the Billy Graham Evangelistic Association’s hometown of Charlotte, North Carolina. Four law officers were killed and four more were wounded while serving a warrant. It was the nation’s worst law enforcement shooting since 2016 and it left the 1,800-member Charlotte-Mecklenburg Police Department (CMPD) stunned and grieving.`}</p>{`
    `}<p parentName="div">{` Crisis-trained Billy Graham Rapid Response Team (BG-RRT) chaplains—each of whom were former officers themselves—spent days with the CMPD, sharing the hope, peace, and comfort of Christ in the midst of a devastatingly difficult time.`}</p>{`
    `}<p parentName="div">{` `}<strong parentName="p">{`One of the chaplains, a retired CMPD officer named Charles, had the opportunity to minister to his former partner on the force, who happened to be on the scene during the deadly firefight.`}</strong>{` “All this confusion and pain that we go through … we’re going to get through it,” he told her. “God is always there for us. He’s not going to forsake us. … Having Jesus in your heart and that everlasting trust that He’s there—you can get through anything, you can face anything.”`}</p>{`
    `}<p parentName="div">{` Psalm 34:18 says, `}<em parentName="p">{`“The Lord is near to those who have a broken heart, and saves such as have a contrite spirit”`}</em>{` (NKJV). The chaplains felt the presence of the Holy Spirit in the midst of grief-filled conversations with officers and staff.`}</p>{`
    `}<p parentName="div">{` Will you join me in praying for the families of those who were killed, for the officers who are recovering from wounds, and for all those affected by this terrible tragedy?`}</p>{`
    `}<h4 parentName="div" {...{
          "id": "mending-marriages-in-alaska"
        }}>{`MENDING MARRIAGES IN ALASKA`}</h4>{`
    `}<p parentName="div">{` Even as our hearts are burdened for the law enforcement community in Charlotte, we’re preparing 
    for another season of Marriage Resiliency Week events as part of our Law Enforcement Appreciation Program in Alaska.`}</p>{`
    `}<p parentName="div">{` Every summer, we host groups of officers and their spouses who have experienced trauma while protecting and serving our nation. In most of these cases, the wounds they’ve endured (both physical and mental) are impacting their marriages, leaving many on the verge of hopelessness and divorce.`}</p>{`
    `}<p parentName="div">{` While in the far reaches of the Alaskan wilderness, these couples have the opportunity for spiritual encouragement, healing, and marriage enrichment. Couples take part in Biblically based sessions that strengthen their relationships with God and each other, while also enjoying the beauty of God’s creation through outdoor activities.`}</p>{`
    `}<p parentName="div">{` Every year, we see marriages restored—and souls saved for eternity! One of our favorite times of the week comes near the end when `}<strong parentName="p">{`couples often choose to renew their vows, and hurting officers ask to be baptized in the frigid Alaskan water.`}</strong></p>{`
    `}<h4 parentName="div" {...{
          "id": "carrying-hope-to-the-nations"
        }}>{`CARRYING HOPE TO THE NATIONS`}</h4>{`
    `}<p parentName="div">{` There is hope—in this life and for eternity. It’s not found in this world, however. Indeed, recent events have shown us how quickly the temporary pleasures and material treasures of life can be stripped away. Rather, it’s found in Jesus Christ, His death and resurrection, and it’s our honor to carry that message around the globe. None of this would be possible without your prayers and support. `}<strong parentName="p">{`Lives are being changed for eternity, and people are finding true and everlasting hope, thanks to you.`}</strong></p>
      </div>
      <div>{`
    `}<p parentName="div"><br parentName="p"></br>{`May God bless you and your family,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2022/02/Will-Graham-Signature.png",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Will Graham`}<br parentName="p"></br>{`Executive Vice President`}</p>
      </div>
      <div {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
    `}</h4>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world.`}</p>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`INTERNATIONAL GOD LOVES YOU TOURS`}</span>{` | Through your gift for the `}<strong parentName="p">{`International God Loves You Tours`}</strong>{`, you can help share the life-changing power of His Gospel with individuals in cities around the world, including Birmingham, England, and Glasgow, Scotland, this June.`}</p>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`NATIONAL LAW ENFORCEMENT MINISTRY`}</span>{` | This year, hundreds of law enforcement officers and their spouses will attend appreciation dinners, retreats, and weeklong marriage seminars in Alaska. Your support makes it possible to bless them with this time away and strengthen them in their calling as they sacrificially serve our nation.`}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      