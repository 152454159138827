import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="give" id="give-form">
    <SingleDonationForm projectId={200001} esubCodes={['BGEA', 'PLTR']} SESourceCode={'BY230YLFD'} collectionType={1} showFreeGiftOffer={false} destination={2} buttonAmounts={[25, 50, 100, 250]} recurringButtonAmounts={[12, 24, 48, 100]} recurringToggle={true} donationDateButtons={false} mdxType="SingleDonationForm" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      