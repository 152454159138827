import React from 'react';
import '../../styles/global.module.scss';

const customModalTextLFO = (
  <div>
    <p>
    <p>
    We hope these cards will help you and your family grow closer to God as you
    “<em>let the word of Christ dwell in you richly</em>” (Colossians 3:16, ESV). 
    </p>
    </p>
    <br></br>
    <p>
      <b>Before you go, there are a few things we’d like you to know{' '}…</b>
    </p>
    {/* <br></br> */}
    <p>
    God is using friends like you to reach people with His love in a variety
        of ways through the Billy Graham Evangelistic Association, including a
        24-hour prayer line, online Gospel resources, and Billy Graham Rapid
        Response Team (BG-RRT) chaplains, who comfort and pray with hurting
        people in the midst of crisis.
    </p>
    {/* <br></br> */}
    <p> This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <p>
        When you make a gift today, you can reach people across the globe with
        the message of God’s love. In a time when so many are overcome with
        anger, fear, or hopelessness, the Good News of Jesus Christ is exactly
        what they need to hear. Will you help share it with them?
      </p>
    {/* <br></br> */}
    <blockquote className="modalBlockquote">
    Your gift today will help share the hope of the Gospel with people
        around the world who are far from God.
    </blockquote>
  </div>
);

export default customModalTextLFO;
