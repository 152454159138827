import React from 'react';
import './fearless-scripture.scss'

const FearlessCustomConfirmation = () => (
  <div>
    <p>
    Thank you for subscribing to receive updates on the <em> Fearless With Cissie Graham Lynch</em> podcast. 
    Click the button below for your digital Scripture prayer cards that you can personalize for yourself or 
    use to encourage others. We pray this resource will strengthen you with “<em>the word of truth, the gospel 
    of your salvation</em>”{' '} (Ephesians 1:13, ESV).
    </p>
    <ul>
    <h6 className='con-header'>Choose your preferred version:</h6>
      <li>
         <a href='/resources/20374-fearless-color-scripture-cards-v7-color.pdf' target="_blank">
            <span>Full Color Printable PDF</span>
          </a>
      </li> 
      <li>
        <a href='/resources/20374-fearless-scripture-cards-v7-grayscale.pdf' target="_blank">
              <span>Grayscale Printable PDF</span>
            </a>
    </li> 
    <li>
      <a href='/resources/20374-fearless-scripture-cards-v7-mobile.pdf' target="_blank">
            <span>Mobile-Friendly Version</span>
          </a>
    </li>
 </ul>

  </div>
);

export default FearlessCustomConfirmation;
