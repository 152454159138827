import React from 'react';
import { Block, Heading } from '@bgea-js/react-ui';

const customConfirmationLaos = () => (
  <div>
    <Heading>ຂອບໃຈ</Heading>
    <Block>
      <p>ການສະໝັກຂອງທ່ານສຳເລັດເເລ້ວ</p>
    </Block>
  </div>
);

export default customConfirmationLaos;
