import "../../../../src/content/european-congress-lp/congress.scss";
import EuropeanCongressHeader from "../../../../src/content/european-congress-lp/european-congress-header.mdx";
import EuropeanCongressSection from "../../../../src/content/european-congress-lp/european-congress-section.md";
import EuropeanCongressModal from "../../../../src/content/european-congress-lp/european-congress-modal.mdx";
import EuropeanCongressSchedule from "../../../../src/content/european-congress-lp/european-congress-schedule.md";
import * as React from 'react';
export default {
  EuropeanCongressHeader,
  EuropeanCongressSection,
  EuropeanCongressModal,
  EuropeanCongressSchedule,
  React
};