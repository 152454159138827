import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "quote-banner"
    }}>{`
  `}<div parentName="div" {...{
        "className": "content-wrapper"
      }}>{`
    `}<div parentName="div" {...{
          "className": "margins"
        }}>{`
      `}<div parentName="div">{`
        `}<h3 parentName="div">{`
          «Ибо так возлюбил Бог мир, что отдал Сына Своего Единородного, дабы
          всякий верующий в Него, не погиб, но имел жизнь вечную».
        `}</h3>{`
        `}<h3 parentName="div" {...{
              "className": "mb-0"
            }}>{`— Иоанна 3:16`}</h3>{`
      `}</div>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "pattern-block-quote",
        "id": "desktop"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/10/shapes-verse.png",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "pattern-block-quote",
        "id": "mobile"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/10/shapes-verse.png",
          "alt": ""
        }}></img>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      