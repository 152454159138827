import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "column small-12 medium-4 large-5 body-content"
    }}>{`
        `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2020/07/Extraordinary_Journey_DVD_Cover.png",
        "alt": "An Extraordinary Journey DVD cover",
        "className": "cover"
      }}></img>{`
            `}<p parentName="div"></p>{`
        `}<div parentName="div" {...{
        "className": "color-block hide-mb"
      }}>{`
            `}<h5 parentName="div">{`INCLUDES`}</h5>{`
            `}<hr parentName="div"></hr>{`
            `}<h4 parentName="div">{`BILLY GRHAM'S FUNERAL`}</h4>{`
        `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      