import './redeemed-book.scss';

const RedeemedBookCustomMarkup = {
  markup: `<h3 style="text-align:center;">Explore how to grow deeper in Christ with a free copy of Will Graham’s book, <em>Redeemed</em>.</h3>
  <p className="p-content">
  Have you or a loved one ever felt unsatisfied, like you were missing something important in life? Only a close relationship with Jesus Christ 
  can fulfill the deepest longings of our hearts—and we want to help others “<em>know Him and the power of His resurrection</em>” (Philippians 3:10, NKJV).
  </p>
  <p style="margin-bottom:3rem;">
  When you fill out the form below, we’ll send you a free copy of <strong><em>Redeemed</em></strong>—a powerful devotional book by Will Graham that will
  point you directly to Christ, help you build your faith, and challenge you to share this life-changing hope with others. 
  </p>
  `,
};

export default RedeemedBookCustomMarkup;
