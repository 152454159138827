import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
  <p>Dear Friend,</p>
  <p>
    &emsp;Thanks to the prayers and support of committed believers like you, men, women, and children on opposite ends of the globe have discovered the hope of Jesus Christ through God Loves You Tour events and now “<em>walk in newness of life</em>” (Romans 6:4, ESV).
  </p>
  <p>
    &emsp;Early this year,<strong> I had the privilege of preaching the Good News in 10 cities along our nation’s southern border</strong>, which is <em>frontera</em> in Spanish. Poverty, uncertainty, and conflict is a part of daily life for so many in these cities.  
    <div className="quote-container">
  <p><p className="quote-open">“</p><span className="quote"> THE SPIRITUAL NEED IN THIS REGION WAS—AND STILL IS—DEEP.</span><p className="quote-close">”</p></p>
  </div>
    &nbsp;The spiritual need in this region was—and still is—deep. It was such a joy to share the Gospel—and watch as hundreds of people at every stop on the Frontera tour surrendered their lives to the Lord in repentance and faith.
  </p>
  <p>
    &emsp;As I write, I’ve just finished preaching at <strong>God Loves You Tour events in Birmingham, England, and Glasgow, Scotland.</strong> Culturally and geographically, these two cities are a world away from Texas, Arizona, and California—but <strong>the needs of the human heart are the same.</strong> In both cities, I explained how our only hope in this life—and the next—is a personal relationship with the Savior. We praise God that hundreds responded by placing their faith and trust in Him.
  </p>
    <p>
    &emsp;<strong>Will you pray for new believers in our country and in the United Kingdom?</strong> Please ask the Lord to make them bold witnesses for Him.
  </p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      