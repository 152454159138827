import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationGTS = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
}) => {
  const recurText = recurrenceText === 'One-Time' ? 'única' : 'mensual';
  return (
    <div>
      <h4 subtitle>Gracias por tu regalo</h4>
      <Block>
        Le damos gracias a Dios por tu pasión por compartir la Palabra de Dios y
        el Evangelio en la frontera entre México y Estados Unidos a través de la
        gira God Loves You Frontera Tour con Franklin Graham.
      </Block>
      <Block>
        Tu generosidad equipará a un nuevo creyente con una Biblia con la que
        podrá crecer en su fe en Jesucristo.
      </Block>
      <Block>
        El número de confirmación de tu donación{' '}
        {showRecurrenceText && <strong>{recurText}</strong>} es{' '}
        <strong>{transactionId}</strong> por la cantidad de $
        {total.toFixed(2) ?? 'NEED AMOUNT'}.
      </Block>
      <Block>
        Si tienes alguna pregunta, llama al +1-877-247-2426. Por favor, ten tu
        número de confirmación a la mano e indica que nos estás contactando con
        relación a una donación realizada a través del sitio web de la Billy
        Graham Evangelistic Association.
      </Block>
      <Block>
        Nos gustaría mantenerte informado sobre el ministerio y enviarte
        palabras de aliento que te ayudarán a crecer en tu fe. Puedes cancelar
        esta suscripción en cualquier momento.
      </Block>
    </div>
  );
};

export default customConfirmationGTS;
