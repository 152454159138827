import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`There’s a crisis at our southern border. Every day, thousands of people 
    are streaming across in search of a new life—and the communities they’re passing through are weighed down by their own struggles.`}</p>{`
    `}<p parentName="div"><strong parentName="p">{`I believe this 1,900-mile stretch is one of the neediest parts of the United States, and Jesus Christ is their only hope.`}</strong></p>{`
    `}<p parentName="div">{`As you read this, we’re preparing to take the Good News to 10 cities in this region during  the`}<strong parentName="p">{` God Loves You Frontera Tour`}</strong>{` along the border—or `}<em parentName="p">{`frontera `}</em>{`
    in Spanish. We’re starting in `}<strong parentName="p">{` Brownsville, Texas`}</strong>{`, on the Gulf coast, and ending in 
    `}<span parentName="p" {...{
          "className": "image-float-left"
        }}>{`
        `}<span parentName="span" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAIDBAUG/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABlsqqzWAH/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQQAAgMFM//aAAgBAQABBQJzY5wNUuZ0vNQAtT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAABBAMBAAAAAAAAAAAAAAACAAEQEhEhMXH/2gAIAQEABj8CEcdQWKtdwPqBnaP/xAAeEAEAAgEEAwAAAAAAAAAAAAABABFBECFRcTFhgf/aAAgBAQABPyFwORZXklZx17g2WSvj3z+QCCcPWn//2gAMAwEAAgADAAAAEMDP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHxABAQABAgcAAAAAAAAAAAAAAREAIaEQMUFRgZGx/9oACAEBAAE/EBVpTXtpN8t2O5E6gPK3ABKJRwI4pB1BzHz1lajWFKNN+H//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "franklin dec2023 v02",
              "title": "franklin dec2023 v02",
              "src": "/static/6db2eb836f854df83f6d6f54ca21c2b0/6a068/franklin_dec2023-v02.jpg",
              "srcSet": ["/static/6db2eb836f854df83f6d6f54ca21c2b0/f93b5/franklin_dec2023-v02.jpg 300w", "/static/6db2eb836f854df83f6d6f54ca21c2b0/b4294/franklin_dec2023-v02.jpg 600w", "/static/6db2eb836f854df83f6d6f54ca21c2b0/6a068/franklin_dec2023-v02.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</span>{`
    `}<strong parentName="p">{`Chula Vista, California, on the Pacific coast, going city-by-city through McAllen, Laredo, Eagle Pass, Del Rio, Presidio, and El Paso, Texas; and Tucson and Yuma, Arizona`}</strong>{`. I will preach the Gospel, call people to repentance, and invite them to believe on the Name of the Lord Jesus Christ. My messages will be translated into Spanish at every stop, and we’ll have great music in both languages.`}</p>{`
    `}<p parentName="div">{`We’ve been holding Christian Life and Witness Courses in each of the cities. Through classes in both English and Spanish, we want to equip believers to share their faith with others and serve as prayer counselors during the tour.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      