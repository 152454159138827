import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "national-law-enforcement-ministry"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`NATIONAL LAW ENFORCEMENT MINISTRY`}</span>{`
  `}</h3>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/august/sept-prayer-letter/alaska-group-prayer.jpg",
          "alt": "Couples praying in Alaska"
        }}></img>{`
  `}<figcaption parentName="div">{` Many Marriage Resiliency Week participants tell us that it changes their marriages—and their lives.`}</figcaption>{`
  `}</div>{`
  `}<h4 parentName="div">{`‘I COULD NOT HAVE ASKED FOR ANYTHING BETTER …’`}</h4>{`
  `}<p parentName="div">{`For the past two months, we have hosted law enforcement officers and their spouses for Marriage Resiliency Weeks in rural Alaska. It’s always a privilege to serve the men and women who sacrifice so much—especially those who have experienced intense trauma in the line of duty, like these couples. Nearly all of the participants have told us that this time together revitalized their marriages.`}</p>{`
  `}<p parentName="div">{`Even more exciting, `}<strong parentName="p">{`several of the men and women shared that they made life-changing decisions for Jesus Christ, including Mark* and Jenny.*`}</strong>{` The two were recovering from a brief separation when they arrived in Alaska, and Jenny described their marriage as distant. Neither of them had a personal relationship with the Lord. As the week went on, they heard the `}<em parentName="p">{`“good news of peace through Jesus Christ”`}</em>{` (Acts 10:36, ESV) throughout the sessions.`}</p>{`
  `}<img parentName="div" {...{
        "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/august/sept-prayer-letter/alaska-baptism.jpg",
        "alt": "Man getting baptized in Alaska"
      }}></img>{`
  `}<p parentName="div" {...{
        "className": "mt-2"
      }}>{`Mark explained to one of our team members that he believed in God but had never repented of his sins nor committed himself to Him—and now he wanted to change that. By the week’s end, he placed his faith and trust in Jesus Christ. Mark was baptized in the cold lake waters, and he and Jenny renewed their marriage vows. `}<strong parentName="p">{`“I could not have asked or planned anything better, period,” Mark said. “This is truly a special place.”`}</strong></p>{`
  `}<p parentName="div">{`Please pray for this couple, and for each person who attended one of these Marriage Resiliency Weeks. We know that law enforcement is a difficult, challenging, and dangerous job, and only a personal relationship with the Savior offers the lasting peace and strength they need.`}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rapid-response-team"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`SHE HAD LOST HER HOME—AND SO MANY LOVED ONES`}</h4>{`
  `}<p parentName="div">{`As I write, our Billy Graham Rapid Response Team (BG-RRT) chaplains are ministering in many communities across our country. Tornadoes, floods, wildfires, or hurricanes have hit almost every region of the U.S. over the past couple of months, and I’m grateful for the chaplains who are working hard to share God’s love with hurting people.`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/august/sept-prayer-letter/rrt-group-praying.jpg",
          "alt": "RRT chaplains praying with a man"
        }}></img>{`
  `}<figcaption parentName="div">{`BG-RRT chaplains are deployed across the nation right now in the wake of natural disasters.`}</figcaption>{`
  `}</div>{`
  `}<p parentName="div">{`Recently, two chaplains met Lucia* in Ruidoso, New Mexico. A wildfire had reduced her home to ashes—after years of loss had already taken so much from her. July marked the anniversaries of her parents’ deaths, both within the last three years. When our chaplains met Lucia, it had only been three days since the death of a dear friend who was like a second mother to her.`}</p>{`
  `}<p parentName="div">{`As they sat in the ruins of her house, chaplains gently asked Lucia if they could share the Good News with her—that `}<em parentName="p">{`“God shows his love for us in that while we were still sinners, Christ died for us”`}</em>{` (Romans 5:8, ESV). Lucia was eager to listen. `}<strong parentName="p">{`The Holy Spirit worked in her heart as they used our “Steps to Peace With God” booklet to explain the Gospel to her.`}</strong>{` We praise God that she told them she was ready to place her faith in Jesus Christ! Our chaplains gave her a new Bible along with resources to help her draw closer to the Lord. Please pray for her and for all those hearing the Gospel through these deployments.`}</p>{`
  `}<img parentName="div" {...{
        "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/august/sept-prayer-letter/rrt-booklet.jpg",
        "alt": "RRT chaplain showing woman book"
      }}></img>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "billy-graham-library"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM LIBRARY`}</span>{`
  `}</h3>{`
   `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/august/sept-prayer-letter/ben-carson.jpg",
          "alt": "Dr. Ben Carson"
        }}></img>{`
  `}<figcaption parentName="div">{`Dr. Ben Carson`}</figcaption>{`
  `}</div>{`
  `}<h4 parentName="div">{`‘WE HAVE TO RETURN TO OUR FAITH …’`}</h4>{`
  `}<p parentName="div">{`A few weeks ago, we had the privilege of welcoming my friend Dr. Ben Carson to the Billy Graham Library. Dr. Carson greeted the 650 guests, signed copies of his new book, `}<em parentName="p">{`The Perilous Fight`}</em>{`, and shared his heart for protecting family and faith in a culture that is at war against both. `}<strong parentName="p">{`“We have an ‘ace in the hole,’ and that is God,” he told the crowd. “We have to return to our faith in God.`}</strong>{` We have to be vocal about what we believe.” I couldn’t agree with him more—and I’m thankful for his bold witness.`}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "billy-graham-training-center"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM TRAINING CENTER`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`HISPANIC SEMINAR AT THE BILLY GRAHAM TRAINING CENTER AT THE COVE`}</h4>{`
  `}<p parentName="div">{`Recently, men and women from all over the nation attended a two-day Spanish-language seminar at the Billy Graham Training Center at The Cove. “God’s Design for the Family” was led by author and speaker Sixto Porras. This timely conference helped parents—and those who hope to be parents someday—consider how they can keep God’s truth at the center of their families. “We are living in a world where kids are struggling to find their identity,” Sixto explained. “Let your home be the place where your children find their identity [in Christ], where they find their sense of belonging.”`}</p>{`
  `}<p parentName="div">{`The Biblical teaching resonated with many participants, including Josías, who is only 18. He and his family moved from Argentina to Florida a few years ago, and his dad now pastors a Spanish-speaking church. “I know this is what we need if we want to honor the Lord,” he said at the conference, referring to the family he hopes to have after he and his girlfriend get married someday. `}<strong parentName="p">{`“We want to start with small but well-directed steps to one day have a family that follows God’s design, even from a young age.”`}</strong>{` Please pray that God will work powerfully in the lives of each person who attended.`}</p>{`
  `}<p parentName="div">{`We have a busy schedule of evangelistic ministry in the coming months—and there are countless people in these cities who are far from Jesus Christ. Without Him, they will be lost for eternity. Will you ask the Holy Spirit to work in their hearts and minds—and call them `}<em parentName="p">{`“out of darkness into His marvelous light”`}</em>{` (1 Peter 2:9, NKJV)? Your prayers are critical to this Gospel work, and I am grateful for you. `}</p>{`
  `}<p parentName="div">{`May God richly bless you,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Franklin Graham`}<br parentName="p"></br>{`President & CEO`}</p>{`
  `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<h4 parentName="div" {...{
        "className": "cta projects"
      }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
  `}<h4 parentName="div" {...{
        "className": "mb-6 smaller"
      }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
  `}</h4>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`NATIONAL LAW ENFORCEMENT MINISTRY`}</span>{` | This year, hundreds of law enforcement officers and their spouses will attend appreciation dinners, retreats, and weeklong Marriage Resiliency Week events in Alaska. Your support makes it possible to bless them with this time away and strengthen them in their calling as they sacrificially serve our nation.
  `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`THE BILLY GRAHAM LIBRARY `}</span>{` | We hear from Library guests daily about how God has touched their lives—or changed their hearts—during their visit. Your gift to the `}<strong parentName="p">{`Billy Graham Library`}</strong>{` helps keep admission to the Library free, sharing the Good News of Christ with thousands of families, schools, and individuals who otherwise might not visit.
  `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      