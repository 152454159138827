import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div {...{
        "className": "internetevangelism"
      }}>{`
    `}<div parentName="div" {...{
          "className": "image-float-left-fg franklin-img"
        }}>{`
            `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "125%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBBAUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwAE/9oADAMBAAIQAxAAAAF3dYQ3CRCyNPLvDooEFf/EABsQAAMBAQADAAAAAAAAAAAAAAABAgMRBEFE/9oACAEBAAEFAhqeFaSO+GdpybtaeMe/kP/EABkRAAEFAAAAAAAAAAAAAAAAAAEAAhAREv/aAAgBAwEBPwENGFU//8QAFhEBAQEAAAAAAAAAAAAAAAAAARIg/9oACAECAQE/AVax/8QAHRAAAQUAAwEAAAAAAAAAAAAAAQACEBEhEkFhgv/aAAgBAQAGPwJZHEOFrTR9WuFwx/d7P3H/xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhMUEQUXHw/9oACAEBAAE/IQA7DEUMH0OKDAe4MCKtDSAtRRubK1FF0euBnBn8xDmf/9oADAMBAAIAAwAAABCn5wD/xAAXEQEAAwAAAAAAAAAAAAAAAAABABAh/9oACAEDAQE/EGJTYov/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EIqWFQin/8QAHRABAAIDAAMBAAAAAAAAAAAAAQARITFBYYGhsf/aAAgBAQABPxBlmHmvsLljaSjkqUSgFg5msdjwLiPY1EQrOECnTxLSxVqxASK4HhT+D7io4wT7J8ONk//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "fg essen edit 231001 tp2 4348",
              "title": "fg essen edit 231001 tp2 4348",
              "src": "/static/ee5cf9405aa5e0dc588777af19ca1ccf/066f9/fg_essen_edit_231001_tp2_4348.jpg",
              "srcSet": ["/static/ee5cf9405aa5e0dc588777af19ca1ccf/f93b5/fg_essen_edit_231001_tp2_4348.jpg 300w", "/static/ee5cf9405aa5e0dc588777af19ca1ccf/066f9/fg_essen_edit_231001_tp2_4348.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`Through your support, you are helping share the Gospel with people all over  the world—24 hours a day, seven days a week!
    `}</p>{`
       
   `}<p parentName="div">{`Many of the people who discover His truth through our internet evangelism ministry have limited opportunities for hearing the Good News. Paul,* who is paralyzed after having a stroke, is one of them. He recently enrolled in our `}<em parentName="p">{`Know Jesus`}</em>{` discipleship course, learned more about the Lord, and ultimately decided to surrender his life to Him.
    “My life has become more peaceful and calm,” he typed. “This ministry has given me hope that God can forgive my sin.” `}</p>{`
    `}<span parentName="div" {...{
          "className": "float-text"
        }}>{`
        `}<a parentName="span" {...{
            "href": "#"
          }}>{`“This ministry has given me hope ...”`}</a>{`
    `}</span>{`
    `}<p parentName="div">{`Will you pray for all those who are hearing the Gospel through this online outreach? I thank God for you and for  your commitment to tell lost men, women, and children about Jesus Christ, “`}<em parentName="p">{`who loves us and has freed us from our sins by his blood`}</em>{`” (Revelation 1:5, ESV).`}</p>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
     `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": ""
        }}></img>{`
     `}<p parentName="div">{`Franklin Graham`}</p>{`
        `}<p parentName="div">{`President & CEO`}</p>
      </div>
      <div {...{
        "className": "topic-two",
        "id": "eternal-peace"
      }}>
        <h2 parentName="div">{`Eternal Peace After Overwhelming Emptiness`}</h2>{`
    `}<div parentName="div" {...{
          "className": "image-float-right-mad"
        }}>{`
            `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "400px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "141%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAwH/2gAMAwEAAhADEAAAAYttKMSCEmtOVV4qdEO//8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEgMRIQT/2gAIAQEAAQUChEXdcKiqqI3opMZNS24qAKOheSgaf//EABkRAAIDAQAAAAAAAAAAAAAAAAASARARIf/aAAgBAwEBPwFum00n/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAiH/2gAIAQIBAT8BWeEYiI//xAAbEAACAwADAAAAAAAAAAAAAAAAARARIQIScf/aAAgBAQAGPwKezNElxs8EjVGFs//EABwQAQEBAAMAAwAAAAAAAAAAAAERACExQVFhwf/aAAgBAQABPyG8fmhHxMUIOogV9d1DR45xuLndxJTheakbXDOVzuvzM/onW9Bd/9oADAMBAAIAAwAAABBrI/P/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARMf/aAAgBAwEBPxBJxEbFNaf/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQMf/aAAgBAgEBPxBrul+FRZf/xAAcEAEBAAMBAQEBAAAAAAAAAAABEQAhMUFhcdH/2gAIAQEAAT8QD109OnLwRQ0JPn5nET2+YJAeAaPMIy8Ac/mMkIsQddYLRsTb7kCmDDb85hSEd1x4VdkJTFMgPjzERQjxmf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "madagascar female",
              "title": "madagascar female",
              "src": "/static/c2554211819a6fbb74bd7d964b4b7dea/066f9/madagascar%20female.jpg",
              "srcSet": ["/static/c2554211819a6fbb74bd7d964b4b7dea/f93b5/madagascar%20female.jpg 300w", "/static/c2554211819a6fbb74bd7d964b4b7dea/066f9/madagascar%20female.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
        </div>
        <p parentName="div">{`
   Marie*—who lives in Madagascar—was overwhelmed by emptiness. Deep down, she knew that a relationship with the Lord was missing from her life. She cried out to God and even opened the Bible—but she couldn’t shake the feeling of confusion. `}<strong parentName="p">{`Marie turned to the internet, typed “how to get closer to Jesus,” and found PeaceWithGod.net.`}</strong>{` Within minutes, she connected with one of our team members, Bonnie, who asked her about faith. Marie paid close attention as Bonnie shared how Jesus Christ offers salvation to all those who place their faith and trust in Him. Marie responded by`}<strong parentName="p">{` praying a heartfelt prayer to surrender her life to the Lord`}</strong>{`. Please pray that He will strengthen her new faith in Him.
`}</p>
        <div parentName="div" {...{
          "className": "topic-three",
          "id": "everything-to-jesus"
        }}>
          <h2 parentName="div">{`Everything Pointed to Jesus Christ`}</h2>{`
    `}<p parentName="div">{`Sarah* was raised in an observant Jewish family, but`}<strong parentName="p">{` by the time she was 13, she described herself as agnostic`}</strong>{`. Now in her 60s, she realized how hollow she felt. She enrolled in our`}<em parentName="p">{` Know Jesus`}</em>{` course after looking for answers about Christianity online. She had heard of Christianity and it seemed like 
`}<span parentName="p" {...{
              "className": "image-float-left"
            }}>{`
    `}<span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "960px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABamXRhAMP/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAECAxESMf/aAAgBAQABBQKfOWn31IhUSkh5n//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAi/9oACAECAQE/AVv/xAAaEAADAQADAAAAAAAAAAAAAAAAAREhECJC/9oACAEBAAY/ApUjrNPPEMbR/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERITFRcYGh/9oACAEBAAE/IXt3Qpd3tPgr4WeyEctcC2kp7LKTR//aAAwDAQACAAMAAAAQIM//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/EBMuX//EABYRAQEBAAAAAAAAAAAAAAAAAAEAgf/aAAgBAgEBPxBHZn//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRcZGh/9oACAEBAAE/EDB4Q2Nt+4caWLRW32b4nZS5KfbL5XcF8UXNgmUM8uZ//9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "sarah astock",
                  "title": "sarah astock",
                  "src": "/static/b3d627a77e38f41ced9fd1b8d33b3633/6a068/sarah_astock.jpg",
                  "srcSet": ["/static/b3d627a77e38f41ced9fd1b8d33b3633/f93b5/sarah_astock.jpg 300w", "/static/b3d627a77e38f41ced9fd1b8d33b3633/b4294/sarah_astock.jpg 600w", "/static/b3d627a77e38f41ced9fd1b8d33b3633/6a068/sarah_astock.jpg 960w"],
                  "sizes": "(max-width: 960px) 100vw, 960px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span>
            </span>{`
    so much around her pointed to Jesus; she often thought of Him when she read the Psalms or saw others wearing cross necklaces. Early in the course, Sarah prayed to receive Jesus Christ in repentance and faith, and she was excited to share her decision with our team member. “`}<strong parentName="p">{`I want the Holy Spirit to be with me`}</strong>{` and guide me on the path of righteousness to help me follow God’s will,” she typed. We praise God that Sarah completed the course—and then connected with friends who are believers to help her find ongoing discipleship and fellowship. Before their exchange ended, she told our associate that they were helping her grow in her new faith.
`}</p>
        </div>{`
    `}<section parentName="div" {...{
          "id": "twelve-months"
        }}>{`
        `}<div parentName="section" {...{
            "className": "numbers",
            "id": "top-countries"
          }}>{`
        `}<h5 parentName="div">{`In the past 12 months ...`}</h5>{`
        `}<p parentName="div">{`
        More than 9 MILLION visits to Peace With God sites.*
        `}</p>{`
        `}<p parentName="div">{`
        Just over 600,000 people indicated making decisions for Christ.
        `}</p>{`
        `}<p parentName="div">{`
        On average, more than 1,000 people view our sites each hour.
        `}</p>{`
        `}<p parentName="div" {...{
              "className": "numbers-info"
            }}>{`
        *Site languages include: English, Spanish, Portuguese, Arabic, Chinese, Russian, and American Sign Language
        `}</p>{`
        `}</div>{`
        `}<div parentName="section" {...{
            "className": "country-list"
          }}>{`
        `}<p parentName="div" {...{
              "className": "add-arrow"
            }}>{`Visitors to PeaceWithGod.net came from these top 10 countries:`}</p>{`
        `}<ol parentName="div">{`
        `}<li parentName="ol">{`United States`}</li>{`
        `}<li parentName="ol">{`Netherlands`}</li>{`
        `}<li parentName="ol">{`Ghana`}</li>{`
        `}<li parentName="ol">{`Kenya`}</li>{`
        `}</ol>{`
        `}<ol parentName="div" {...{
              "start": 5
            }}>{`
        `}<li parentName="ol">{`Uganda`}</li>{`
        `}<li parentName="ol">{`Zambia`}</li>{`
        `}<li parentName="ol">{`Tanzania`}</li>{`
        `}</ol>{`
        `}<ol parentName="div" {...{
              "start": 8
            }}>{`
        `}<li parentName="ol">{`Canada`}</li>{`
        `}<li parentName="ol">{`Indonesia`}</li>{`
        `}<li parentName="ol">{`Ethiopia`}</li>{`
        `}</ol>{`
        `}</div>
        </section>
        <p parentName="div" {...{
          "className": "scripture"
        }}>{`
    `}<strong parentName="p">{`“I WILL SHOW MY GREATNESS ... AND MAKE MYSELF KNOWN IN THE EYES OF MANY NATIONS. THEN THEY WILL KNOW THAT I AM THE LORD.” `}</strong><span parentName="p" {...{
            "id": "verse"
          }}>{`—EZEKIEL 38:23, ESV`}</span>{`
`}</p>{`
    `}<div parentName="div" {...{
          "className": "topic-two",
          "id": "witnessing"
        }}>{`
    `}<h2 parentName="div">{`Witnessing for Jesus Christ—in the Face of Danger`}</h2>{`
    `}<p parentName="div">{`Only in her teens, Rimsha* had already been through a lot. `}<strong parentName="p">{`ISIS had occupied her hometown, causing a lot of suffering for her and her family.`}</strong>{` A couple of years ago, she decided she didn’t believe in the teachings of Islam, and she began to look into other religions. Christianity seemed like the best option to her, but she wasn’t sure where to start. Recently, she saw an ad for our Arabic-language online evangelism ministry—and she was immediately intrigued.
    `}</p>{`
    `}<p parentName="div">{`
    `}<strong parentName="p">{`Rimsha connected with Samira, one of our team members, and told her that she had made the decision to surrender her life to Jesus Christ.`}</strong>{` Now she wanted to grow closer to Him. Samira encouraged her to read the gospels, and Rimsha enrolled in one of our online courses—and then encouraged her brother Ahmed* to join her. He dove into the lessons and a short time later, he too prayed to receive Jesus Christ in repentance and faith. We praise God for drawing this brother and sister to Himself.`}<strong parentName="p">{` But their story doesn’t end there!`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
    Ahmed sent the course to his friend Tariq,* who described himself as an atheist. Tariq enrolled in the course, and the Holy Spirit worked powerfully in his heart and mind. He placed his faith and trust in Jesus Christ and was eager to share how the Lord 
    `}<span parentName="p" {...{
              "className": "image-float-left"
            }}>{`
        `}<span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "959px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7iTE/NVBgJ//xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERIx/9oACAEBAAEFAmOSuw9R/dItVjn/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BSpD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8Bta//xAAaEAABBQEAAAAAAAAAAAAAAAAQAAESIVGB/9oACAEBAAY/Ak8to9H/xAAZEAEBAAMBAAAAAAAAAAAAAAABABARMSH/2gAIAQEAAT8hlr770TCiQ+Rx3f/aAAwDAQACAAMAAAAQ18//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QvDT/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QAbF//8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUWFx/9oACAEBAAE/EKI7mv8A6gKyFQ1EVlMPMIVkKY9iFRfk/9k=')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "rimsha blend no flower table",
                  "title": "rimsha blend no flower table",
                  "src": "/static/1e12e3a0b0b2afedc15162902e8ce811/9100a/rimsha_blend_no%20flower-table.jpg",
                  "srcSet": ["/static/1e12e3a0b0b2afedc15162902e8ce811/f93b5/rimsha_blend_no%20flower-table.jpg 300w", "/static/1e12e3a0b0b2afedc15162902e8ce811/b4294/rimsha_blend_no%20flower-table.jpg 600w", "/static/1e12e3a0b0b2afedc15162902e8ce811/9100a/rimsha_blend_no%20flower-table.jpg 959w"],
                  "sizes": "(max-width: 959px) 100vw, 959px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span>{`
    `}</span>{`
    completely changed his life. “I was depressed, sad, and felt guilty,” he typed. “I totally feel different now. Even the way I used to talk to my friends has changed. … They were surprised from the way I speak, from peace and kindness that I have. Thank You very much, Jesus, I adore You.” 
    `}</p>{`
    `}<p parentName="div">{`
    Will you pray for Rimsha, Ahmed, and Tariq?`}<strong parentName="p">{` Placing their faith in Jesus Christ can cost them relationships, or even their lives.`}</strong>{` Ask the Lord to protect them and continue using them to reach others with His truth.
    `}</p>{`
    `}</div>{`
    `}<div parentName="div" {...{
          "className": "topic-two",
          "id": "new-creature"
        }}>{`
    `}<h2 parentName="div">{`‘Make me a new creature …’`}</h2>{`
    `}<p parentName="div">{`
  Philip,* who lives in Uganda, went to the internet for answers.`}<strong parentName="p">{` Only in his 30s, his marriage fell apart after he repeatedly committed adultery.`}</strong>{` As he sat totally alone in his house, he 
   `}<span parentName="p" {...{
              "className": "image-float-right"
            }}>{`
        `}<span parentName="span" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "960px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "66.66666666666666%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGjsRiLgoV//8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxETEv/aAAgBAQABBQLouqD1hbzGtNYUnP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAEEAwAAAAAAAAAAAAAAAAEAAhAxEUFh/9oACAEBAAY/ArRdrEEprb7H/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAERITFR/9oACAEBAAE/IcETu5EiV0bD1murU2ENbZ//2gAMAwEAAgADAAAAEK8P/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQMBAT8Qh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExQVFh/9oACAEBAAE/EBkrRpLzcajOYPNz5I3QSaXiu5W5DSo5fJpRFSzV8n//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="span" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "philip uganda astock",
                  "title": "philip uganda astock",
                  "src": "/static/e3811d63360a04ed1ddce50dd4329259/6a068/philip_uganda_astock.jpg",
                  "srcSet": ["/static/e3811d63360a04ed1ddce50dd4329259/f93b5/philip_uganda_astock.jpg 300w", "/static/e3811d63360a04ed1ddce50dd4329259/b4294/philip_uganda_astock.jpg 600w", "/static/e3811d63360a04ed1ddce50dd4329259/6a068/philip_uganda_astock.jpg 960w"],
                  "sizes": "(max-width: 960px) 100vw, 960px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
    `}</span>{`
     `}</span>{`
  realized how empty he felt—and he decided to turn to God. Philip went online for answers about Jesus Christ and `}<strong parentName="p">{`found our`}<em parentName="strong">{` Know Jesus`}</em>{` discipleship course.`}</strong>{` Just a few days into the lessons, he surrendered his life to the Lord. He typed a few of his heartfelt prayers into a chat with one of our team members. “I ask for forgiveness of my sins that I committed in adultery and fornication,” Philip shared. “Please remove the guilt in me and renew my inner person. Make me a new creature that loves to follow God’s commandments.”`}<strong parentName="p">{` Before the course ended, Philip also said he hoped and prayed that God would restore his marriage.`}</strong>{` Will you pray for Philip? Please ask the Lord 
  to work powerfully in his life.
`}</p>
        </div>{`
   
`}<section parentName="div" {...{
          "className": "howtobeapart",
          "id": "how-to-be-a-part-IE"
        }}>{`
    `}<h3 parentName="section" {...{
            "className": "h3-portion"
          }}>{`How you can be a part `}<span parentName="h3" {...{
              "className": "steps"
            }}>{`|`}</span>{` `}<span parentName="h3" {...{
              "className": "smallportion"
            }}>{`Internet Evangelism`}</span>{`
    `}</h3>{`
    `}<p parentName="section">{`
    `}<span parentName="p" {...{
              "className": "step"
            }}>{`PRAY: `}</span>{`Every moment of every day, someone is actively exploring the Gospel through our `}<em parentName="p">{`Search for Jesus`}</em>{` ministry. Thousands of people are looking for hope, meaning, or peace that only Christ can give. Your prayers help share the Good News online with searching hearts. Visit `}<a parentName="p" {...{
              "href": "https://searchforjesus.net/pray/",
              "target": "_blank"
            }}><strong parentName="a">{`SearchforJesus.net/Pray`}</strong></a>{` to see how God is at work.
`}</p>
          <p parentName="section">{`
    `}<span parentName="p" {...{
              "className": "step"
            }}>{`VOLUNTEER: `}</span>{`
    You can help share God’s love with people across the globe without leaving home. To learn how you can volunteer online, visit 
    `}<a parentName="p" {...{
              "href": "https://searchforjesus.net/",
              "target": "_blank"
            }}><strong parentName="a">{`SearchforJesus.net`}</strong></a>{`.
`}</p>
          <p parentName="section">{`
    `}<span parentName="p" {...{
              "className": "step"
            }}>{`GIVE: `}</span>{`
    Your gift can reach people everywhere who search for truth, even where the Gospel cannot be shared openly. The ministry is active worldwide in English, Spanish, Portuguese, Arabic, Chinese, Russian, and American Sign Language.
`}</p>
        </section>
        <p parentName="div"><span parentName="p" {...{
            "className": "footnote"
          }}>{`*Names and photos in testimonies changed to protect privacy`}</span></p>
        <br parentName="div"></br>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      