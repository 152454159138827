import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="content-wrapper featured" id="pre-events">
    <div className="margins">
        <div className="content-container english">
            <h1 className="bottom-title"><span>ПРЕДСТОЯЩИЕ ЗАПЛАНИРОВАННЫЕ</span></h1>
            <h1 className="top-title">МЕРОПРИЯТИЯ</h1>
        </div>
        <div className="content-container" id="pre-events-list">
            <div className="margins">
                <div className="event column">
                    <p className="event-entry-day">Суб.</p>
                    <div className="pre-event-content-container">
                        <p className="date">7 декабря 2024</p>
                        <h2>ПАСТОРСКИй ИНФОРМАЦИОННЫЙ СЕМИНАР</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Эстонская методистская церковь</p>
                                <p>Нарвское шоссе 51</p>
                                <p>10120 Таллинн, Эстония</p>
                            </div>
                            <a id="details1" className="modal-btn" href="#modal1">&#8250;</a>
                        </div>
                        <section id="modal1" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details1" className="modal-close">
                                    <span className="closeModal">close</span>
                                </a>
                                <h1>Пасторский информационный семинар</h1>
                                <p className="time">7 декабря, 10:00</p>
                                <div className="location-block">
                                    <p className="location">Эстонская методистская церковь</p>
                                    <p className="location">Нарвское шоссе 51, 10120 Таллинн, Эстония</p>
                                </div>
                                <p className="details">Приглашаем всех пасторов Эстонии в субботу, 7 декабря, на информационный семинар с подробным описанием предстоящего мероприятия «Время Надежды» с Уиллом Грэмом.</p>
                                <p className="details">Узнайте, как ваша община может поучаствовать посодействовать в деле приближения жителей Эстонии к Иисусу Христу. На встрече будут обсуждаться следующие вопросы:</p>
                                <ul>
                                    <li>Как церковь может стать молитвенным партнером</li>
                                    <li>Как церковь может стать участником движения «Я – Андрей»</li>
                                    <li>Как проведение курса «Христианская жизнь и свидетельство» (ХЖС) может принести пользу вашей церкви</li>
                                    <li>Приглашение прошедших курс ХЖС принять участие на мероприятии «Время Надежды» в качестве душепопечителей или волонтеров</li>
                                    <li>Как записаться на групповую регистрацию</li>
                                    <li>Важность последующей работы с теми, кто примет Иисуса Христа на празднике «Время Надежды» </li>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="event column">
                    <p className="event-entry-day">Суб.</p>
                    <div className="pre-event-content-container">
                        <p className="date">25 января 2025</p>
                        <h2>СТАРТ «ВРЕМЕНИ НАДЕЖДЫ»</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Церковь Олевисте</p>
                                <p>Лай 50</p>
                                <p>10133 Таллинн, Эстония</p>
                            </div>
                            <a id="details2" className="modal-btn" href="#modal2">&#8250;</a>
                        </div>
                        <section id="modal2" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details2" className="modal-close"><span className="closeModal">close</span></a>
                                <h1>Запуск «Времени Надежды»</h1>
                                <p className="time">25 января, 10:00</p>
                                <div className="location-block">
                                    <p className="location">Церковь Олевисте</p>
                                    <p className="location">Лай 50, 10133 Таллинн, Эстония</p>
                                </div>
                                <p className="details">Приглашаются пасторы и лидеры церквей Эстонии, христианские организации и семейные пары в субботу, 25 января, на вечер хвалы и общения с вдохновляющим словом и призывом к действию.</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      