import React from 'react';

const donationHeaderSpiritualBootCamp = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great news {firstName}! Your "Spiritual Boot Camp" is on its way to your
      inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart to grow closer to Him, and we hope this
        devotional will help you be{' '}
        <em>
          “transformed by the renewal of your mind, that by testing you may
          discern what is the will of God”
        </em>{' '}
        (Romans 12:2, ESV).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer materials
        like this because of friends like you. The Lord is using the gifts and
        prayers of believers like you to reach people across the globe with His
        love—and there are so many more who need to hear the Good News.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote className="modalBlockquote">
        Your gift of $50 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderSpiritualBootCamp;
