import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`
     We praise God that Easter will have new meaning this year for
    thousands of people along the southern border in Texas, Arizona, and
    California.
  `}</p>{`
  `}<p parentName="div">{`
     In a region where many feel isolated and forgotten,
    `}<strong parentName="p">{`
      more than 58,000 individuals heard the Good News of Jesus Christ during
      our 10-stop God Loves You Frontera Tour.
    `}</strong>{`
    This was our first bilingual evangelistic tour, and both the music and
    message were in English and Spanish. What a privilege it was to see God at
    work transforming thousands of lives by the power of His love!
  `}</p>{`
  `}<p parentName="div">{`
    `}<strong parentName="p">{`
       It’s difficult to describe the overwhelming sense of joy at seeing
      people from all walks of life coming forward to receive Jesus Christ as
      Lord and Savior of their lives.
    `}</strong>{`
    Heat, cold, and wind didn’t slow them down.
    `}<span parentName="p" {...{
          "className": "image-float-left"
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQDBQb/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABeUkr40gqV//EABwQAQACAQUAAAAAAAAAAAAAAAIBAwQAESEjJP/aAAgBAQABBQJWIzXk7aChgzw57cSfP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AWf/xAAWEQADAAAAAAAAAAAAAAAAAAACECH/2gAIAQIBAT8BCr//xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIhQREyUf/aAAgBAQAGPwJ3rw642KlslFTxhEn/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAxIUGB/9oACAEBAAE/IUAqeHRquqq/dzQxemeumAV8/dff/9oADAMBAAIAAwAAABAPz//EABYRAAMAAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxCQv//EABYRAQEBAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxAu5//EABsQAQADAQEBAQAAAAAAAAAAAAEAESExUUGR/9oACAEBAAE/EAtrXK799Y7JQTXoBdl59Vl9ikPQp7CHdvO4FIzk4IfrP//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "counseler",
              "title": "counseler",
              "src": "/static/905c2f7182d98df64cf158fcd227dae6/6a068/counseler.jpg",
              "srcSet": ["/static/905c2f7182d98df64cf158fcd227dae6/f93b5/counseler.jpg 300w", "/static/905c2f7182d98df64cf158fcd227dae6/b4294/counseler.jpg 600w", "/static/905c2f7182d98df64cf158fcd227dae6/6a068/counseler.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</span>{`
    Many people from churches just across the border in Mexico also brought
    their neighbors for the night so they could hear the Gospel. My friend David
    Ruiz interpreted each message into Spanish, and every night of the tour it
    was clear that men, women, and children were hungry for
    `}<em parentName="p">{`“the word of the truth, the gospel”`}</em>{`
    (Colossians 1:5, ESV).
  `}</p>{`
  `}<p parentName="div">{`
     The God Loves You Frontera Tour kicked off in
    `}<strong parentName="p">{`Brownsville, Texas`}</strong>{`, where more than 550 people responded to
    the Good News. The very next evening the tour moved on to McAllen, where
    more than 410 people made decisions for Jesus Christ. One teenager, Nayeli,*
    felt the prompting of the Holy Spirit when I gave the invitation to repent
    and believe. She got up from her seat and walked forward. Although she had
    been raised in a Christian home, Nayeli did not have a relationship with the
    Lord—and that was weighing heavily on her heart. “You listen to what your
    friends say, you get involved in the things of the world,” she explained.
    `}<strong parentName="p">{`“I knew that I was on the side of what is not Christian.”`}</strong>{`
    Her mom was shocked—and thrilled—when she watched Nayeli receive Jesus
    Christ in repentance and faith. “When I saw her stand up, I never imagined
    that she was standing up to come forward,” she said. “My prayer has always
    been that she would accept Jesus as her Lord and Savior.”
  `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      