import React from 'react';

const donationHeaderHymnsfortheSoul = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your copy of <em>Hymns for the Soul</em> should arrive in  in 2–4 weeks.
    </h2>
    <br></br>
    <div>
      <p>
      We praise the Lord for your heart for worship and we pray this resource will encourage you to share your faith as you 
       “<em>sing of the steadfast love of the Lord, forever</em>” (Psalm 89:1, ESV).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
      God is using friends like you to reach people with His love in a variety of 
      ways through the Billy Graham Evangelistic Association, including the Billy Graham 
      24/7 Prayer Line, online Gospel resources, and Billy Graham Rapid Response Team 
      chaplains, who comfort and pray with hurting people in the midst of crisis.
      </p>
      <p>
        This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift today will help share the hope of the Gospel with people
        around the world who are far from God.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderHymnsfortheSoul;
