import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div {...{
        "style": {
          "display": "block"
        }
      }}>{`
        `}<div parentName="div" {...{
          "className": "image-float-left-big"
        }}>{`
            `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAA//aAAwDAQACEAMQAAABz3MmdKgwP//EABsQAAICAwEAAAAAAAAAAAAAAAECABESEyED/9oACAEBAAEFAtXW8RWp50nI3k7T/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AYiI/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREv/aAAgBAgEBPwHTKz//xAAaEAEAAwADAAAAAAAAAAAAAAAAAREhMlHh/9oACAEBAAY/AmRL1qu3Kn//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhQVGRsf/aAAgBAQABPyG5s8YAelIdRAgvb9ie98lhppTMJ//aAAwDAQACAAMAAAAQiz//xAAVEQEBAAAAAAAAAAAAAAAAAAAAcf/aAAgBAwEBPxAVf//EABgRAAIDAAAAAAAAAAAAAAAAAAABETFR/9oACAECAQE/EKoHqj//xAAeEAEBAAICAgMAAAAAAAAAAAABEQAhMUFRYXGBof/aAAgBAQABPxBOCiWwvqY2BvX5vvFFBHztjlHUWAV5MYPWeQsdfHOVNh1Hc7z/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "kidzfest 240211 tp1 7764 inet1",
              "title": "kidzfest 240211 tp1 7764 inet1",
              "src": "/static/4bc7d76febc9d2b64b2c2db54ae3789e/6a068/kidzfest_240211_tp1_7764_inet1.jpg",
              "srcSet": ["/static/4bc7d76febc9d2b64b2c2db54ae3789e/f93b5/kidzfest_240211_tp1_7764_inet1.jpg 300w", "/static/4bc7d76febc9d2b64b2c2db54ae3789e/b4294/kidzfest_240211_tp1_7764_inet1.jpg 600w", "/static/4bc7d76febc9d2b64b2c2db54ae3789e/6a068/kidzfest_240211_tp1_7764_inet1.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
            `}<figcaption parentName="div">{`THE FESTIVAL KICKED OFF with the family-friendly Festikids event which featured high-energy music and engaging performances from local youth.`}</figcaption>{`
        `}</div>{`
        `}<div parentName="div" {...{
          "className": "image-float-right-big"
        }}>{`
            `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAE3rNeCrdU//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIDEQAhBBIi/9oACAEBAAEFAl94qabTA0eO56zipP/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAgEBPwEYv//EABkQAAIDAQAAAAAAAAAAAAAAAAABEBExIf/aAAgBAQAGPwLDJd9Gf//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExQVH/2gAIAQEAAT8hRt0/EP6y3VaXEOoL4lGsix//2gAMAwEAAgADAAAAEIs//8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8QSf/EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QwVh//8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUWFx/9oACAEBAAE/EKwU/RfhzCAoqHdcgeSoWh5MwS4I76mU1BEt63ANzgbZ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "nadia sing no mask 240211 lr2 4097 inet",
              "title": "nadia sing no mask 240211 lr2 4097 inet",
              "src": "/static/2fec6c90e426d7494cc0971240672c30/6a068/nadia_sing_no_mask_240211_lr2_4097_inet.jpg",
              "srcSet": ["/static/2fec6c90e426d7494cc0971240672c30/f93b5/nadia_sing_no_mask_240211_lr2_4097_inet.jpg 300w", "/static/2fec6c90e426d7494cc0971240672c30/b4294/nadia_sing_no_mask_240211_lr2_4097_inet.jpg 600w", "/static/2fec6c90e426d7494cc0971240672c30/6a068/nadia_sing_no_mask_240211_lr2_4097_inet.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
            `}<figcaption parentName="div">{`
                GRAMMY-NOMINATED ARTIST NADIA—who is a native of Oaxaca, Mexico—was among those who performed powerful, Christ-centered music at the Festival.
            `}</figcaption>{`
        `}</div>{`
    `}</div>{`
    `}<p>{`
        As we look ahead to the rest of 2024, my father will be preaching the 
        Good News in`}<strong parentName="p">{` Poland, the United Kingdom, Italy, and Vietnam`}</strong>{`. At a recent gathering in Naples, we saw 
        Christians praying fervently for their city, and they erupted with joy when we announced that the Piazza del Plebiscito—Naples’ 
        large public square—had been secured for the event later this year. Such is their passion for proclaiming the hope of Jesus to 
        their neighbors!
    `}</p>{`
    `}<h3 {...{
        "className": "specialstyling mt-6",
        "id": "god-loves-you-tour"
      }}><span parentName="h3" {...{
          "className": "category"
        }}>{`EVANGELISTIC CRUSADES`}</span></h3>{`
    `}<h4>{`Planning the Proclamation`}</h4>{`
     `}<div {...{
        "className": "image-float-left-margin"
      }}>{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWe0kVoB/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAIBERMDEv/aAAgBAQABBQLU3o9MxzildYguj//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwG1r//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAgEBPwGMv//EABsQAAMAAgMAAAAAAAAAAAAAAAABEQJBMYGR/9oACAEBAAY/AnEcIqwXp3Saot0//8QAHRAAAgIDAAMAAAAAAAAAAAAAAREAITFRcUGRof/aAAgBAQABPyFzpWc1DVm55gaIDcUex9oZItG+wpYDJfZ//9oADAMBAAIAAwAAABAHP//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QYphJ/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxALy1f/xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFxgcH/2gAIAQEAAT8QrAsgppnL59ZvleBHuuTKQxdCX5kmhWmS7EfhligAXewd/cJSobOzbV88z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "naples prayer crowd ky suggest inet",
            "title": "naples prayer crowd ky suggest inet",
            "src": "/static/397d620ca05869772b453048490a7a11/6a068/naples_prayer_crowd_ky_suggest_inet.jpg",
            "srcSet": ["/static/397d620ca05869772b453048490a7a11/f93b5/naples_prayer_crowd_ky_suggest_inet.jpg 300w", "/static/397d620ca05869772b453048490a7a11/b4294/naples_prayer_crowd_ky_suggest_inet.jpg 600w", "/static/397d620ca05869772b453048490a7a11/6a068/naples_prayer_crowd_ky_suggest_inet.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
        `}<figcaption parentName="div">{`
            CHRISTIAN LEADERS IN NAPLES, ITALY, pray for revival in their nation ahead of Franklin’s Noi Festival this fall.
        `}</figcaption>{`
    `}</div>{`
    `}<p>{`
         I’m also busy planning an exciting year of ministry.  As you read this, I’m on the road `}<strong parentName="p">{`launching evangelistic outreaches in Northern Colorado and central Arizona`}</strong>{`.
        The churches there are united in prayer and burdened to share the truth of Jesus 
        Christ with their communities.
    `}</p>{`
    `}<p>{`
        Next month, I’m heading north to `}<strong parentName="p">{`Canada`}</strong>{` for a pair of events. On April 4, we’re launching the 
        `}<strong parentName="p">{`Grande Prairie Look Up Celebration`}</strong>{`, an evangelistic outreach that will take place in `}<strong parentName="p">{`Alberta this fall`}</strong>{`. 
        From there, I’ll travel to `}<strong parentName="p">{`British Columbia to take part in a very special Chinese Evangelism Congress`}</strong>{`. During this free, 
        one-day event (translated into Mandarin, Cantonese, and English), participants will worship together and be inspired and 
        challenged to focus on the Gospel and reach others with Christ’s hope.
    `}</p>{`
    `}<p>{`
        Will you join me in praying for the upcoming Celebrations as churches in Colorado, Arizona, and Alberta prepare the ground for 
        the seeds of the Gospel? Pray also for Chinese believers in Canada, that they would be equipped and emboldened in their work 
        to share the Good News. 
    `}</p>{`
    `}<h4>{`TRUE FREEDOM IN PORTUGAL`}</h4>{`
    `}<p>{`
        Following my trip to Canada, `}<strong parentName="p">{`I will preach in Porto, Portugal, during the Celebration of Hope on April 26`}</strong>{`. Fifty years ago, 
        the Carnation Revolution took place in this nation, and they celebrate with a national holiday on April 25 called Freedom Day. 
        Now, 50 years later (almost to the day), I’m honored to preach Christ and tell people in Portugal’s second-largest city “`}<em parentName="p">{`if the 
        Son makes you free, you shall be free indeed`}</em>{`” (John 8:36, NKJV).
    `}</p>{`
    `}<p>{`
        Will you join me in praying that many would find the hope of Jesus—in Porto and beyond—
        through the Celebration of Hope?
    `}</p>{`
    `}<h4>{`“GO INTO ALL THE WORLD …”`}</h4>{`
    `}<p>{`
        My friends, we feel a never-ending burden to 
        follow the Great Commission and go into all the world with the Gospel of Jesus Christ.
         Nothing else matters in the scope of eternity. Only the Gospel of Jesus Christ—the hope found in His death, burial, and resurrection—has 
         the power to truly change the human heart. `}<strong parentName="p">{`Thank you for partnering with us in this effort. You are a blessing,`}</strong>{` and your support is 
         impacting the eternal destination of people across the United States and to the ends of the earth. 
    `}</p>{`
    `}<p>{`
        From my family to yours, may you have a happy and blessed Easter!
    `}</p>{`
    `}<div>{`
            `}<p parentName="div">{`God bless you,`}</p>{`
            `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2021/02/Will-Graham-Signature-2.png.webp",
          "alt": ""
        }}></img>{`
            `}<p parentName="div">{`Will Graham`}</p>{`
            `}<p parentName="div">{`Executive Vice President`}</p>{`
    `}<span parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<span parentName="span" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAGillQrpLB//8QAGhAAAgMBAQAAAAAAAAAAAAAAAgMBERIABP/aAAgBAQABBQJjcAHps98ZTkahpNm//8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/AYy//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAESIf/aAAgBAgEBPwFPCj//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIRIQMxQVFh/9oACAEBAAY/AroUa8NhxecGm64Oj//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExUWGB/9oACAEBAAE/ISbZnKy20QOX+y9CG2yhO19Yt1gzJ//aAAwDAQACAAMAAAAQPP8A/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAh/9oACAEDAQE/EDSf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCXSz//xAAcEAEAAwACAwAAAAAAAAAAAAABABEhQVExcfD/2gAIAQEAAT8QqJBALyMoOTLvZ9xKgg7oRRgVnpfoh6YS/Be3Ay+ALJ//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "fg mws 240111 sc1 8682 inet",
              "title": "fg mws 240111 sc1 8682 inet",
              "src": "/static/97ff7f5f1bb5462021081cc0b600df6c/6a068/fg_mws_240111_sc1_8682_inet.jpg",
              "srcSet": ["/static/97ff7f5f1bb5462021081cc0b600df6c/f93b5/fg_mws_240111_sc1_8682_inet.jpg 300w", "/static/97ff7f5f1bb5462021081cc0b600df6c/b4294/fg_mws_240111_sc1_8682_inet.jpg 600w", "/static/97ff7f5f1bb5462021081cc0b600df6c/6a068/fg_mws_240111_sc1_8682_inet.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</span>{`
    `}</div>{`
    `}<p>{`
        P.S. My father has filmed a powerful Easter message from Israel. This special program also features music by Michael W. Smith, who was with him in the Holy Land. You can watch it on Fox News at noon EDT on March 31—or on local channels and online. It’s a great way to share the glorious message of Christ’s resurrection with your friends and family.
    `}</p>{`
   `}</undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      