import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="urgent-center">
      <h2 className="urgent-title">SO MANY MORE STILL NEED TO HEAR</h2>
      <h3>When you give, you share the hope of the Gospel with lost men and women.</h3>
      <p>Each day, an average of 155,000 people slip into eternity—and the vast majority aren’t prepared to 
stand before God because they don’t know His Son. With your gift, you’re making it possible for people
 in nearly every nation on earth—including many closed to evangelism—to learn about Jesus Christ, who 
 died to rescue them from their sin. God is using Christians like you to reach lost and hurting individuals 
 who are,“<em>without hope and without God</em>” (Ephesians 2:12, HCSB) around the
  world with His love, through nearly every effective means possible.</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      