import React from 'react';

const donationHeaderCissieEaster = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise the Lord for your desire to draw closer to Him through His
        Word this year. It’s our prayer that this resource will encourage you
        with the promise that “the law of the Lord is perfect, reviving the
        soul; the testimony of the Lord is sure” (Psalm 19:7, ESV).
      </p>
      <p>Before you go, there are a few things we’d like you to know…</p>
      <p>
        God is using friends like you to reach people with His love in a variety
        of ways through the Billy Graham Evangelistic Association, including a
        24-hour prayer line, online Gospel resources, and Billy Graham Rapid
        Response Team (BG-RRT) chaplains, who comfort and pray with hurting
        people in the midst of crisis.
      </p>
      <p>
        This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <p>
        When you make a gift today, you can reach people across the globe with
        the message of God’s love. In a time when so many are overcome with
        anger, fear, or hopelessness, the Good News of Jesus Christ is exactly
        what they need to hear. Will you help share it with them?
      </p>
      <blockquote className="modalBlockquote">
        Your gift today will help share the Gospel with people around the world
        who are far from God and equip them with powerful Biblical resources to
        become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderCissieEaster;
