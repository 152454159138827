import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <footer {...{
      "className": "new-footer",
      "id": "en-footer"
    }}>{`
    `}<section parentName="footer">{`
        `}<div parentName="section" {...{
          "className": "textwidget custom-html-widget"
        }}>{`
            `}<div parentName="div" {...{
            "className": "columns"
          }}>{`
                `}<div parentName="div" {...{
              "className": "column logo-column"
            }}>{`
                    `}<a parentName="div" {...{
                "href": "https://billygraham.org",
                "target": "_blank",
                "rel": "noreferrer"
              }}><img parentName="a" {...{
                  "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/may/BGEA%20logo_A%20ministry%20of_WHITE.svg",
                  "alt": "Billy Graham Evangelistic Association",
                  "width": 141,
                  "height": 60
                }}></img></a>{`
                `}</div>{`
                `}<div parentName="div" {...{
              "className": "column"
            }}>{`
                    `}<p parentName="div"><strong parentName="p">{`Local Office:`}</strong></p>{`
                    `}<p parentName="div">{`Niine 11, Tallinn 10414`}</p>{`
                    `}<p parentName="div">{`+373 57877748`}</p>{`
                `}</div>{`
                `}<div parentName="div" {...{
              "className": "column"
            }}>{`
                    `}<p parentName="div"><a parentName="p" {...{
                  "href": "https://billygraham.org/privacy-policy/",
                  "target": "_blank",
                  "rel": "noreferrer"
                }}><strong parentName="a" {...{
                    "className": "privacy"
                  }}>{`PRIVACY POLICY`}</strong></a><br parentName="p"></br>{`© 2024  | BGEA is a registered 501(c)(3)`}<br parentName="p"></br>{`non-profit organization.`}</p>{`
                `}</div>{`
            `}</div>{`
        `}</div>{`
    `}</section>
    </footer>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      