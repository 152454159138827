import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABWnpIFZFxD//EABsQAAMAAgMAAAAAAAAAAAAAAAEDEQACEiEx/9oACAEBAAEFAvTdZMKwQxfBfU//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BbX//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8BrH//xAAZEAACAwEAAAAAAAAAAAAAAAAAAREhMRD/2gAIAQEABj8CgvF1w6M0/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERITFRYf/aAAgBAQABPyFLApGu+HTQaso+VG5fhihHGKz/2gAMAwEAAgADAAAAEAAf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxAZ05f/xAAYEQACAwAAAAAAAAAAAAAAAAAAARExUf/aAAgBAgEBPxCDsWz/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMUFhccH/2gAIAQEAAT8QKbAl09sNGaAxtwKSJdGuMKrDp6wBdxCyechVggzfHzP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "frontera pre pray crowd",
            "title": "frontera pre pray crowd",
            "src": "/static/6802aa6e5a34945d3ae33efb253b0c04/6a068/frontera-pre-pray-crowd.jpg",
            "srcSet": ["/static/6802aa6e5a34945d3ae33efb253b0c04/f93b5/frontera-pre-pray-crowd.jpg 300w", "/static/6802aa6e5a34945d3ae33efb253b0c04/b4294/frontera-pre-pray-crowd.jpg 600w", "/static/6802aa6e5a34945d3ae33efb253b0c04/6a068/frontera-pre-pray-crowd.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`HUNDREDS OF CHRISTIANS GATHERED in Brownsville, Texas, to ask God to bring revival to their community—and all of the tour cities—during the upcoming God Loves You Frontera Tour.`}</figcaption>{`
    `}<p parentName="div">{`
    Scott, a pastor who helped host this course at his church in Yuma, shared how much the event meant to him. “[The] content was exactly what we needed to hear in preparation for the March event.” Leaders like him have told us for decades that `}<strong parentName="p">{` this training alone is one of the greatest investments we make in a community—and the impact is felt for years to come.`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
    Excitement is building among local Christians, and more than 4,300 have attended the Nights of Prayer and Worship we’ve held. At these powerful events, believers have faithfully asked God to draw their neighbors into a relationship with His Son. The local church is passionate about this outreach, and at several events it was standing-room only.
    `}</p>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
    `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "30.333333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHUiGbAK//EABgQAAMBAQAAAAAAAAAAAAAAAAECEgMT/9oACAEBAAEFAmLUek5Bo//EABURAQEAAAAAAAAAAAAAAAAAABBB/9oACAEDAQE/Aaf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAEBAQEAAAAAAAAAAAAAAAABACEi/9oACAEBAAY/AkJ26b//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMWGR/9oACAEBAAE/Iagk6PRHopst5P/aAAwDAQACAAMAAAAQB8//xAAWEQADAAAAAAAAAAAAAAAAAAABECH/2gAIAQMBAT8QMT//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QEX//xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMUGBof/aAAgBAQABPxBYjRHt8w8FJw9+zBDSxFYQ1XP/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "frontera bus",
              "title": "frontera bus",
              "src": "/static/f9bc49efa26481716f00b33abf7d5d10/6a068/frontera-bus.jpg",
              "srcSet": ["/static/f9bc49efa26481716f00b33abf7d5d10/f93b5/frontera-bus.jpg 300w", "/static/f9bc49efa26481716f00b33abf7d5d10/b4294/frontera-bus.jpg 600w", "/static/f9bc49efa26481716f00b33abf7d5d10/6a068/frontera-bus.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    
    `}</div>{`
    `}<p parentName="div">{`
    Carlos, who leads a Hispanic church in one community, told us how thrilled he is about the tour after the Night of Prayer and Worship in his city.
    `}<strong parentName="p">{` “I have pastored the same church for 30 years, and I’ve never seen anything as wonderful as this,”`}</strong>{` he said. “We have participated in every step of the preparation and are so excited for the event.`}<strong parentName="p">{` Thank you for the investment in our city.`}</strong>{`” Pray for a mighty move of God across the region, both through the preparations and during the evangelistic outreaches themselves.
    `}</p>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "god-loves-you-tour"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`EVANGELISTIC CRUSADES`}</span></h3>{`
    `}<h4 parentName="div">{`Mexico City`}</h4>{`
    `}<p parentName="div">{`I’m preparing to return to `}<strong parentName="p">{` Mexico City, Mexico.`}</strong>{` God gave me the opportunity to preach in this massive city a year ago, and `}<strong parentName="p">{`because of the great need there—and the hunger we saw—I’m so thankful for the chance to share the Gospel once again.`}</strong>{` We’re holding a Preach the Word Pastor Conference before the Festival to equip and encourage hundreds of local pastors and church leaders, and my friend Skip Heitzig is teaching on proclamation evangelism. Please pray especially for these leaders as they labor tirelessly to “`}<em parentName="p">{`speak the word of God with boldness`}</em>{`” (Acts 4:31, ESV). `}</p>{`
    `}<p parentName="div">{`
    We praise God that more than 15,000 people have participated in Christian Life and Witness Courses in the city. Tens of thousands of believers have been faithfully praying for their neighbors, and we’ve lined up `}<strong parentName="p">{`over 750 buses to bring people to the Festival from across the sprawling metro area.`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
    Please pray that “`}<em parentName="p">{`the eyes of those who are blind will be opened, and the ears of those who 
    are deaf will be unstopped`}</em>{`”  (Isaiah 35:5, NASB) during the Festival.
    `}</p>
      </div>
      <div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "christmas-at-the-library"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`GOSPEL TV SPOTS`}</span></h3>{`
    `}<h4 parentName="div">{`Broadcasting Hope for the New Year`}</h4>{`
    `}<p parentName="div">{`
    Just a few days ago, `}<strong parentName="p">{`our new evangelistic TV and radio spot finished airing across the nation.`}</strong>{`
    In it, I urged listeners to consider their resolutions for the new year and make the most important
    decision ever—to receive Jesus Christ in repentance and faith. I thank God that He used this ad to
    draw people to Himself, including many who called our Billy Graham 24/7 Prayer Line.
    `}</p>{`
    `}<p parentName="div">{`
    Maxine* was among those who called in. She saw the Gospel spot and wanted to pray with
    someone about her health concerns. When Kelly,* our prayer line attendant, asked about her spiritual
    walk, Maxine said she is a sinner who prays a lot—but she didn’t personally know Christ. Kelly
    explained the Gospel to her, and `}<strong parentName="p">{`Maxine finally understood what it means to have a relationship
    with God through His Son, Jesus Christ.`}</strong>{` The two prayed together, and our team
    member sent her resources to help her grow closer to the Lord.
    `}</p>{`
     `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
    `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAgP/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHKlrBnEH//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIDEhEh/9oACAEBAAEFAtlc9z9EUtqx91//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAgEBPwGH/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQASIUEQEXH/2gAIAQEABj8CO6TWnuYKM2v/xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMVFh/9oACAEBAAE/IRq4YyXQWfjAr3xm8uaE/vLc/S6t/9oADAMBAAIAAwAAABC/3//EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxBbn//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESEx/9oACAECAQE/EFdjtm//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMVFhcf/aAAgBAQABPxAGkNoy8YSczHP1mEUCrZb1Np3V+yitQ2V054l7BsNPs//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Visitors line along fence to observe the Live Nativity on their way into the Library.",
              "title": "Visitors line along fence to observe the Live Nativity on their way into the Library.",
              "src": "/static/728e87bfead597556b1239828a769423/6a068/february-chaplains-pray.jpg",
              "srcSet": ["/static/728e87bfead597556b1239828a769423/f93b5/february-chaplains-pray.jpg 300w", "/static/728e87bfead597556b1239828a769423/b4294/february-chaplains-pray.jpg 600w", "/static/728e87bfead597556b1239828a769423/6a068/february-chaplains-pray.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div" {...{
            "className": "category"
          }}>{`CHAPLAINS PRAY with hurting residents in the wake of destructive tornadoes in Tennessee and North Carolina.`}</figcaption>{`
    `}</div>
      </div>
      <div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "christmas-at-the-library"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span></h3>{`
    `}<h4 parentName="div">{`Ministering in Jesus’ Name `}</h4>{`
    `}<p parentName="div">{`
    Our Billy Graham Rapid Response Team chaplains have been faithfully ministering in several communities after deadly tornadoes hit Nashville, Tennessee, and Claremont, North Carolina.
    `}</p>{`
    `}<p parentName="div">{`
    Two chaplains recently met Cindy,* whose home in Tennessee had been damaged by the storm. Like many people we meet in the midst of tragedy, she had faced crisis after crisis long before the tornado. She told our chaplains that she had been orphaned at the age of 13. Years later, she had to bury her young daughter.`}<strong parentName="p">{` Cindy was broken, and she blamed God for much of what she had endured.`}</strong>{`
    `}</p>{`
    `}<div parentName="div">{`
    `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "75%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDAf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABfJWyyEC//8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAAxIR/9oACAEBAAEFAix0xsA20fm1tyhfs//EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQMBAT8BpmH/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEgH/2gAIAQIBAT8BpWv/xAAdEAACAQQDAAAAAAAAAAAAAAAAAQIQERIxIjJB/9oACAEBAAY/AnHN3Nnajy5S8ZpH/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARIUGBMaH/2gAIAQEAAT8hARhobqAF6zmGxclySynCekpUAqv5z//aAAwDAQACAAMAAAAQ8D//xAAYEQACAwAAAAAAAAAAAAAAAAAAASExYf/aAAgBAwEBPxBzoyR//8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERMWH/2gAIAQIBAT8QSRZsf//EAB0QAAIDAAIDAAAAAAAAAAAAAAERACExQaFRgfD/2gAIAQEAAT8QBS7QzxDEAzwXpURRRG2I7ENgDK0Dq7rgcI0vn5z2hNiZ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "february rapid response",
              "title": "february rapid response",
              "src": "/static/24924e89e2973e1e6f1b63398c9d58c2/6a068/february-rapid-response.jpg",
              "srcSet": ["/static/24924e89e2973e1e6f1b63398c9d58c2/f93b5/february-rapid-response.jpg 300w", "/static/24924e89e2973e1e6f1b63398c9d58c2/b4294/february-rapid-response.jpg 600w", "/static/24924e89e2973e1e6f1b63398c9d58c2/6a068/february-rapid-response.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`HUNDREDS OF HOMES AND BUSINESSES WERE DESTROYED by deadly tornadoes in Tennessee in December.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{`
    Our chaplains listened as she poured out her heart—and then she was open to hearing what they had to say. They asked her if they could share with her the only source of real hope, that “`}<em parentName="p">{`God shows his love for us in that while we were still sinners, Christ died for us`}</em>{`”  (Romans 5:8, ESV). The Holy Spirit began to soften Cindy’s heart, and she listened with an open mind. 
    `}</p>{`
    `}<p parentName="div">{`
    After they explained the Gospel, Cindy said she wanted to surrender her life to the Lord in repentance and faith. When she finished praying, our chaplains gave her a new Bible. A couple of hours later, they returned to check on her—and her entire countenance had changed.`}<strong parentName="p">{` Cindy’s emotional burdens had lifted as she experienced “`}<em parentName="strong">{`the peace of God, which surpasses all understanding`}</em>{`”  (Philippians 4:7, ESV).`}</strong>{` Please pray for her and for the many others in Tennessee, Kentucky, Mississippi, and North Carolina who are reeling from the tornadoes.
`}</p>
      </div>
      <br></br>
      <div>{`
    `}<h4 parentName="div">{`PRAY FOR 2024 GOSPEL OPPORTUNITIES`}</h4>{`
    `}<p parentName="div">{`
    Please join me in praying for all of the upcoming evangelistic outreaches this year, including the Festival in Mexico City and the God Loves You Frontera Tour. `}<strong parentName="p">{`None of this ministry would be possible without friends like you, and I am deeply grateful.`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham`}</p>{`
    `}<p parentName="div">{`President & CEO`}</p>{`
    `}<p parentName="div">{`*Names changed to protect privacy`}</p>
      </div>
      <hr></hr>
      <div {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the Franklin Graham Festivals in Mexico City, Mexico, and Kraków, Poland, this year.
    `}</p>{`
     `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`GOSPEL TV SPOTS`}</span>{` | Your gift to `}<strong parentName="p">{`Gospel TV Spots `}</strong>{`helps air the Good News through TV ads all across America and offer a 24/7 prayer line for people who want to talk or pray with someone.
    `}</p>{`
        `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
    `}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      