import React from 'react';
import "./conocer-a-jesus.scss"

const conocerJesusConfirmation = () => (
  <div className='callout'>
    <p>
      <strong>¡Gracias por solicitar el estudio bíblico!</strong> 
    </p>
    <p>
    Recibirás tu copia entre 4 a 6 semanas. Agradecemos a Dios por la 
    decisión que tomaste durante Route 66 God Loves You Tour y oramos 
    para que el Señor use este libro para animarte y fortalecerte mientras 
    caminas con Él.
    </p>
    <p>
    ¡Que Dios te bendiga muchísimo!
    </p>
  </div>
);

export default conocerJesusConfirmation;
