import React from 'react';

const donationHeaderIsHellReal = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <br />
      <p>
        We praise the Lord for your heart to draw near to Christ and lead others
        to follow Him. It’s our prayer that this resource will serve as an
        encouraging reminder that{' '}
        <em>“everyone who calls on the name of the Lord will be saved”</em>{' '}
        (Romans 10:13, ESV).
      </p>
      <p>
        <strong>
          Before you go, there are a few things we’d like you to know…
        </strong>
      </p>
      <p>
        God is using the Billy Graham Evangelistic Association and friends like
        you to reach people across the globe with His love in a variety of ways,
        including evangelistic events, a 24-hour prayer line, online resources,
        and Billy Graham Rapid Response Team (BG-RRT) chaplains.
      </p>
      <p>
        This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <p>
        When you make a gift today, the Lord can use you to reach people across
        the globe with the hope of Easter. There is no greater time than now to
        help share the Good News of Jesus Christ.
      </p>
      <p>
        <strong>Will you give them the opportunity to hear it?</strong>
      </p>
      <blockquote>
        Your gift of $50 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderIsHellReal;
