import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationHP } from '@bgea-js/form-templates';
import customConfirmationHPV from "../../../../src/content/ride-signup-movie/customConfirmation";
import "../../../../src/content/ride-signup-movie/ride-signup-movie.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationHP,
  customConfirmationHPV,
  React
};