import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`
    Just a few weeks ago, I preached the Gospel at a special event in
    `}<strong parentName="p">{` Seoul, South Korea`}</strong>{`, one of the most populous cities on
    Earth. These services marked the 50`}<sup parentName="p">{`th`}</sup>{`
    anniversary of my father's massive Crusade there—his largest ever.
  `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/06/seoul-man-praying.jpg",
          "alt": "Seoul man praying."
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`
    Buddhist temples still dot the city just like they did in 1973—but now Seoul
    is so large it's considered a megacity. By some estimates, it has the
    world's busiest airport. In contrast to its booming prosperity, there is
    still great spiritual poverty. South Korea has
    `}<strong parentName="p">{`
      one of the highest suicide rates in the world, and many have no idea where
      to turn for hope.
    `}</strong>{`
    I'm grateful I had the opportunity to proclaim the Good News that
    `}<em parentName="p">{`“the free gift of God is eternal life in Christ Jesus our Lord”`}</em>{`
    (Romans 6:23, ESV) to over 70,000 people.
    `}<strong parentName="p">{`
      Over 6,000 responded by surrendering their lives to Him in repentance and
      faith, including Yun-Jung Shim.*
    `}</strong>{`
  `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/06/seoul-woman-praying.jpg",
          "alt": "Seoul woman praying."
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`
    She had attended church as a teenager, but when she got married, her
    husband’s family discouraged her from having anything to do with
    Christianity. “They wanted me to believe in Buddhism. Last week my friend
    invited me to church and then invited me here,” she shared. When I gave the
    invitation, Yun-Jung surrendered her life to Jesus Christ. “I feel like God
    has held my hand, and He met me today.”
  `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      