import React from 'react';

const donationHeaderPrayForNation = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great news {firstName}! Your "30 day Prayer Guide" is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
      We praise God that you want to stand boldly for Him and pray for our nation. We hope this resource gives you strength as you point people to <em>“the way, and the truth, and the life”</em> (John 14:6, ESV).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        God is using friends like you to reach people with His love in a variety of ways through the Billy Graham Evangelistic Association, including a 24-hour prayer line, online resources, and Billy Graham Rapid Response Team (BG-RRT) chaplains, who comfort and pray with hurting people in the midst of crisis.
      </p>
      <p>This Gospel ministry relies on friends like you who make outreaches like these possible through your prayers and financial support.</p>
      <blockquote className="modalBlockquote">
      When you make a gift today, you can reach people across the globe with the message of God’s love. In a time when so many are overcome with anger, fear, or hopelessness, the Good News of Jesus Christ is exactly what they need to hear. Will you help share it with them?
      </blockquote>
    </div>
  </div>
);

export default donationHeaderPrayForNation;
