import React from 'react';
import { Block, Heading } from '@bgea-js/react-ui';

const customConfirmationVietname = () => (
  <div>
    <Heading>Cần Thơ, Vietnam</Heading>
    <Block>
      <p>Thông tin của bạn đã được gửi thành công. Xin cám ơn!</p>
    </Block>
  </div>
);

export default customConfirmationVietname;