import React from 'react';
import './christmas-card.scss'

const customModalTextWGCC = ({firstName}) => (
  <div
    style={{
      marginBottom: '2rem',
    }}
    >
    <h2
      id='will-christmas-header'
    >
      Thank you for signing up, {firstName}.
    </h2>
    <p>
    I pray your home will be filled with great joy and peace as you celebrate our <em>“Savior, who is Christ the Lord”</em> (Luke 2:11, ESV).
    </p>
    <p>
    In the coming weeks, look for an email from us with more information about my upcoming Christmas special. The program will feature an evangelistic message and festive music, and it’s a great way to tell your loved ones who don’t know Jesus Christ about the Good News of great joy.
    </p>
    <p>
    Thank you for your heart to share the Gospel with people across the globe. May God bless you and your family in the coming weeks.
    </p>
  </div>
);

export default customModalTextWGCC;
