import React from 'react';

const customConfirmationECE = () => (
  <div>
    We thank you for your interest in attending the European Congress on
    Evangelism 2025. We will send more information regarding the application
    process in March 2024.
  </div>
);

export default customConfirmationECE;
