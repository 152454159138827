import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<div parentName="div">{`
  `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQACBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB55hGbN//xAAaEAADAAMBAAAAAAAAAAAAAAAAARECEBIT/9oACAEBAAEFAsVFwmeOqWH/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFZ/8QAFhABAQEAAAAAAAAAAAAAAAAAUQAg/9oACAEBAAY/AmM//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIWEx/9oACAEBAAE/ISS64juGFX7Bw7BbFKrWf//aAAwDAQACAAMAAAAQyD//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAEDAQE/EEtIf//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QxhWf/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARQSExgf/aAAgBAQABPxAtzw78B7UJxcxCJZnolBL2yKVou6yf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Couples praying in Alaska",
            "title": "Couples praying in Alaska",
            "src": "/static/bb26749dcc0ddf406e941ef6d3e465e6/6a068/vietnam_fg_crowd_2023_230301_6930.jpg",
            "srcSet": ["/static/bb26749dcc0ddf406e941ef6d3e465e6/f93b5/vietnam_fg_crowd_2023_230301_6930.jpg 300w", "/static/bb26749dcc0ddf406e941ef6d3e465e6/b4294/vietnam_fg_crowd_2023_230301_6930.jpg 600w", "/static/bb26749dcc0ddf406e941ef6d3e465e6/6a068/vietnam_fg_crowd_2023_230301_6930.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}<figcaption parentName="div">{`Hundreds of people surrendered their lives to the Lord in Ho Chi Minh City during our Festival there last year, one of the largest such events in the country to date.`}</figcaption>{`
  `}</div>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rapid-response-team"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`Festivals`}</span><span parentName="h3" {...{
          "className": "sub-category"
        }}>{` CAN THO, VIETNAM`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`Upcoming Gospel Ministry in Vietnam`}</h4>{`
  `}<p parentName="div">{`
      God has opened the door for me to `}<strong parentName="p">{`preach the Gospel once again in Vietnam at the end of the year.`}</strong>{` Last year, I had the privilege of sharing the Good News in Ho Chi Minh City, which marked the first time a foreign speaker has held a Gospel outreach, outside of a holiday. Now I have the chance to host a Festival in December in Can Tho, a large city in the country’s Mekong Delta region. Buddhism, folk religion, and atheism are all prevalent here, and `}<strong parentName="p">{`very few of Can Tho’s nearly 2 million residents know the Lord.`}</strong>{`
  `}</p>{`
  `}<p parentName="div">{`
      So many pastors, church leaders, and Christians in Can Tho are already working hard to prepare for the Festival. More than 2,400 people have participated in the Christian Life and Witness Course (CLWC), and believers are regularly gathering all over the Mekong Delta for prayer. Many churches in the region are also using our I Am Andrew program to encourage their members to invite friends, neighbors, and family members who don’t know the Lord to the two-day event. So far, local Christians are praying for more than 4,800 loved ones.
  `}</p>{`
  `}<p parentName="div">{`
      One pastor shared the positive impact CLWC has already had. “The training’s content has encouraged congregations belonging to the Khmer ethnic or those in remote regions who had never previously received such training,” he explained. “After completing the course, the believers applying, and practicing courageously went out to bear witness. `}<strong parentName="p">{`Consequently, for just over a month, we had more than 20 people accept God as Lord and Savior.`}</strong>{`”
  `}</p>{`
  `}<p parentName="div">{`
      Ask the Lord to continue strengthening believers in Can Tho—and that He will call many men, women, and children to Himself.
  `}</p>{`
   `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rapid-response-team"
      }}>{`
     `}<span parentName="h3" {...{
          "className": "category"
        }}>{`Billy graham RAPID RESPONSE TEAM`}</span><span parentName="h3" {...{
          "className": "sub-category"
        }}>{`TEXAS`}</span>{`
  `}</h3>{`
    `}<h4 parentName="div">{`Responding with Christ-centered Compassion`}</h4>{`
  `}<p parentName="div">{`
    Two months ago, Hurricane Beryl marked the earliest Category 5 storm on record—and with Hurricane Debby on its heels just weeks later, many communities across our country were left reeling from the devastation. Both storms created tornadoes that destroyed homes and claimed lives too. I’m thankful for chaplains who immediately deployed all across the Southeast and who are continuing to minister now. This urgent ministry would not be possible without friends like you. 
   `}</p><div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
      `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB52qo3MAv/8QAGxABAAICAwAAAAAAAAAAAAAAAQACAxAREiL/2gAIAQEAAQUC5SVNXDrjPQz/xAAXEQEAAwAAAAAAAAAAAAAAAAAAESFB/9oACAEDAQE/AbxD/8QAFhEAAwAAAAAAAAAAAAAAAAAAABEh/9oACAECAQE/AYqM/8QAGBAAAwEBAAAAAAAAAAAAAAAAEBFhAAL/2gAIAQEABj8CUL3cH//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQVGx/9oACAEBAAE/IfTdorNkFxnwWeNvZTWLURif/9oADAMBAAIAAwAAABCIP//EABcRAQADAAAAAAAAAAAAAAAAAAARIVH/2gAIAQMBAT8QsQx//8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIVH/2gAIAQIBAT8QAqmy3Z//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhQTGBYcHR/9oACAEBAAE/EByxEBjD7l1qARFdb8RgCwd4AbIAKzfyV6wkBXMWth1P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rrt rev replace oct pltr 240719",
            "title": "rrt rev replace oct pltr 240719",
            "src": "/static/3b70b907a935fd849f26539a79a6b835/6a068/rrt_rev_replace_oct_pltr_240719.jpg",
            "srcSet": ["/static/3b70b907a935fd849f26539a79a6b835/f93b5/rrt_rev_replace_oct_pltr_240719.jpg 300w", "/static/3b70b907a935fd849f26539a79a6b835/b4294/rrt_rev_replace_oct_pltr_240719.jpg 600w", "/static/3b70b907a935fd849f26539a79a6b835/6a068/rrt_rev_replace_oct_pltr_240719.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
       `}<figcaption parentName="div">{`During each deployment, chaplains point hurting people to the eternal hope of Jesus Christ.`}</figcaption>{`
    `}</div>{`
  `}<p parentName="div"></p>{`
  `}<p parentName="div">{`
    In Texas, a couple of our chaplains met Felipe,* whose home had been hit by one of the hurricanes. The 71-year-old was happy to talk and opened up about his life. It was evident that he was a hard worker who cared for the people around him. But when faith came up, Felipe said he was counting on that work to get him into Heaven. Our chaplains gently explained that the Bible says we can only “`}<em parentName="p">{`enter the holy places by the blood of Jesus`}</em>{`” (Hebrews 10:19, ESV). `}<strong parentName="p">{`Felipe listened thoughtfully while they shared the Gospel—and then he prayed to 
  receive Jesus Christ in repentance and faith.`}</strong>{` “I know for certain that I’m going to Heaven,” he said joyfully as their time together ended. “Jesus died on the cross for my sin, and I’ve been forgiven.”
  `}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rapid-response-team"
      }}>{`
     `}<span parentName="h3" {...{
          "className": "category"
        }}>{`CELEBRATIONS`}</span><span parentName="h3" {...{
          "className": "sub-category"
        }}>{`NORTHERN COLORADO`}</span>{`
  `}</h3>{`
    `}<h4 parentName="div">{`‘Simply set forth the truth plainly …’`}</h4>{`
    `}<p parentName="div">{`
      In just a few weeks, my son Will is going to preach the Gospel in `}<strong parentName="p">{` Northern Colorado,`}</strong>{` and hundreds of believers have attended CLWC to prepare for the Celebration. 
    `}</p>{`
    `}<p parentName="div">{`
      Many of the participants have told us how much the training helped equip them. “I have long worried that if I don’t eloquently present the Gospel in just the right way, that I would be an impediment and barrier,” said one CLWC attendee.`}<strong parentName="p">{` “But your words tonight, to simply set forth the truth plainly, brings the power of the Holy Spirit to the situation.”`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
      Will you pray that many people will embrace the Good News that “`}<em parentName="p">{`the blood of Jesus his Son cleanses us from all sin`}</em>{`” (1 John 1:7, ESV)? 
    `}</p>{`
     `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rapid-response-team"
      }}>{`
     `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM TRAINING CENTER`}</span><span parentName="h3" {...{
          "className": "sub-category"
        }}>{`ASHEVILLE, NC`}</span>{`
    `}</h3>{`
    `}<h4 parentName="div">{`Pastor Appreciation at the COVE`}</h4>{`
    `}<p parentName="div">{`
      October marks Pastor Appreciation Month, and God has given us the opportunity to regularly minister to these men—and their wives—through Pastor Renewal Retreats at the Billy Graham Training Center at The Cove. Many recent studies report growing rates of loneliness and burnout among church leaders. We’ve hosted two of these retreats over the past couple of months, and another is underway as I write. 
    `}</p>{`
    `}<p parentName="div">{`
      Pastor Rick* told us how much the time away and Biblical teaching meant to him. “I came to this property discouraged and tired.`}<strong parentName="p">{` I am leaving renewed and ready in the Lord to advance the cause of God.`}</strong>{` At several moments throughout the weekend, I looked at my wife and said, ‘I can’t believe that someone we don’t know and haven’t met made this possible.’”
    `}</p>{`
     `}<h4 parentName="div">{`A Turning Point for Our Nation`}</h4>{`
     `}<p parentName="div">{`
       `}<strong parentName="p">{`We are weeks away from one of the most consequential elections most of us have ever seen.`}</strong>{` Our country is deeply divided. Godlessness, progressivism, and secularism now define much of American culture—and we are in trouble. As Christians, we must vote for candidates who best stand for Biblical principles. `}<strong parentName="p">{`Most importantly, we need to lift our nation up to the Lord in prayer.`}</strong>{` God’s Word promises that “`}<em parentName="p">{`the prayer of a righteous person has great power as it is working`}</em>{`” (James 5:16, ESV).
     `}</p>{`
     `}<p parentName="div">{`
       `}<strong parentName="p">{`You are critical to this ministry,`}</strong>{` and I’m grateful that you are “`}<em parentName="p">{`side by side with me in the gospel`}</em>{`” (Philippians 4:3, ESV).
     `}</p>{`
      `}<p parentName="div">{`May God richly bless you,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Franklin Graham`}<br parentName="p"></br>{`President & CEO`}</p>{`
  `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>{`
  `}<div parentName="div" {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "options-subtitle mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for  `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the Will Graham Celebrations in Colorado and Canada, as well as the Franklin Graham Festivals in Italy and Vietnam this year.
    `}</p>{`
     `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM `}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
    `}</p>{`
    `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      