import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "northern-colorado"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`CELEBRATIONS  `}<strong parentName="span">{`|`}</strong>{`  NORTHERN COLORADO`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`PROCLAIMING THE CROSS IN COLORADO`}</h4>{`
  `}<p parentName="div">{`I recently finished preaching the Gospel at the `}<strong parentName="p">{`Northern Colorado Look Up Celebration`}</strong>{`. This was a homecoming of sorts for me. While my family is often associated with North Carolina, I was actually born in Longmont, Colorado. It had always been a dream of mine to return to the area and proclaim the hope of the Savior, and I’m thankful that God opened that door this year.`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/oct/noco-counsel.jpg",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`Northern Colorado is a beautiful place on the eastern edge of the Rocky Mountains. Unfortunately, `}<strong parentName="p">{`there is a significant level of spiritual darkness`}</strong>{`, bringing to mind Paul’s words in Romans 1:25: `}<em parentName="p">{`“They exchanged the truth about God for a lie and worshiped and served the creature rather than the Creator, who is blessed forever!”`}</em>{` (ESV). Many are so focused on nature-centered spirituality that their eyes are closed to the truth of the Gospel. Into that darkness, I proclaimed the Light of the Savior! I shared that the only true hope we have is in Jesus Christ and what He has already accomplished through His death, burial, and resurrection. `}<strong parentName="p">{`I encouraged the roughly 5,500 in attendance to repent of their sins and surrender their lives into His holy hands.`}</strong></p>{`
  `}<img parentName="div" {...{
        "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/oct/noco-crowd.jpg",
        "alt": ""
      }}></img>{`
  `}<figcaption parentName="div">{`More than 5,500 people heard the Gospel proclaimed over the course of the two-night Northern Colorado Look Up Celebration with Will Graham.`}</figcaption>{`
  `}<p parentName="div">{`One of the first people to respond to the invitation was a young man named Derek.* “For the last couple years, I was in a really dark place. I was doing things I shouldn’t be doing,” he shared, acknowledging the toll that his bad decisions had taken on him. As I shared the story of the Prodigal Son from Luke 15, Derek heard and understood the Gospel. `}<strong parentName="p">{`“The feeling I got when I surrendered myself to Jesus, surrendered myself to God, it was much better than anything else had ever made me feel. …`}</strong>{` He’s my Savior. There’s really no other way to put it,” Derek said, his face beaming with joy. As he left that night, Derek was excited to share the new hope he had found with his friends.`}</p>{`
  `}<p parentName="div">{`Will you join me in praying for Derek and the more than 330 others who responded at the Northern Colorado Look Up Celebration? Pray that the seeds that have been planted will fall on good ground (Matthew 13:8) and that each will grow in their newfound faith.`}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "grand-prairie"
      }}>{`
  `}<span parentName="h3" {...{
          "className": "category"
        }}>{`CELEBRATIONS  `}<strong parentName="span">{`|`}</strong>{`  Alberta, Canada`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`The Gospel in Grande Prairie`}</h4>{`
  `}<p parentName="div">{`In a few weeks I will share the Gospel at the `}<strong parentName="p">{`Grande Prairie Look Up Celebration in Alberta, Canada`}</strong>{`. I was recently told by a local pastor that there’s a deep hunger for the Gospel in this community. “We really feel that this is God’s timing. We’re praying for transformation and we’re really praying for the Spirit to change the landscape of this city for generations.”`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/oct/noco-will-graham.jpg",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<p parentName="div"><strong parentName="p">{`Please join me in praying for the Grande Prairie Look Up Celebration.`}</strong>{` Pray that believers will be bold in inviting and bringing their friends, that God will open and soften hearts, and—ultimately—that many will find real peace through a personal relationship with Jesus Christ.`}</p>{`
  `}<h4 parentName="div">{`A Time for Thanksgiving`}</h4>{`
  `}<p parentName="div">{`Psalm 100:4 says, `}<em parentName="p">{`“Enter into His gates with thanksgiving, and into His courts with praise. Be thankful to Him, and bless His name”`}</em>{` (NKJV). `}<strong parentName="p">{`My friends, we have so much to be thankful for: We have been `}<em parentName="strong">{`“called … out of darkness into His marvelous light”`}</em></strong>{` (1 Peter 2:9, NKJV). Please join me in praying that hurting people all over our nation will respond to the Gospel and become children of the King.`}</p>{`
  `}<p parentName="div">{`May God bless you and your family in the coming season,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2022/02/Will-Graham-Signature.png",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Will Graham`}</p>{`
  `}<p parentName="div">{`Executive Vice President`}</p>{`
  `}<p parentName="div">{`*Names changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<div parentName="div" {...{
        "className": "give-background mb-6"
      }}>{`
  `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
  `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
  `}</h4>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` `}<strong parentName="p">{`|`}</strong>{` The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` 
    it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` `}<strong parentName="p">{`|`}</strong>{` Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` `}<strong parentName="p">{`|`}</strong>{` So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the upcoming Will Graham Celebration in Alberta, Canada, and the Franklin Graham Festival in Can Tho, Vietnam.
  `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      