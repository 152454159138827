import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentAugust from "../../../../src/content/prayer-letter-august/main-content-august.md";
import "../../../../src/content/prayer-letter-august/prayer-letter-august.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentAugust,
  React
};