import React from 'react';

const customModalTextCG = (
  <div>
    <p>
      We praise the Lord for your heart to draw near to Him—and to help others
      do the same. It’s our prayer that this resource will serve as an
      encouraging reminder that “
      <i>
        with the Lord there is steadfast love, and with him is plentiful
        redemption
      </i>
      ” (Psalm 130:7, ESV).
    </p>
    <p>
      <b>Before you go, there are a few things we’d like you to know…</b>
    </p>
    <p>
      The Billy Graham Evangelistic Association is able to offer materials like
      this because of friends like you. The Lord is using the gifts and prayers
      of believers like you to reach people across the globe with His love—and
      there are so many more who need to hear the Good News.
    </p>
    <p>Will you give them the opportunity to hear it?</p>
    <blockquote
      style={{
        borderLeft: '1px solid #6e6e6e',
        borderRight: '1px solid #6e6e6e',
        textAlign: 'center',
        marginBottom: 0,
        color: '#6e6e6e',
        padding: '9px 19px',
      }}
    >
      Your gift of $50 today will help share the Gospel with people around the
      world who are far from God and equip them with powerful Biblical resources
      to become faithful followers of Christ.
    </blockquote>
  </div>
);

export default customModalTextCG;
