import "../../../../src/content/buenos-aires/buenos-aires.scss";
import { GetInvolved } from '@bgea-js/form-templates';
import iiaCopyAr from "../../../../src/content/buenos-aires/iiaCopy";
import baConfirmation from "../../../../src/content/buenos-aires/baConfirmation";
import * as React from 'react';
export default {
  GetInvolved,
  iiaCopyAr,
  baConfirmation,
  React
};