import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><section {...{
        "id": "schedule"
      }}>{`
  `}<div parentName="section" {...{
          "className": "main-container"
        }}>{`
  `}<div parentName="div" {...{
            "className": "margins",
            "id": "schedule-header"
          }}>{`
    `}<div parentName="div">{`
      `}<h1 parentName="div">{`Congress `}<strong parentName="h1">{`Schedule`}</strong></h1>{`
    `}</div>{`
    `}<div parentName="div">{`
      `}<p parentName="div" {...{
                "className": "mt-5"
              }}>{`In addition to these plenary sessions and Bible studies, there will be opportunities to network over coffee with other participants.`}</p>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
            "className": "schedule-wrapper"
          }}>{`
    `}<div parentName="div" {...{
              "className": "schedule-day"
            }}>{`
      `}<div parentName="div" {...{
                "className": "line mobile",
                "id": "line-1"
              }}></div>{`
      `}<div parentName="div" {...{
                "className": "margins day-info"
              }}>{`
        `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-1"
                }}></div>{`
        `}<img parentName="div" {...{
                  "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/may-27.svg",
                  "alt": "May 27th",
                  "className": "date"
                }}></img>{`
        `}<p parentName="div" {...{
                  "className": "theme"
                }}>{`The Call to Proclamation Evangelism`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
                "className": "margins session-info"
              }}>{`
        `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-1-B"
                }}></div>{`
        `}<div parentName="div" {...{
                  "className": "sessions-container"
                }}>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Command of God Is to Preach the Gospel”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. FRANKLIN GRAHAM`}</p>{`
        `}</div>{`
        `}</div>{`
      `}</div>{`
    `}</div>{`
    `}<div parentName="div" {...{
              "className": "schedule-day"
            }}>{`
      `}<div parentName="div" {...{
                "className": "line mobile",
                "id": "line-2"
              }}></div>{`
      `}<div parentName="div" {...{
                "className": "margins day-info"
              }}>{`
      `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-2"
                }}></div>{`
      `}<img parentName="div" {...{
                  "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/may-28.svg",
                  "alt": "May 28th",
                  "className": "date"
                }}></img>{`
      `}<p parentName="div" {...{
                  "className": "theme"
                }}>{`The Essentials of Proclamation Evangelism`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
                "className": "margins session-info"
              }}>{`
        `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-2-B"
                }}></div>{`
        `}<div parentName="div" {...{
                  "className": "sessions-container"
                }}>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
            `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`BIBLE STUDY`}</p>{`
            `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Theology of Evangelistic Proclamation”`}</p>{`
            `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`ALISTAIR BEGG`}</p>{`
          `}</div>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
            `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
            `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Cross & Blood of Christ Are Central to Preaching the Gospel”`}</p>{`
            `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`CHARLES PRICE`}</p>{`
          `}</div>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
            `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
            `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Bible is Foundational for Preaching the Gospel”`}</p>{`
            `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. ULRICH PARZANY`}</p>{`
          `}</div>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
            `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
            `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“Discipleship is Crucial to the Preaching of the Gospel”`}</p>{`
            `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. DANNY PASQUALE`}</p>{`
          `}</div>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“We Must Grasp the Utter Lostness of Mankind”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. GUNNAR MÄGI`}</p>{`
          `}</div>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
            `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
            `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“Holy Living is Mandatory for Preaching the Gospel”`}</p>{`
            `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. JOAKIM LUNDQVIST`}</p>{`
          `}</div>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
            `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
            `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“We Must Recognise Preaching the Gospel Has a High Cost”`}</p>{`
            `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`BISHOP JAMES ALEXANDER`}</p>{`
          `}</div>{`
          `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
            `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
            `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Holy Spirit Empowers Preaching the Gospel”`}</p>{`
            `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`SKIP HEITZIG`}</p>{`
          `}</div>{`
        `}</div>{`
      `}</div>{`
    `}</div>{`
    `}<div parentName="div" {...{
              "className": "schedule-day"
            }}>{`
      `}<div parentName="div" {...{
                "className": "line mobile",
                "id": "line-3"
              }}></div>{`
      `}<div parentName="div" {...{
                "className": "margins day-info"
              }}>{`
      `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-3"
                }}></div>{`
      `}<img parentName="div" {...{
                  "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/may-29.svg",
                  "alt": "May 29th",
                  "className": "date"
                }}></img>{`
      `}<p parentName="div" {...{
                  "className": "theme"
                }}>{`The Practice of Proclamation Evangelism`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
                "className": "margins session-info"
              }}>{`
        `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-3-B"
                }}></div>{`
        `}<div parentName="div" {...{
                  "className": "sessions-container"
                }}>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`BIBLE STUDY`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Scope of Evangelistic Proclamation”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`MICHAEL REEVES`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Gift and Calling of the Evangelist”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. GREG LAURIE`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“We Must Prepare Well for Preaching the Gospel”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`HÉLDER FAVARIN`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“We Must Extend an Invitation in Preaching the Gospel”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. WILL GRAHAM`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“Prayer is Essential in Preaching the Gospel”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. MARC DEROEUX`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“We Must Unite with Others in Preaching the Gospel”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`MARIUSZ MUSZCYŃSKI`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“Jesus the Unique Son of God”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`ALBERT MOHLER`}</p>{`
        `}</div>{`
        `}</div>{`
      `}</div>{`
    `}</div>{`
    `}<div parentName="div" {...{
              "className": "schedule-day"
            }}>{`
      `}<div parentName="div" {...{
                "className": "line mobile",
                "id": "line-4"
              }}></div>{`
      `}<div parentName="div" {...{
                "className": "margins day-info"
              }}>{`
      `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-4"
                }}></div>{`
      `}<img parentName="div" {...{
                  "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/june/euro-congress/may-30.svg",
                  "alt": "May 30th",
                  "className": "date"
                }}></img>{`
      `}<p parentName="div" {...{
                  "className": "theme"
                }}>{`The Commitment to Proclamation Evangelism`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
                "className": "margins session-info"
              }}>{`
        `}<div parentName="div" {...{
                  "className": "line",
                  "id": "line-4-B"
                }}></div>{`
        `}<div parentName="div" {...{
                  "className": "sessions-container"
                }}>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`BIBLE STUDY`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Authority in Evangelistic Proclamation”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`ARCHBISHOP KANISHKA RAFFEL`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`PLENARY SESSION`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“I Am Not Ashamed of the Gospel”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`SKIP HEITZIG`}</p>{`
        `}</div>{`
        `}<div parentName="div" {...{
                    "className": "session"
                  }}>{`
          `}<p parentName="div" {...{
                      "className": "session-type"
                    }}>{`CLOSING`}</p>{`
          `}<p parentName="div" {...{
                      "className": "quote"
                    }}>{`“The Time is Now”`}</p>{`
          `}<p parentName="div" {...{
                      "className": "speaker is-hidden"
                    }}>{`REV. FRANKLIN GRAHAM`}</p>{`
        `}</div>{`
        `}</div>{`
      `}</div>{`
    `}</div>{`
  `}</div>{`
  `}</div>
      </section>
      <footer {...{
        "id": "eu-footer"
      }}>{`
  `}<div parentName="footer" {...{
          "className": "main-container"
        }}>{`
  `}<div parentName="div" {...{
            "className": "margins"
          }}>{`
    `}<div parentName="div">{`
    `}<a parentName="div" {...{
                "href": "https://billygraham.org",
                "target": "_blank",
                "rel": "noreferrer"
              }}><img parentName="a" {...{
                  "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/may/BGEA%20logo_A%20ministry%20of_WHITE.svg",
                  "alt": "Billy Graham Evangelistic Association",
                  "width": 141,
                  "height": 60
                }}></img></a>{`
    `}</div>{`
    `}<div parentName="div" {...{
              "className": "links"
            }}>{`
      `}<div parentName="div">{`
        `}<p parentName="div"><a parentName="p" {...{
                    "href": "https://billygraham.org/privacy-policy/",
                    "target": "_blank",
                    "rel": "noreferrer"
                  }}><strong parentName="a" {...{
                      "className": "privacy"
                    }}>{`PRIVACY POLICY`}</strong></a><span parentName="p">{`|`}</span></p>{`
        `}<p parentName="div"><a parentName="p" {...{
                    "href": "https://billygraham.org/about/contact/",
                    "target": "_blank",
                    "rel": "noreferrer"
                  }}><strong parentName="a" {...{
                      "className": "privacy"
                    }}>{`CONTACT`}</strong></a><span parentName="p">{`|`}</span></p>{`
        `}<p parentName="div"><a parentName="p" {...{
                    "href": "https://billygraham.org/about/what-we-believe/",
                    "target": "_blank",
                    "rel": "noreferrer"
                  }}><strong parentName="a" {...{
                      "className": "privacy"
                    }}>{`ABOUT`}</strong></a></p>{`
      `}</div>{`
      `}<p parentName="div" {...{
                "className": "mt-3"
              }}>{`© 2024  | BGEA is a registered 501(c)(3) non-profit organization.`}</p>{`
    `}</div>{`
  `}</div>{`
  `}</div>
      </footer></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      