import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div id="clwc-section" className="is-hidden">
    <div className="content-wrapper">
        <div className="margins">
            <div className="clwc-content-container">
                <div className="content-container">
                    <h1 className="bottom-title">Christian Life & Witness</h1>
                    <h1 className="top-title">Course</h1>
                </div>
                <div className="content-container">
                    <p>By attending this free course taught in three one-hour sessions, participants will learn:</p>
                    <ul>
                        <li>Biblical principles for living a life that pleases God and points other people to Him.</li>
                        <li>Practical tips and advice for sharing the Gospel.</li>
                        <li>How to encourage and support new Christians and help them grow in their faith through studying God’s Word.</li>
                        <li>This course is available to everyone and all are welcome.</li>
                    </ul>
                    <p>Classes are held free of charge at various locations.</p>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/d/embed?mid=1bmqCyvk1p14-i0kURL2jOsHSS-Ij1II&ehbc=2E312F" width="640" height="480"></iframe>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      