import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationLB = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
}) => (
  <div>
    <h2>Thank You for Your Gift</h2>
    <p>
      Your donation helps make it possible for more people to hear the Gospel at
      the upcoming God Loves You Tour. We are grateful for friends like you who
      have a heart for sharing this Good News around the globe.
    </p>
    <p>
      Please keep the London outreach in prayer as people from across England
      and Wales experience God's love.
    </p>
    <p>
      Watch the August 26 event live at{' '}
      <a href="https://billygraham.org/live">BillyGraham.org/Live</a>.
    </p>
    <Block>
      The confirmation number for your{' '}
      {showRecurrenceText && <strong>{recurrenceText}</strong>} donation is{' '}
      <strong>{transactionId}</strong> for the amount of $
      {total.toFixed(2) ?? 'NEED AMOUNT'}.
    </Block>
    <Block>
      If you have any questions, call us at 1-877-247-2426. Please have your
      confirmation number ready and indicate that you are contacting us about a
      donation made through the Billy Graham Evangelistic Association website.
    </Block>
    <Block>
      To keep you updated on the ministry, we would like to send you our prayer
      letter, <i>From the Desk of Franklin Graham</i>, featuring biblical truths
      about current events, news of his travels and updates on the work of BGEA
      with thousands of people around the world. You can opt-out from receiving
      these emails anytime.
    </Block>
  </div>
);

export default customConfirmationLB;
