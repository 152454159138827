import React from 'react';

const iiaCopyAr = () => (
  <div>
    <b>ANDRÉS EN ACCIÓN</b>
    <p>
      &quot;Andrés en Acción&quot; es un compromiso de pasar tiempo con Jesús
      diariamente, y hacer prioridad en tu vida el compartir las Buenas Nuevas:
    </p>
    <p>
      <b>ORAR</b> - Escribí los nombres de personas que conocés y que necesitan a Jesús, y orá por ellos cada día.
    </p>
    <p>
      <b>COMPARTIR</b> - Pasá tiempo con ellos y encontrá oportunidades de testificar acerca de Jesús.
    </p>
    <p>
      <b>TRAER</b> - Traé a las personas en tu lista al evento. Si responden al llamado de Cristo o muestran un interés en el Evangelio, van a necesitar que estés presente para animarlos regularmente.
    </p>
    <p>
      <b>DAR SEGUIMIENTO</b> - Invitalos a que te acompañen a la iglesia. Dios puede usarte a vos y a tu iglesia para ayudarlos a tener una relación más profunda con Jesús.
    </p>
  </div>
);

export default iiaCopyAr;