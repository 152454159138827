import React from 'react';

const donationHeaderSignsOfChristmas = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great news {firstName}! Your Christmas resource is on its way to your inbox.
    </h2>
    <div>
      <p>
      We thank God for your heart to share the Gospel this Christmas season. 
      It’s our prayer that “Signs of Christmas” will help you tell others that 
      Jesus Christ was born to “<em>save His people from their sins</em>” (Matthew 1:21, NKJV).
      </p>
      <p>
          Before you go, there are a few things we’d like you to know…
      </p>
      <p>
      God is using friends like you to reach people with His love in a variety of ways through 
      the Billy Graham Evangelistic Association, including a 24-hour prayer line, online Gospel 
      resources, and Billy Graham Rapid Response Team chaplains, who comfort and pray with hurting 
      people in the midst of crisis. Will you help give many more the opportunity to hear the message 
      of God’s great love?
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid',
          borderRight: '1px solid',
          textAlign: 'center',
          marginBottom: ' 0px',
          color: ' rgb(110, 110, 110)',
          padding: '9px 30px',
        }}
      >
      When you make a gift today, more people across the globe will hear the Good News of Jesus Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderSignsOfChristmas;
