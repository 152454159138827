import React from 'react';

const decisionMagazineDigitalCopy = ({ firstName }) => (
  <div>
    <h2 className='confirmation-dc'>
      Good News {firstName}! Your "Decision Magazine Election Issue" is on its way to
      your inbox.
    </h2>
  </div>
);

export default decisionMagazineDigitalCopy;
