import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentJuly from "../../../../src/content/prayer-letter-july-2024/main-content-july.md";
import LearnMoreJuly from "../../../../src/content/prayer-letter-july-2024/learn-more-details-july.md";
import "../../../../src/content/prayer-letter-july-2024/prayer-letter-july.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentJuly,
  LearnMoreJuly,
  React
};