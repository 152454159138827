import React from 'react';
import hpCustomModal from "./customModalText"

const customModalHeaderRP = ({ firstName }) => (
  <div>
  <h2>
    Good news{' '} {firstName}! Your link to watch this inspiring Billy Graham film, <em>The Ride</em>, is on its way to your inbox.
  </h2>
  {/* <br></br> */}
  {hpCustomModal}
</div>
);

export default customModalHeaderRP;
