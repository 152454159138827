exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-monthlyprayerletter-js": () => import("./../../../src/templates/monthlyprayerletter.js" /* webpackChunkName: "component---src-templates-monthlyprayerletter-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-with-header-and-image-js": () => import("./../../../src/templates/pageWithHeaderAndImage.js" /* webpackChunkName: "component---src-templates-page-with-header-and-image-js" */),
  "component---src-templates-page-with-header-js": () => import("./../../../src/templates/pageWithHeader.js" /* webpackChunkName: "component---src-templates-page-with-header-js" */),
  "component---src-templates-pageordinary-js": () => import("./../../../src/templates/pageordinary.js" /* webpackChunkName: "component---src-templates-pageordinary-js" */)
}

