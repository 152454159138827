import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentMar from "../../../../src/content/prayer-letter-march-2024/main-content-mar.mdx";
import LearnMore1 from "../../../../src/content/prayer-letter-march-2024/learn-more1.mdx";
import LearnMore2 from "../../../../src/content/prayer-letter-march-2024/learn-more2.mdx";
import LearnMore3 from "../../../../src/content/prayer-letter-march-2024/learn-more3.mdx";
import "../../../../src/content/prayer-letter-march-2024/prayer-letter-march.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentMar,
  LearnMore1,
  LearnMore2,
  LearnMore3,
  React
};