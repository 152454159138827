import React from 'react';

const donationHeaderWhatIsRapture = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your "Biblical Answers on the End Times" is on its
      way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise the Lord for your heart to know what His Word says, and it’s
        our prayer that this resource will help you “
        <i>as you wait for and earnestly desire the coming of the day of God</i>
        ” (2 Peter 3:11, HCSB).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer materials
        like this because of friends like you. The Lord is using the gifts and
        prayers of believers like you to reach people across the globe with His
        love—and there are so many more who need to hear the Good News.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift of $50 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderWhatIsRapture;
