import React from 'react';
import '../../styles/global.module.scss';

const customModalTextLFO = (
  <div>
    <p>
    We praise God for your heart for His truth, and we hope this powerful movie will encourage you to {' '}
    “<em>run with endurance the race that is set before us, looking to Jesus, the founder and perfecter of our faith</em>”
      {' '}(Hebrews 12:1–2, ESV).
    </p>
    <br></br>
    <p>
      <b>Before you go, there are a few things we’d like you to know{' '}…</b>
    </p>
    {/* <br></br> */}
    <p>
      The Billy Graham Evangelistic Association is able to offer spiritual
      resources because of friends like you. The Lord is using the gifts and
      prayers of people like you to reach people across the globe with His
      love—and there are so many more who need to hear the Good News.
    </p>
    {/* <br></br> */}
    <p>Will you give them the opportunity to hear it?</p>
    {/* <br></br> */}
    <blockquote className="modalBlockquote">
      Your gift will help share the Gospel with people around the world who are
      far from God and equip them with powerful Biblical resources to become
      faithful followers of Christ.
    </blockquote>
  </div>
);

export default customModalTextLFO;
