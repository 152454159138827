import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="callout" id="panel-cdn" style={{
      "display": "block"
    }}>
            <a className="button order-secondary" href="https://bookstore.billygraham.org/an-extraordinary-journey-dvd.html" target="_blank">Buy On DVD Now</a>
            <p id="p-order">Or from one of these other retailers</p>
            <ul className="dvd-group">
                <li><a className="button secondary" href="https://www.amazon.com/Billy-Graham-Extraordinary-Journey-Self/dp/B07FSWK6HR/ref=sr_1_1?ie=UTF8&qid=1537548413&sr=8-1&keywords=billy+graham+an+extraordinary+journey+dvd" target="_blank">amazon</a></li>
                <li><a className="button secondary" href="https://www.walmart.com/ip/Billy-Graham-An-Extraordinary-Journey-DVD/481774531" target="_blank">walmart</a></li>
                 <li><a className="button secondary" href="https://www.bestbuy.com/site/billy-graham-an-extraordinary-journey-dvd-2018/34263861.p?skuId=34263861" target="_blank">best buy</a></li>
                 <li><a className="button secondary" href="https://www.target.com/p/billy-graham-extraordinary-journey-dvd/-/A-53974198" target="_blank">target</a></li>
                <li><a className="button secondary" href="https://www.barnesandnoble.com/w/dvd-billy-graham-an-extraordinary-journey/32851939?ean=0829567129722" target="_blank">barnes & noble</a></li>
            </ul>
            <hr className="btn-divider-2" />
            <a className="button order-secondary" href="https://billygrahamanextraordinaryjourney.vhx.tv/products/billy-graham-an-extraordinary-journey-international-extras-edition" target="_blank">Order for Digital Release
                </a>
            <p>Or from one of these other retailers</p>
              <ul className="dvd-group">
                    <li><a className="button secondary" href="https://itunes.apple.com/us/movie/billy-graham-an-extraordinary-journey/id1432286556" target="_blank">itunes</a></li>
                    <li><a className="button secondary" href="https://www.amazon.com/Billy-Graham-Extraordinary-Journey/dp/B07HKWMR8N" target="_blank">amazon</a></li>
                    <li><a className="button secondary" href="https://vimeo.com/ondemand/bganextraordinaryjourney" target="_blank">vimeo</a></li>
                    <li><a href="https://play.google.com/store/movies/details/Billy_Graham_An_Extraordinary_Journey?id=yCDM1w8qLnM.P&hl=en" target="_blank" className="button secondary">google play</a></li>
                     <li><a href="https://www.vudu.com/content/movies/details/Billy-Graham-An-Extraordinary-Journey/996877" target="_blank" className="button secondary">vudu</a></li>
                      <li><a className="button secondary" href="https://www.christiancinema.com/rent?item_id=CC00008564&title=Billy-Graham%3A-An-Extraordinary-Journey" target="_blank">Christian Cinema</a></li>
                </ul>
       
            <h2>Special Features</h2>
            <ul className="features">
                <li>Billy Graham's Funeral</li>
                <li>Sermon "God Loves You"</li>
                <li>Documentary "A Passion for God"</li>
                <li>Final Interview "Reflections from a Full Life"</li>
            </ul>
        </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      