import React from 'react';

const customConfirmationEsSHIC = ({ firstName }) => (
  <div>
    <h2>{firstName},</h2>
    <p>
      Agradecemos a Dios por tu interés en prepararte para compartir la
      esperanza de Dios, que sólo se encuentra en Jesucristo, con aquellos que
      atraviesan una crisis personal y con aquellos que pueden sentirse
      perdidos, ansiosos, heridos y temerosos en su vida cotidiana.
    </p>
    <p>
      <strong>
        Te recomendamos descargar el{' '}
        <a
          href="https://s3.theark.cloud/static-billygraham-org/sites/rrt.billygraham.org/uploads/prod/2023/11/SHIC-Espa%C3%B1ol-Manual_PerPage-Printable.pdf"
          target="_blank"
          rel="noreferrer"
        >
          manual{' '}
          <span class="screen-reader-text">
            se abre en una nueva pestaña o ventana{' '}
          </span>
        </a>
        antes de comenzar.
      </strong>
    </p>
    <p>
      <strong>
        Este manual es para uso personal solamente. El uso comercial o con
        grupos no está autorizado.
      </strong>
    </p>
    <p>
      Para comenzar con la capacitación, haz clic en el siguiente botón:
      <br />
      <a href="/shicod-espanol-bienvenida" class="button">
        Video: Bienvenida
      </a>
    </p>
    <p>
      Si tienes alguna pregunta, envíanos un correo electrónico a{' '}
      <a href="mailto:hispanic@bgea.org?subject=SHIC On-Demand - Español">
        hispanic@bgea.org
      </a>
      . Escribe <em>SHIC On-Demand – Español</em> en la línea de asunto.
    </p>
  </div>
);

export default customConfirmationEsSHIC;
