import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationFGT = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
}) => (
  <div>
    <p className="conf-title">Thank you for your gift to help proclaim the Good News of Jesus Christ around the world. </p>
    <Block>
    We are grateful for your prayers and for your heart to share the Gospel with people who are searching for hope. 
    </Block>
    <Block>
      The confirmation number for your{' '}
      {showRecurrenceText && <strong>{recurrenceText}</strong>} donation is{' '}
      <strong>{transactionId}</strong> for the amount of $
      {total.toFixed(2) ?? 'NEED AMOUNT'}.
    </Block>
    <Block>
      If you have any questions, call us at 1-877-247-2426. Please have your
      confirmation number ready and indicate that you are contacting us about a
      donation made through the Billy Graham Evangelistic Association website.
    </Block>
    <Block>
      To keep you updated on the ministry, we would like to send you our prayer
      letter, <i>From the Desk of Franklin Graham</i>, featuring biblical truths
      about current events, news of his travels and updates on the work of BGEA
      with thousands of people around the world. You can opt-out from receiving
      these emails anytime.
    </Block>
  </div>
);

export default customConfirmationFGT;