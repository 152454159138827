import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentCrusades from "../../../../src/content/crusades-update/main-content-crusades.md";
import "../../../../src/content/crusades-update/crusades-update.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentCrusades,
  React
};