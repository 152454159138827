const DailyDevotionalCustomMarkup = {
  markup: `<h3>Get Billy Graham's Daily Devotion in Your Inbox</h3>
  <p style="text-align:center;"><em>"And now, O Lord, for what do I wait? My hope is in you."</em> —Psalm 39:7, ESV </p>
  <p>Life circumstances have a way of distracting us and taking our focus off of God. Whether you're juggling a hectic schedule or stuck in a monotonous routine, let Billy Graham's daily devotional refresh your faith and inspire you for the tasks ahead.</p>
  <p>Through Hope for Each Day, you'll find words of encouragement and Biblical wisdom to help you press on and remind you that no matter what your day brings, there's always hope.
  Sign up now to get Hope for Each Day delivered straight to your inbox.</p>
  `,
};

export default DailyDevotionalCustomMarkup;
