import React from 'react';

const donationHeaderWorldviewDigital = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We appreciate your heart for evangelism, and we hope this resource helps
        you effectively share the Good News that
        <em> “the gift of God is eternal life in Christ Jesus our Lord” </em>
        - (Romans 6:23, ESV)
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        God is using friends like you to reach people with His love in a variety
        of ways through the Billy Graham Evangelistic Association, including a
        24-hour prayer line, online Gospel resources, and Billy Graham Rapid
        Response Team (BG-RRT) chaplains, who comfort and pray with hurting
        people in the midst of crisis.
      </p>
      <p>
        This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift today will help share the hope of the Gospel with people
        around the world who are far from God.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderWorldviewDigital;
