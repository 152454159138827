import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="content-wrapper featured" id="pre-events">
    <div className="margins">
        <div className="content-container english">
            <h1 className="bottom-title"><span>OTHER UPCOMING</span></h1>
            <h1 className="top-title">EVENTS</h1>
        </div>
        <div className="content-container" id="pre-events-list">
            <div className="margins">
                <div className="event column">
                    <p className="event-entry-day">SAT</p>
                    <div className="pre-event-content-container">
                        <p className="date">December 7, 2024</p>
                        <h2>Pastor’s Information Seminar</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Estonian Methodist Church</p>
                                <p>Narva mnt 51</p>
                                <p>10120 Tallinn, Estonia</p>
                            </div>
                            <a id="details1" className="modal-btn" href="#modal1">&#8250;</a>
                        </div>
                        <section id="modal1" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details1" className="modal-close">
                                    <span className="closeModal">close</span>
                                </a>
                                <h1>Pastor’s Information Seminar</h1>
                                <p className="time">December 7, 10:00 a.m.</p>
                                <div className="location-block">
                                    <p className="location">Estonian Methodist Church</p>
                                    <p className="location">Narva mnt 51, 10120 Tallinn, Estonia</p>
                                </div>
                                <p className="details">Estonia pastors are invited to gather on Saturday, Dec. 7, to hear more about the upcoming Time of Hope with Will Graham.</p>
                                <p className="details">Discover how your congregation can get involved in reaching the people of Estonia for Jesus Christ. Topics to be covered include:</p>
                                <ul>
                                    <li>How your church can become a prayer partner</li>
                                    <li>How to become a church committed to the I Am Andrew vision</li>
                                    <li>How hosting the Christian Life and Witness Course (CLWC) can greatly benefit your congregation</li>
                                    <li>Encouraging those who complete the CLWC to become counselors and volunteers at the Time of Hope</li>
                                    <li>How to sign up for group registrations</li>
                                    <li>The importance of following up with those who make a decision for Christ at the Time of Hope</li>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="event column">
                    <p className="event-entry-day">SAT</p>
                    <div className="pre-event-content-container">
                        <p className="date">January 25, 2025</p>
                        <h2>Time of Hope Launch</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Oleviste Church</p>
                                <p>Lai tn 50</p>
                                <p>10133 Tallinn, Estonia</p>
                            </div>
                            <a id="details2" className="modal-btn" href="#modal2">&#8250;</a>
                        </div>
                        <section id="modal2" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details2" className="modal-close"><span className="closeModal">close</span></a>
                                <h1>Time of Hope Launch</h1>
                                <p className="time">January 25, 10:00 a.m.</p>
                                <div className="location-block">
                                    <p className="location">Oleviste Church</p>
                                    <p className="location">Lai tn 50, 10133 Tallinn, Estonia</p>
                                </div>
                                <p className="details">Estonian pastors and church leaders, Christian organizations, and spouses are invited to help launch Time of Hope on Saturday, Jan. 25. Join us for an evening of praise, fellowship, an encouraging message, and a call to action.</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      