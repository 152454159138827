import React from 'react';

const ruEstoniaIAA = () => (
  <div>
    <b>Я - как Андрей</b>
    <p>
      «Я - как Андрей» — это решение посвятить себя общению с Иисусом и сделать
      проповедь Благой вести своим главным жизненным приоритетом. Таким образом
      мы обещаем Богу:
    </p>
    <p>
      <b>МОЛИТЬСЯ</b> - напишите имя хотя бы одного знакомого человека, который
      нуждается в Иисусе и молитесь о нем каждый день.
    </p>
    <p>
      <b>ДЕЛИТЬСЯ</b> - Проводите время с этим человеком и используте любую
      возможность делиться с ним вестью об Иисусе.
    </p>
    <p>
      <b>ПРИВОДИТЬ</b> - Приведите своего друга или подругу на евангелизационное
      мероприятие. Если они откликнутся на призыв Христа или проявят интерес к
      Евангелию, они будут нуждаться в вашей поддержке и в дальнейшем.
    </p>
    <p>
      <b>НАПРАВИТЬ</b> - Пригласите его или ее пойти вместе с вами в церковь.
      Бог может действовать через вас и вашу церковь, чтобы привести этого
      человека к глубоким отношениям с Иисусом.
    </p>
  </div>
);

export default ruEstoniaIAA;
