import React from 'react';

const donationHeaderDecisionDigest = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your "Decision Digest" is on its way to your
      inbox.
    </h2>
    <div>
      <p>
        We praise God for your heart for His truth, and it’s our prayer that
        Decision Digest will help you stand boldly for “
        <i>
          the gospel, for it is the power of God for salvation to everyone who
          believes
        </i>
        ” (Romans 1:16, ESV). Your first Decision Digest email should arrive in
        your inbox in the next few days.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer resources
        like this because of friends like you. The Lord is using the gifts and
        prayers of believers like you to reach people across the globe with His
        love—and there are so many more who need to hear the Good News.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift of $50 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderDecisionDigest;
