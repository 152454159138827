import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`
     I’ve been thinking a lot about faithfulness lately—God’s enduring faithfulness, and the faithfulness of friends like you. I hope you understand what your prayers and support mean to all of us at the Billy Graham Evangelistic Association. God is using you, and we are grateful for your partnership in ministry.
  `}</p>{`
 `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "a-legacy-of-gospel-proclamation"
      }}><span parentName="h3" {...{
          "className": "category"
        }}>{`EVANGELISTIC CRUSADES`}</span></h3>{`
    `}<h4 parentName="div">{`Good News for the Gold Coast`}</h4>{`
    `}<p parentName="div">{` I’ve just completed an evangelistic outreach in the Gold Coast region of Australia. It’s a beautiful place, with palm trees, bright lights, the buzz of activity, and miles of sandy beaches—a vacation destination that draws people from around the world. `}</p>{`
    `}<p parentName="div">{` As is often the case, though, the brokenness is right under the surface. The perfect weather 
     `}<span parentName="p" {...{
          "id": "smaller-image",
          "className": "image-float-right"
        }}>{`
        `}<span parentName="span" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAc52T5aRUWf/xAAbEAACAgMBAAAAAAAAAAAAAAABAgMSABETIv/aAAgBAQABBQKlXRLxjxgFZk0rzE9f/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPwGMU//EABsQAAIDAAMAAAAAAAAAAAAAAAABAhExAxJx/9oACAEBAAY/AnWIaR1vCfHqHBYS9P/EABoQAQEBAQEBAQAAAAAAAAAAAAERACExQVH/2gAIAQEAAT8hWO+Hc9iK0v3CfD8Y7jQ64BMi71Gf/9oADAMBAAIAAwAAABDEH//EABcRAQEBAQAAAAAAAAAAAAAAABEBAFH/2gAIAQMBAT8Qoszeb//EABkRAAMAAwAAAAAAAAAAAAAAAAABESExQf/aAAgBAgEBPxCoT2ZdP//EABoQAQADAQEBAAAAAAAAAAAAAAEAESExUXH/2gAIAQEAAT8QNhWMXV+zINwqM783kfa5KNuGlALdRrGcUdbdIvsBWjU//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "gold coast counsel 240603",
              "title": "gold coast counsel 240603",
              "src": "/static/d6612e9952ba33d8b726abbb6ee2ad1b/6a068/gold-coast_counsel_240603.jpg",
              "srcSet": ["/static/d6612e9952ba33d8b726abbb6ee2ad1b/f93b5/gold-coast_counsel_240603.jpg 300w", "/static/d6612e9952ba33d8b726abbb6ee2ad1b/b4294/gold-coast_counsel_240603.jpg 600w", "/static/d6612e9952ba33d8b726abbb6ee2ad1b/6a068/gold-coast_counsel_240603.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</span>{`
    and festive atmosphere hide the hopelessness that people are facing. `}<strong parentName="p">{`Sadly, many visit the Gold Coast to pursue pleasures they think will bring them joy, but instead they find themselves spiraling ever deeper into sin and despair.`}</strong>{`
    `}</p>{`
     `}<p parentName="div">{` What a perfect place to proclaim the Gospel of Jesus Christ—loudly and boldly!
    `}</p>{`
    `}<p parentName="div">{` More than 4,600 people attended the one-day Gold Coast Look Up Celebration, and I encouraged them to turn in repentance to the only One who can give real peace and lasting hope. When I gave the invitation to begin a relationship with Christ, people poured forward from all corners of the Gold Coast Convention and Exhibition Centre. One man literally ran forward, weeping and crying out to God.
    `}</p>{`
   
`}</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      