import React from 'react';

const donationFaithSports = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your link to "Faith & Sports" resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart to live out your faith and pray that like these sports figures you would <em>“not be ashamed of the Gospel…”</em> (Romans 1:16, ESV).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer spiritual resources because of friends like you. The Lord is using the gifts and prayers of people like you to reach people across the globe with His love—and there are so many more who need to hear the Good News.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote className="modalBlockquote">
      Your gift will help share the Gospel with people around the world who are far from God and equip them with powerful Biblical resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationFaithSports;
