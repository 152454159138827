import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentRrt from "../../../../src/content/rrt/main-content-rrt.md";
import AdditionalContent from "../../../../src/content/rrt/additional-content.md";
import styles from "../../../../src/content/rrt/rrt.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentRrt,
  AdditionalContent,
  styles,
  React
};