import { NewNames as NewNamesForm } from '@bgea-js/form-templates';
import TriviaCardsCustomMarkup from "../../../../src/content/ruth-graham-trivia-cards-sign-up/customMarkup";
import "../../../../src/content/ruth-graham-trivia-cards-sign-up/ruth-graham-trivia-cards.scss";
import customModalHeaderTCRuth from "../../../../src/content/ruth-graham-trivia-cards-sign-up/customModalHeader";
import * as React from 'react';
export default {
  NewNamesForm,
  TriviaCardsCustomMarkup,
  customModalHeaderTCRuth,
  React
};