import React from 'react';

const donationHeaderSimplySharingJesus = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your "Simply Sharing Jesus" is on its way to your
      inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God that you want to grow in your relationship with Him, and
        we hope this resource strengthens your faith and encourages you to share
        Jesus with others.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association (BGEA) is able offer Gospel
        resources like the one you’re receiving because of friends who
        generously make this ministry possible. The Lord is using the gifts and
        prayers of believers like you to reach people across the globe with His
        love—and the global pandemic has only brought more opportunities to do
        this. There are so many struggling with isolation and fear who need to
        hear the Good News.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift of $50 today will help share the Gospel with people around the
        world who are looking for hope and equip them with powerful Biblical
        resources to become faithful followers of Jesus Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderSimplySharingJesus;
