import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentDec from "../../../../src/content/prayer-letter-december/main-content-dec.md";
import LearnMoreDec from "../../../../src/content/prayer-letter-december/learn-more-details-dec.md";
import "../../../../src/content/prayer-letter-december/prayer-letter-december.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentDec,
  LearnMoreDec,
  React
};