import { NewNames as NewNamesForm } from '@bgea-js/form-templates';
import DailyBiblePlanCustomMarkup from "../../../../src/content/bible-reading-plan/customMarkup";
import donationHeaderBibleReadingPlan from "../../../../src/content/bible-reading-plan/donationHeader";
import "../../../../src/content/bible-reading-plan/daily-bible-reading-plan.scss";
import * as React from 'react';
export default {
  NewNamesForm,
  DailyBiblePlanCustomMarkup,
  donationHeaderBibleReadingPlan,
  React
};