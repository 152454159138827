import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`As we prepare to celebrate the birth of Jesus Christ, the Lord has given me an exciting opportunity: to `}<em parentName="p">{`“testify that the Father has sent the Son as Savior of the world”`}</em>{` (1 John 4:14, NKJV). In just a couple of weeks, `}<strong parentName="p">{`I will share the Good News in Can Tho, Vietnam, a large 
    city on the country’s Mekong River delta.`}</strong></p>{`
    `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/dec/rrt-boone.jpg",
          "alt": ""
        }}></img>{`
    `}<figcaption parentName="div" {...{
          "className": "category"
        }}><strong parentName="figcaption">{`THERE ARE STILL SO MANY PEOPLE`}</strong>{` in Western North Carolina who are struggling to recover from Hurricane Helene. Our chaplains continue to share the hope of Jesus Christ.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{`Last year, thousands heard the Good News in Ho Chi Minh City and it was incredible to see hundreds responding to the invitation to make decisions for Jesus Christ. I’m praying for the Holy Spirit to work again in Vietnam.`}</p>{`
    `}<p parentName="div">{`The vast majority of Can Tho’s population—like the rest of the country—claims no faith whatsoever. Many others are Buddhist, or follow folk religions. There are so many who have never heard `}<em parentName="p">{`“that while we were still sinners, Christ died for us”`}</em>{` (Romans 5:8, NKJV). `}</p>{`
    `}<p parentName="div">{`There is a small, vibrant Christian community in Can Tho—and they have been working hard to prepare for the Season of Love Festival. Churches across the region are praying for more than 34,000 of their unsaved neighbors by name, and nearly 1,000 believers have been trained to serve as prayer counselors during the event.`}</p>{`
    `}<p parentName="div">{`Reverend Huy Quoc Le, a Vietnamese pastor, recently shared how excited the church is. “To the body of Christ, the last months have been a wonderful experience,” he told us. “Some leaders told me that although they live in the same city, it was rare that they would see each other. `}<strong parentName="p">{`Season of Love creates a precious opportunity for partnership among churches here.”`}</strong></p>{`
    `}<p parentName="div">{`Will you pray for the Festival? Please ask the Lord to embolden believers in Can Tho, to draw people from all walks of life to Himself, and to strengthen the church there.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      