import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentInt from "../../../../src/content/internet-evangelism-update/main-content-int.md";
import "../../../../src/content/internet-evangelism-update/internet-evangelism.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentInt,
  React
};