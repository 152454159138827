import React from 'react';
import customModalTextWGCC from './customModalText';

const customModalHeaderWGCC = ({ firstName }) => (
  <div>
    <a href="https://billygraham.org">
      <img
        src={
          'https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2020/06/logo-bgea-b.png.webp'
        }
        style={{
          maxWidth: '150px',
          display: 'block',
          margin: '0 auto',
        }}
        alt="Billy Graham Evangelistic Association"
      ></img>
    </a>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great news {firstName}! You’ll soon receive your Christmas card from Will
      and Kendra Graham.
    </h2>
    <br></br>
    {customModalTextWGCC}
  </div>
);

export default customModalHeaderWGCC;
