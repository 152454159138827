import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><p>{`
        Each evening began with Festikids, a program for children and their parents that featured colorful 
        performances, fun music, and a Gospel presentation.
    `}</p>{`
    `}<p>{`
        During the two-night outreach, the Holy Spirit was once again at work, and `}<strong parentName="p">{`more than 4,100 people 
        streamed forward `}</strong>{` when given the opportunity to repent of their sins and surrender to Christ as Savior.
    `}</p>{`
    `}<p>{`
        Among those who responded was a woman named Ari,* who suffers from a condition that makes walking difficult. 
        Though she was not a believer, Ari had started listening to Christian radio. When she learned about Esperanza 
        CDMX, she desperately wanted to attend but was afraid her health would not permit it.`}<strong parentName="p">{` Ari did the only thing she 
        could think to do—she prayed. Not only did she make it to the event, 
        but as my father proclaimed the Gospel, she 
        joyfully gave her heart to Christ.`}</strong>{` “Look, I was able to walk. I stood up and went to the front and said to God, 
        ‘Here I am for You,’” Ari shared with tears in her eyes. “I felt God’s love … I feel that I am leaving renewed, 
        full of hope, full of strength. I will not give up, and I want to learn more of the Word,” she said as she held 
        the New Testament we gave her.
    `}</p>{`
    `}<div>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB4XBLUwH/xAAYEAEAAwEAAAAAAAAAAAAAAAABABARIv/aAAgBAQABBQISc5hRX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAAgIf/aAAgBAQAGPwIi/wD/xAAaEAACAwEBAAAAAAAAAAAAAAAAAREhYTFx/9oACAEBAAE/IUeNrwmhnpiTA1jpn//aAAwDAQACAAMAAAAQcy//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8QxX//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARITFBUYH/2gAIAQEAAT8QTSw7bsT2i4qrh5B9iuIDSoyrXPc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mexcity stadium crowd no blend 240211 tp2 2189 hdr inet",
            "title": "mexcity stadium crowd no blend 240211 tp2 2189 hdr inet",
            "src": "/static/0ac6ee962a6aab0311483cd657b017ff/6a068/mexcity_stadium_crowd_no_blend_240211_tp2_2189-hdr_inet.jpg",
            "srcSet": ["/static/0ac6ee962a6aab0311483cd657b017ff/f93b5/mexcity_stadium_crowd_no_blend_240211_tp2_2189-hdr_inet.jpg 300w", "/static/0ac6ee962a6aab0311483cd657b017ff/b4294/mexcity_stadium_crowd_no_blend_240211_tp2_2189-hdr_inet.jpg 600w", "/static/0ac6ee962a6aab0311483cd657b017ff/6a068/mexcity_stadium_crowd_no_blend_240211_tp2_2189-hdr_inet.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`MORE THAN 2,000 CHURCHES gathered regularly for the last year to pray for people to discover the Gospel during 
    the Festival—and God answered those prayers.`}</figcaption>{`
    `}</div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      