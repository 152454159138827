import React from 'react';
import { Block, Heading } from '@bgea-js/react-ui';

const privacyPolicyCopyLaos = () => (
  <div>
    <Heading subtitle textSize={4}>
      ໃບຍິນຍອມມອບຂໍ້ມູນ
    </Heading>
    <Block textSize={6}>
      ໂດຍການໃຫ້ຂໍ້ມູນສ່ວນໂຕຂອງທ່ານ (ລວມທັງມູມມອງທາງສາສະໜາຂອງທ່ານ),
      ທ່ານໄດ້ຍິນຍອມໃຫ້ພວກເຮົາ: ພວກເຮົາຈະນຳ ຂໍ້ມູນດັ່ງກາວນີ້
      ເພື່ອຕິດຕໍ່ທ່ານກ່ຽວກັບວາລະຕ່າງໆ ທີ່ຈະມີຂຶ້ນ ແລະ ໃຫ້ທ່ານໄດ້ຮູ້ກ່ຽວກັບ
      ກິດຈະກຳຂອງພັນທະກິດຕ່າງໆ ຂອງ BGEA; ແລະນຳໃຊ້ຂໍມູນນີ້
      ເພື່ອຈະຈັດການເຂົ້າຮ່ວມຂອງທ່ານ.
      ຂໍ້ມູນດັ່ງກ່າວນີ້ຈະໄດ້ຮັບການດູແລຈາກພັນທະກິດເຮົາ.
    </Block>
    <Block textSize={6}>
      BGEA ທີ່ຢູ່ສະຫະຣັດ ຫຼືປະເທດທີ່ພວກເຂົາໄດ້ເກັບຂໍ້ມູນມານັ້ນ
      (ແລະອາດຈະຖືກສົ່ງໃຫ້ເພື່ອນຮ່ວມພັນທະກິດທີ່ຢູ່ໃນປະເທດຂອງທ່ານເພື່ອດຳເນີນການຕໍ່)
      ເພື່ອຈຸດປະສົງທີ່ຢູ່ດ້ານເທິງເທົ່ານັ້ນ.
      ຖ້າຫາກທ່ານຕ້ອງການທີ່ຈະປັບຄວາມຕ້ອງການຂອງທ່ານ ຫຼື
      ຢາກຮູ້ເພີ່ມເຕີມກ່ຽວກັບວິທີທີ່ພວກເຮົາຮັກສາຂໍ້ມູນຂອງທ່ານ,
      ທ່ານສາມາດເຂົ້າໄປເບິ່ງນະໂຍບາຍຄວາມເປັນສ່ວນຕົວຂອງສະມາຄົມ ການປະກາດຂ່າວປະເສີດ
      ບີວລີ່ ແກຣ໌ແຮມ ໄດ້ທີ່{' '}
      <a href="https://billygraham.org/lao-privacy-policy/">
        BillyGraham.org/privacy
      </a>
      .
    </Block>
  </div>
);

export default privacyPolicyCopyLaos;
