const FearlessCustomMarkup = {
  markup: `<h3 style="text-align:center;">GET THESE DIGITAL SCRIPTURE CARDS WHEN YOU SIGN UP FOR UPDATES ON THE <em>FEARLESS WITH CISSIE GRAHAM LYNCH</em> PODCAST.</h3>
  <p className="p-content">
  How do you live faithfully for Jesus Christ in a culture that’s increasingly hostile to
  Him? Cissie Graham Lynch—daughter of Franklin Graham and granddaughter of Billy
  Graham—offers a practical, Biblical perspective on issues facing our world today in
  her <em>Fearless</em> podcast. 
  </p>
  <p style="margin-bottom:3rem;">
  When you sign up using the form below, we’ll send you a link to download your digital
  Scripture cards that you can personalize for yourself or use to encourage others.
  You’ll also receive email updates when the next episode of <em>Fearless with Cissie Graham Lynch</em> is released. It’s
  our prayer that these resources will encourage you to mature in faith and be “<em>bold in
  our God to speak … the gospel</em>” (1 Thessalonians 2:2, NKJV).
  </p>
  `,
};

export default FearlessCustomMarkup;
