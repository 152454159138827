import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="legacy-container hidden">
  <h3 className="legacy-title">Thank you for your interest in our Legacy Planner</h3>
  <p className="legacy-content"> When you fill out the form below, we will send you practical estate planning 
  information as well as guidance for simple but powerful ways you can share your 
  Christian faith. Inside your planner, you will find tips on sharing your faith in 
  your will, a will information worksheet, a guide to documenting your final wishes, 
  and other practical checklists. We are so grateful for Kingdom-minded friends like 
  you who share our passion to "<em>make disciples of all nations</em>" (Matthew 28:19).</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      