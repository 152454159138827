import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<div parentName="div" {...{
        "className": "image-float-right franklin-bio"
      }}>{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "400px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAH/2gAMAwEAAhADEAAAAcb5dNkRgU49aRZJEDDL/8QAHRAAAQUAAwEAAAAAAAAAAAAAAQACAxESISIzQf/aAAgBAQABBQJjbJibikOGsPUhWdSDMdlfJvFf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAgESIP/aAAgBAwEBPwEmK4//xAAXEQEAAwAAAAAAAAAAAAAAAAACARIg/9oACAECAQE/AUptj//EAB0QAAIBBAMAAAAAAAAAAAAAAAEQAAIRITFBUXH/2gAIAQEABj8CzLh8dIEmezapX//EAB4QAQACAgEFAAAAAAAAAAAAAAEAESExURBBcYGR/9oACAEBAAE/IQ4EYrxO0coLlr3DNr1pMsvSNN5j2Yrh5gel96uT/9oADAMBAAIAAwAAABCvDQ7/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARMf/aAAgBAwEBPxBCWZNTlf/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAAC2Dbb//EAB4QAQEBAAICAwEAAAAAAAAAAAERACExQVFhkaHB/9oACAEBAAE/EDHKeWd59QLTbpYSACSeXxhIAl7D0XTZMMAFSvncR1IC6fI+sFBnqt/G/Sbpv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Franklin Graham.",
            "title": "Franklin Graham.",
            "src": "/static/c167d571726fc53d4869fdbef13f5ddd/066f9/fg_israel_slight_serious_v4%20bkgrd_update.jpg",
            "srcSet": ["/static/c167d571726fc53d4869fdbef13f5ddd/f93b5/fg_israel_slight_serious_v4%20bkgrd_update.jpg 300w", "/static/c167d571726fc53d4869fdbef13f5ddd/066f9/fg_israel_slight_serious_v4%20bkgrd_update.jpg 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</div>{`
    `}<p parentName="div">{`  This year’s hurricane season has been one of the worst in recent years. `}<strong parentName="p">{`People all across the Southeast are still reeling from back-to-back storms, including Helene and Milton.`}</strong>{` You are making it possible for Billy Graham Rapid Response Team chaplains to deploy at a moment’s notice to hurting communities in this region—and those affected by other disasters across our nation.`}</p>{`
    `}<p parentName="div">{`  During each deployment, these crisis-trained men and women point people from all walks of life to “`}<em parentName="p">{`God our Savior and ... Christ Jesus our hope`}</em>{`” (1 Timothy 1:1, ESV). In addition to those affected by hurricanes, our chaplains have ministered in Georgia following the tragic shooting at a local high school and California in the wake of wildfires. `}<strong parentName="p">{`We praise God for each person they have prayed with and talked to—and for the hundreds who have responded by receiving Jesus Christ in repentance and faith.`}</strong></p>{`
    `}<p parentName="div">{`  Will you pray for those who have made a life-changing decision for the Lord through this ministry? Please ask God to strengthen them and to continue drawing many more men, women, and children to Himself. Thank you for your heart to share His love.`}</p>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
    `}<p parentName="div">{`Franklin Graham | President & CEO`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      