import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="content-wrapper featured" id="pre-events">
    <div className="margins">
        <div className="content-container english">
            <h1 className="bottom-title"><span>MUUD EELSEISVAD</span></h1>
            <h1 className="top-title">PLANEERITUD ÜRITUSED</h1>
        </div>
        <div className="content-container" id="pre-events-list">
            <div className="margins">
                <div className="event column">
                    <p className="event-entry-day">L</p>
                    <div className="pre-event-content-container">
                        <p className="date">07.12.2024</p>
                        <h2>Pastorite infoseminar</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Eesti Metodisti Kirik</p>
                                <p>Narva mnt 51</p>
                                <p>10120 Tallinn, Estonia</p>
                            </div>
                            <a id="details1" className="modal-btn" href="#modal1">&#8250;</a>
                        </div>
                        <section id="modal1" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details1" className="modal-close">
                                    <span className="closeModal">close</span>
                                </a>
                                <h1>Pastorite infoseminar</h1>
                                <p className="time">07.12, 10:30</p>
                                <div className="location-block">
                                    <p className="location">Eesti Metodisti Kirik</p>
                                    <p className="location">Narva mnt 51, 10120 Tallinn, Estonia</p>
                                </div>
                                <p className="details">Kutsume eesti pastoreid kokku laupäeval, 7. detsembril, et kuulda rohkem eelseisvast festivalist „Lootuse aeg“ Will Grahamiga.</p>
                                <p className="details">Saate teada, kuidas teie kogudus võiks osaleda Jeesuse Kristuse Eesti inimesteni viimises. Käsitletavad teemad on järgmised:</p>
                                <ul>
                                    <li>Kuidas võiks teie kogudus olla palvepartneriks</li>
                                    <li>Kuidas kogudusena pühenduda „Mina olen nagu Andreas“ visioonile</li>
                                    <li>Kuidas teie kogudus võiks kasu saada kursuse „Kristlik elu ja tunnistus“ (KET) võõrustamisest</li>
                                    <li>KET-kursuse lõpetanute julgustamine osalema festival „Lootuse aeg“ palvenõustajate ja vabatahtlikena</li>
                                    <li>Kuidas registreerida gruppe</li>
                                    <li>Kuidas hoida kontakti ja teha järeltööd festivalil Kristuse vastu võtnud isikutega</li>
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="event column">
                    <p className="event-entry-day">L</p>
                    <div className="pre-event-content-container">
                        <p className="date">25.01.2025</p>
                        <h2>FESTIVALI „LOOTUSE AEG“ AVATEENISTUS</h2>
                    </div>
                    <div className="pre-event-content-container">
                        <div className="preview-container">
                            <div>
                                <p>Oleviste kirik</p>
                                <p>Lai tn 50</p>
                                <p>10133 Tallinn, Estonia</p>
                            </div>
                            <a id="details2" className="modal-btn" href="#modal2">&#8250;</a>
                        </div>
                        <section id="modal2" className="modal-dialog">
                            <div className="modal-overlay"></div>
                            <div role="dialog" className="modal-content">
                                <a title="Close modal" href="#details2" className="modal-close"><span className="closeModal">close</span></a>
                                <h1>Festival „Lootuse aeg“ toimumisaasta avateenistus</h1>
                                <p className="time">25.01, 16:00-18:00</p>
                                <div className="location-block">
                                    <p className="location">Oleviste kirik</p>
                                    <p className="location">Lai tn 50, 10133 Tallinn, Estonia</p>
                                </div>
                                <p className="details">Laupäeval, 25. jaanuaril, on kõik Eesti pastorid ja kirikujuhid, kristlikud organisatsioonid ja kristlased kutsutud festival „Lootuse aeg“ toimumisaasta avateenistusele. Ühine meiega, et laulda koos ülistuskoor Ekklesia juhatusel, saada osa pastor Viktor Hammi sõnumist ning palvetada Eestimaa ja festivali ettevalmistuste eest. Kohtumine lõpeb ühise kohviosadusega.</p>
                                <p className="details">Palume teil oma tulekust ja tulijate arvust teada anda 15. jaanuariks kontaktidel: +372 57877748 või <a className="link" href="mailto:info@lootuseaeg.com">info@lootuseaeg.com</a>.</p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      