import React from 'react';
import customModalTextCG from './customModalText';

const donationHeaderCourageGrief = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your "Courage in Grief" is on its way to your
      inbox.
    </h2>
    <br></br>
    {customModalTextCG}
  </div>
);

export default donationHeaderCourageGrief;
