import { ButtonCollapsible } from '@bgea-js/design-library';
import Internationals from "../../../../src/content/extraordinary-journey/internationals.mdx";
import Title from "../../../../src/content/extraordinary-journey/title.mdx";
import Funeral from "../../../../src/content/extraordinary-journey/funeral.mdx";
import Order from "../../../../src/content/extraordinary-journey/order.mdx";
import AvailInternational from "../../../../src/content/extraordinary-journey/availInternational.mdx";
import "../../../../src/content/extraordinary-journey/extraOrdinary.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  Internationals,
  Title,
  Funeral,
  Order,
  AvailInternational,
  React
};