import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div {...{
        "id": "main-wrap"
      }}>{`
  `}<div parentName="div" {...{
          "className": "content-wrapper"
        }}>{`
    `}<div parentName="div" {...{
            "className": "margins"
          }}>{`
      `}<div parentName="div" {...{
              "className": "content-container english"
            }}>{`
        `}<h1 parentName="div" {...{
                "className": "top-title"
              }}>{`
          `}<span parentName="h1">{`«ВРЕМЯ НАДЕЖДЫ»`}</span>{`
        `}</h1>{`
        `}<h1 parentName="div" {...{
                "className": "bottom-title"
              }}>{`с Уиллом Грэмом`}</h1>{`
        `}<div parentName="div" {...{
                "className": "text"
              }}>{`
          `}<p parentName="div">{`
            Фестиваль «Время надежды» с Уиллом Грэмом пройдет в столице Эстонии,
            Таллинне, на ледовой арене Тондираба 18 и 19 октября 2025 года (в
            субботу и в воскресенье). В программе слово надежды от евангелиста
            Уилла Грэма и бесплатный концерт ведущих христианских исполнителей.
            Для проведения этого уникального общественного мероприятия церкви в
            Таллинне и по всей Эстонии объединятся в одну большую команду.
          `}</p>{`
          `}<div parentName="div" {...{
                  "className": "event-date"
                }}>{`
            `}<p parentName="div">{`
              `}<span parentName="p">{`18 - 19`}</span>{`
            `}</p>{`
            `}<p parentName="div">{`октября`}</p>{`
            `}<p parentName="div">{` `}</p>{`
            `}<p parentName="div">{`2025`}</p>{`
          `}</div>{`
          `}<p parentName="div" {...{
                  "className": "text-location"
                }}>{`
            Ледовая арена Тондираба, ТАЛЛИНН, ЭСТОНИЯ
          `}</p>{`
        `}</div>{`
      `}</div>{`
      `}<div parentName="div" {...{
              "className": "feat-img",
              "id": "desktop"
            }}>{`
        `}<img parentName="div" {...{
                "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/10/will-graham.png",
                "alt": "Will Graham"
              }}></img>{`
      `}</div>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
          "className": "pattern-block",
          "id": "desktop"
        }}>{`
     
  `}</div>
      </div>{`;`}</undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      