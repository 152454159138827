import React from 'react';

const eeEstoniaPrivacyPolicyCopy = () => (
  <div>
    <p>
      Andmete esitamisega (sealhulgas teie usuliste vaadete kohta) te annate
      nõusoleku, et me: kasutame teie andmeid selleks, et hoida ühendust
      festival “Lootuse aeg” ja teavitada teid BGEA muudest teenistuslike
      tegevuste kohta; ja kasutame neid andmeid selleks, et hallata teie
      osalemist BGEAs. Neid andmeid säilitab BGEA USAs ja/või EEs (ja neid
      võidakse edastada meie teenistuspartneritele teie riigis, näiteks Center
      of Hope MTÜ-le, töötlemiseks) ainult eespool nimetatud eesmärkidel. Et
      hallata eelistusi ja saada rohkem teavet selle kohta, kuidas me teie
      andmeid käsitleme, vaadake{' '}
      <a
        href="https://billygraham.org/privacy-estonian/"
        target="_blank"
        rel="noreferrer"
      >
        BGEA privaatsuspoliitikat
      </a>
      .
    </p>
  </div>
);

export default eeEstoniaPrivacyPolicyCopy;
