import React from 'react';

const donationHeaderBorn2You = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! A link to this special offer is on its way to your
      inbox.
    </h2>
    <br></br>
    <div>
      <p>Before you go, there are a few things we’d like you to know…</p>
      <p>
        The Billy Graham Evangelistic Association is able to offer materials
        like this because of friends like you. For updates on how God is moving
        around the world and for more spiritual growth content, visit <a href="https://billygraham.org">BillyGraham.org</a>.
      </p>
      <p>
        The Lord is using the gifts and prayers of believers like you to reach
        people across the globe with His love—and there are so many more who
        need to hear the Good News.
      </p>
      <p>
        Will you give them the opportunity to hear it?{' '}
        <a href="https://billygraham.org/give/give-online/?_ga=2.231393695.1861086448.1720015611-1800017408.1719517738">
          Donate today
        </a>
        .
      </p>
    </div>
  </div>
);

export default donationHeaderBorn2You;
