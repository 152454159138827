import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/06/will-seoul-preach.jpg",
          "alt": "Will Graham preaching in Seoul."
        }}></img>{`
    `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`WILL GRAHAM PREACHED THE GOSPEL`}</strong>{` during a youth service in
      Seoul which drew thousands of kids and teenagers.
    `}</figcaption>{`
  `}</div>{`
  `}<p parentName="div">{`
    My son Will also preached at a service for youth on the night before the
    anniversary event. He told thousands of young people in Seoul’s SaRang
    Church that the
    `}<strong parentName="p">{`
      most important message they’ll ever hear is that God loves them
    `}</strong>{`
    and sent His Son to die for them. Hundreds of kids and teens made
    life-changing decisions for Jesus Christ during the service. Will you ask
    the Lord to strengthen each new believer? Please also
    `}<strong parentName="p">{`
      pray that a spiritual revival will sweep across South Korea.
    `}</strong>{`
  `}</p>{`
  `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/06/seoul-stadium.jpg",
        "alt": "Overlook of the Seoul stadium."
      }}></img>{`
  `}<figcaption parentName="div">{`
    `}<strong parentName="figcaption">{`IT WAS A PRIVILEGE TO PROCLAIM THE GOOD NEWS`}</strong>{` to over 70,000
    people and to watch as many responded by surrendering their lives to Him in
    repentance and faith.
  `}</figcaption>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "australia"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`EVANGELISTIC CRUSADES`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`EQUIPPING PASTORS IN AUSTRALIA`}</h4>{`
  `}<p parentName="div">{`
    After Will left South Korea, he joined more than 160 pastors and church
    leaders on
    `}<strong parentName="p">{`
      Australia’s Gold Coast to encourage and equip them in the work of the
      Gospel.
    `}</strong>{`
    He held Celebrations in Tasmania last year, and he was grateful for the
    opportunity to return to this part of the world to meet with these men and
    women who are on fire to share the Gospel with their neighbors. Will you
    pray for leaders in this part of the world? Ask the Lord to help them boldly
    share His love and truth in the midst of overwhelming secularism.
  `}</p>{`
  `}<p parentName="div">{`
    As I write, Will is preparing to share the Good News in
    `}<strong parentName="p">{` Chişinău, Moldova`}</strong>{`. This country has become a refuge for
    people fleeing the conflict in `}<strong parentName="p">{`Ukraine`}</strong>{`. The conflict has
    caused great social division in Moldova, and so many need to hear about the
    hope of Jesus Christ. Please pray that God will open hearts as Will preaches
    His Word.
  `}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "legacy"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM LIBRARY`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`HONORING A LEGACY OF FAITH`}</h4>{`
  `}<p parentName="div">{`
    In addition to the services in Seoul,
    `}<strong parentName="p">{`June marked another important anniversary`}</strong>{`. My mother, Ruth
    Bell Graham, entered Heaven on June 14, 2007. Without her faithful presence
    at home, my father would not have been able to answer God’s call to preach
    around the world. She prayed tirelessly for me—and for my
    siblings—especially during my years as a prodigal.
    `}<strong parentName="p">{`
      I was grateful for the chance to honor my mother’s faith and legacy
    `}</strong>{`
    at the Billy Graham Library on June 16.
  `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/06/graham-family.jpg",
          "alt": "Older photograph of the Graham Family."
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`
    My sister Gigi spoke at our Ladies Tea that day, and we also welcomed
    hundreds of others for a special showing of `}<em parentName="p">{`Footprints of a Pilgrim`}</em>{`
    . This program follows my mother’s life from her childhood in China to her
    years of service to the Lord as she raised us kids and pointed us to His
    truth. It’s my prayer that each person who visited the Library for this
    special celebration was inspired to boldly serve God.
  `}</p>{`
  `}<p parentName="div">{`
    `}<strong parentName="p">{`Summer is a wonderful time to come to the Library.`}</strong>{` Come see
    the updated exhibits and
    `}<strong parentName="p">{`
      bring your family members and friends who don’t know the Lord
    `}</strong>{`
    . When they visit, they’ll hear a
    `}<strong parentName="p">{`clear presentation of the Gospel`}</strong>{` and see how God can
    transform any life surrendered to Him.
  `}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "facing-tragedy"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`FACING TRAGEDY WITH THE HOPE OF JESUS CHRIST`}</h4>{`
  `}<p parentName="div">{`
    A few weeks ago, we had the opportunity to help lead a
    `}<strong parentName="p">{`training conference for embattled chaplains in Ukraine`}</strong>{`. We
    wanted to come alongside these brave Christians as they share the love of
    Jesus Christ with their neighbors, who are facing unspeakable adversity on a
    daily basis as the conflict rages on.
  `}</p>{`
  `}<p parentName="div">{`
    `}<strong parentName="p">{`
      Many of those who attended the You Are Not Alone conference have lost
      their homes and jobs—and any sense of security
    `}</strong>{`
    . Despite these hardships, hundreds of chaplains from all over the country
    gathered to learn more about how they can minister in Jesus’ Name to those
    around them.
  `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/06/ukraine-praying-group.jpg",
          "alt": "Chaplains praying at conference."
        }}></img>{`
    `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`HUNDREDS OF CHAPLAINS IN UKRAINE`}</strong>{` were encouraged to keep
      Gospel proclamation central to their ministry and were reminded that they
      aren’t alone in pursuing this goal.
    `}</figcaption>{`
  `}</div>{`
  `}<p parentName="div">{`
    Just days after the conference ended, the massive Nova Kakhovka dam on
    Ukraine’s Dnieper River collapsed. Nine people lost their lives and tens of
    thousands were left without power or clean water. Chaplains who had just
    finished the conference days earlier were able to deploy to the region and
    offer emotional and spiritual support. In addition to praying and offering a
    listening ear, they handed out Ukrainian-language Bibles to many suffering
    people.
  `}</p>{`
  `}<p parentName="div">{`
    `}<strong parentName="p">{`
      Will you pray for the countless thousands who are hurting because of this
      senseless violence—and for these chaplains who are boldly sharing the love
      of God?
    `}</strong>{`
  `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/06/fg-glyt-uk.jpg",
          "alt": "Logo of God Loves You Tour UK."
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`
    Please also pray for upcoming Gospel ministry in the United Kingdom. In
    August, I have the privilege of returning to `}<strong parentName="p">{`London `}</strong>{`
    to proclaim the Good News that
    `}<em parentName="p">{`“whoever believes in the Son has eternal life”`}</em>{` (John 3:36, ESV).
    Will you ask the Lord to draw people from all walks of life to Himself all
    across southern England?
  `}</p>{`
  `}<p parentName="div">{`
    Thank you for your heart to reach people far from God with the truth of the
    Gospel. I am grateful for you.
  `}</p>{`
  `}<p parentName="div">{`Sincerely,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": "Franklin Graham signature."
      }}></img>{`
  `}<p parentName="div">{`
    Franklin Graham
    `}<br parentName="p"></br>{`
    President & CEO
  `}</p>{`
  `}<p parentName="div">{`*Names changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<h4 parentName="div" {...{
        "className": "cta mt-6"
      }}>{`YOU CAN MAKE A DIFFERENCE`}</h4>{`
  `}<h4 parentName="div" {...{
        "className": "mb-6"
      }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
  `}</h4>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes
    lives—now and for eternity. When you designate a gift for “Where Most
    Needed,” it is available for immediate ministry opportunities and needs,
    including the personnel, resources, materials, equipment, and support
    services vital to the work of evangelism around the world.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are
    living without the hope that comes from Jesus Christ. When you send a gift
    for Evangelistic Crusades, you give people across the globe the chance to
    hear the Gospel at events like upcoming Will Graham Celebrations in Moldova,
    Brazil, and Idaho.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`BILLY GRAHAM LIBRARY`}</span>{` | We hear from Library
    guests daily about how God has touched their lives—or changed their
    hearts—during their visit. Your gift to the Billy Graham Library helps keep
    admission free, sharing the Good News of Christ with thousands of people who
    otherwise might not visit.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your
    contribution in support of the Billy Graham Rapid Response Team helps make
    it possible for crisis-trained chaplains to bring comfort in Christ’s Name
    to hurting communities and broken lives.
  `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      