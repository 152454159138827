import React from 'react';

const ruEstoniaConfirmation = () => (
  <div>
    <h1 className="title">Спасибо!</h1>
    <div className="block">
      <p>Ваша заявка отправлена.</p>
    </div>
  </div>
);

export default ruEstoniaConfirmation;
