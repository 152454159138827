import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "content-wrapper radio-options"
    }}>{`
  `}<a parentName="div" {...{
        "target": "_blank",
        "href": "https://peacewithgod.net/mobile/?utm_source=Redirect+Radio+Resources+LP+findpeacewithgod.net&utm_medium=PWG+link&utm_campaign=RAD&outreach=findpeacewithgod.net",
        "className": ""
      }}>{`
    `}<img parentName="a" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/august/billygrahamradio/AdobeStock_457663901.jpg",
          "alt": ""
        }}></img>{`
    `}<p parentName="a">{`Begin a Relationship With Jesus`}</p>{`
  `}</a>{`
  `}<a parentName="div" {...{
        "target": "_blank",
        "href": "https://goingfarther.net/?utm_source=Redirect+Radio+Resources+LP+findpeacewithgod.net&utm_medium=GF+link&utm_campaign=RAD&outreach=findpeacewithgod.net",
        "className": ""
      }}>{`
    `}<img parentName="a" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/august/billygrahamradio/AdobeStock_269114145.jpg",
          "alt": ""
        }}></img>{`
    `}<p parentName="a">{`Grow in Your Christian Faith`}</p>{`
  `}</a>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      