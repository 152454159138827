import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`
     I’ve just finished preaching in `}<strong parentName="p">{`Kraków, Poland`}</strong>{`, where the Lord did a 
    mighty work. Before the God Loves You Tour event, journalists in the city 
    bluntly asked me why I wanted to share the Gospel there in Poland’s City of 
    Churches.
  `}</p>{`
  `}<p parentName="div">{`
  `}<span parentName="p" {...{
            "className": "image-float-right"
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "960px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "66.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAU+mKxLCf//EABoQAQEAAgMAAAAAAAAAAAAAAAECABEDEjP/2gAIAQEAAQUCokq4dO1vyHeVx9X/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEUH/2gAIAQMBAT8B1H//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEkH/2gAIAQIBAT8BxT//xAAaEAACAgMAAAAAAAAAAAAAAAAAATFxECFB/9oACAEBAAY/AodF8xQjTk//xAAcEAADAAIDAQAAAAAAAAAAAAAAAREhMUFhgZH/2gAIAQEAAT8hUHBtWuDMxXLoO5NDfQeivc36LRSJT//aAAwDAQACAAMAAAAQX/8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAwEBPxBvBZ//xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhUf/aAAgBAgEBPxAXcse3/8QAHRABAAICAgMAAAAAAAAAAAAAAQARIUFRkWFx8P/aAAgBAQABPxBZV01HXdnpggRvW3DoiNmMHiOLNtAuWql5Kt3NJpgCg19U/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "coundel krakow ladies",
                "title": "coundel krakow ladies",
                "src": "/static/e23d738b8a8c868d89d38f3b4fb9bbd9/6a068/coundel_krakow_ladies.jpg",
                "srcSet": ["/static/e23d738b8a8c868d89d38f3b4fb9bbd9/f93b5/coundel_krakow_ladies.jpg 300w", "/static/e23d738b8a8c868d89d38f3b4fb9bbd9/b4294/coundel_krakow_ladies.jpg 600w", "/static/e23d738b8a8c868d89d38f3b4fb9bbd9/6a068/coundel_krakow_ladies.jpg 960w"],
                "sizes": "(max-width: 960px) 100vw, 960px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>{`
    `}</span>{`
    While it may be filled with historic cathedrals, so many of its residents 
   believe that following a set of rituals will make them right with God. My 
   answer to the media was simple: only a relationship with Jesus Christ can 
   do that—and`}<strong parentName="p">{` we praise God that more than 730 people made life-changing 
   decisions for Him during the event.`}</strong>{` 
  `}</p>{`
  `}<p parentName="div">{`
   Almost 200 buses brought people to the TAURON Arena Kraków from all 
  across the region—as far as eight hours away—from the far north of Poland 
  to the Czech Republic in the west. And some of the first buses to arrive came
  all the way from Ukraine! Hundreds of churches spread the word about the tour, 
  and many evangelical denominational leaders helped prepare for this massive outreach. 
  By the time I began preaching, more than 13,000 men, women, and children had filled 
  the arena. After sharing the Gospel message, I invited all those who wanted to “`}<em parentName="p">{`confess 
  that Jesus Christ is Lord, to the glory of God the Father`}</em>{`” (Philippians 2:11, ESV) 
  to come forward. 
  `}</p>
      </div>
      <br></br></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      