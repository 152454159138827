import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="callout" id="panel" style={{
      "display": "block"
    }}>
        <div className="dvd-group-one">
                <a className="button order-secondary" href="https://www.amazon.ca/Billy-Graham-Extraordinary-Journey-Self/dp/B07FSWK6HR/" target="_blank">Canada DVD</a>
                <a className="button order-secondary" href="https://koorong.com/product/billy-graham-an-extraordinary-journey_9323078033352" target="_blank">AUS/NZ DVD</a>
                <a className="button order-secondary" href="https://www.amazon.de/Billy-Graham-Auftrag-gew%C3%B6hnlichen-ungew%C3%B6hnliche/dp/B07QMB4DSV/" target="_blank">Germany DVD</a>
                <a className="button order-secondary" href="https://www.amazon.co.uk/Billy-Graham-An-Extraordinary-Journey/dp/B07FSWK6HR" target="_blank">UK DVD</a>
                <a className="button order-secondary" href="https://www.sajedistribution.com/boutique/dvd-chretien/billy-graham-dvd/" target="_blank">France DVD</a>
        </div>
        <div>
            <a className="button order-secondary" href="https://billygrahamanextraordinaryjourney.vhx.tv/products/billy-graham-an-extraordinary-journey-international-extras-edition" target="_blank">Order for Digital Release</a>
            <p id="p-order">Or from one of these other retailers</p>
            <ul className="dvd-group">
                <li><a className="button secondary" href="https://itunes.apple.com/us/movie/billy-graham-an-extraordinary-journey/id1432286556" target="_blank">iTunes</a></li>
                <li><a className="button secondary" href="https://vimeo.com/ondemand/bganextraordinaryjourney" target="_blank">vimeo</a></li>
                <li><a className="button secondary" href="https://www.christiancinema.com/rent?item_id=CC00008564&title=Billy-Graham%3A-An-Extraordinary-Journey" target="_blank">Christian Cinema</a></li>
            </ul>
        </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      