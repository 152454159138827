import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/bg-statue-e1719341394898.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div">{`THE STATUE FEATURES TWO BIBLE VERSES—John 3:16 and John 14:6—along with the words “Preacher of the Gospel of Jesus Christ” on its base.`}</figcaption>{`
    `}</div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "a-legacy-of-gospel-proclamation"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`BILLY GRAHAM STATUE DEDICATION`}</span></h3>{`
    `}<h4 parentName="div">{`A LEGACY OF GOSPEL PROCLAMATION`}</h4>{`
    `}<p parentName="div">{` A few weeks ago, I had the opportunity to speak in Washington, D.C., during the dedication ceremony as a 7-foot bronze likeness of my father was placed in the U.S. Capitol. The statue features two Bible verses—John 3:16 and John 14:6—along with the words “Preacher of the Gospel of Jesus Christ” on its base. `}<strong parentName="p">{`It was a privilege to see my father’s lifelong Gospel work celebrated in the heart of our nation’s government`}</strong>{`. I told all those gathered how he would have been uncomfortable with the spotlight on him—and then shared the message that he dedicated his life to: `}<em parentName="p">{`“that while we were still sinners, Christ died for us”`}</em>{` (Romans 5:8, NKJV).`}</p>{`
    `}<div parentName="div" {...{
          "id": "smaller-image",
          "className": "image-float-left"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/john-14.jpg",
            "alt": ""
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{` I was also deeply touched by all those who talked about my father’s impact on our nation—and on their own lives. House Speaker Mike Johnson, Senator Ted Budd, Senator Thom Tillis, Congresswoman Virginia Foxx, and North Carolina Governor Roy Cooper were among those who spoke. Congresswoman Foxx summed it up well when she said, “His life’s work was dedicated to the millions of people around the world who found Jesus Christ as their Savior through his preaching.” `}<strong parentName="p">{`Will you pray that this statue will point visitors to the Good News for generations to come?`}</strong>{` If you are ever in Washington, D.C., I hope you’ll stop by to see it—and bring friends with you who don’t know Jesus Christ.`}</p>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "a-weight-was-lifted-off-him"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`GOSPEL TV SPOTS`}</span></h3>{`
    `}<h4 parentName="div">{`A WEIGHT WAS LIFTED OFF HIM`}</h4>{`
    `}<p parentName="div">{` Over the past month, a new round of our Gospel TV spots aired across the nation. In each of these short ads, I shared the `}<em parentName="p">{`“good news about the kingdom of God and the name of Jesus Christ”`}</em>{` (Acts 8:12, ESV), along with the number for our Billy Graham 24/7 Prayer Line. In the four years that we have aired these short messages, our prayer line team members have answered millions of calls—and thousands of people have told us they’ve made decisions for the Lord.`}</p>{`
    `}<p parentName="div">{` We praise God that Dalton is among them. The young man called the prayer line after watching one of the spots. At only 20 years old, he was burdened by tragedy. His best friend died recently after trying to save another friend from drowning. Both lost their lives—and Dalton was heartbroken. He also told our team member that some of his family members had died over the past couple years, and `}<strong parentName="p">{`he was filled with regret about how he had treated them.`}</strong></p>{`
    `}<p parentName="div">{` The two talked about God’s promise that `}<em parentName="p">{`“as the heavens are high above the earth, so great is His mercy toward those who fear Him; as far as the east is from the west, so far has He removed our transgressions from us”`}</em>{` (Psalm 103:11–12, NKJV). `}<strong parentName="p">{`Dalton said a heartfelt prayer of repentance and received new life in Jesus Christ.`}</strong>{` He said that he felt like a weight had been lifted off his chest. Before the call ended, we arranged to send him a new Bible and showed him how to use our online church locator tool. Please join us in praying for Dalton—and for each person who has surrendered their life to the Lord through this outreach over the last few weeks.`}</p>{`
    `}<div parentName="div">{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/RRT-scene_Greenfield-Iowa_393_no-blend_INET.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div">{`BG-RRT CHAPLAINS have ministered in many states hit by tornadoes.`}</figcaption>{`
    `}</div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "the-free-gift-of-god"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span></h3>{`
    `}<h4 parentName="div">{`THE FREE GIFT OF GOD`}</h4>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/rrt-forwerck.jpg",
            "alt": ""
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{` It’s been heartbreaking to read about the near-constant barrage of deadly tornadoes in the U.S. this year. Our Billy Graham Rapid Response Team (BG-RRT) chaplains have repeatedly deployed to `}<strong parentName="p">{`Oklahoma, Texas,`}</strong>{` and `}<strong parentName="p">{`Arkansas`}</strong>{`—among other states—to minister to those affected. Dozens have died and thousands have lost homes and businesses. `}</p>{`
    `}<p parentName="div">{` A couple of chaplains recently met Myrtle,* who had suffered a stroke shortly before the tornado hit her Oklahoma neighborhood. Even though it was difficult for her to speak, she was grateful for the chaplains’ presence and she talked to them as much as she could. She explained that she loved God and she hoped that she had done enough good works to see Him in Heaven. Our chaplains gently shared the truth with her—`}<em parentName="p">{`“by works of the law no human being will be justified in his sight”`}</em>{` (Romans 3:20, ESV). `}<strong parentName="p">{`Using Scripture, they explained the Gospel and Myrtle followed along closely.`}</strong>{` With the chaplains by her side, Myrtle silently prayed to place her faith and trust in Jesus Christ’s finished work on the cross. I am so thankful for this new sister. Will you pray for her and all those who have recently made decisions for the Lord through this ministry?`}</p>{`
    `}<div parentName="div">{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/will-crowd.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div">{`WILL RECENTLY SHARED THE GOSPEL OF JESUS CHRIST in Porto, Portugal, and more than 480 people told us they made decisions for Jesus Christ.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{` Please also pray for my son Will’s upcoming Look Up Celebration on Australia’s Gold Coast, as well as for new believers here in the United Kingdom. `}<strong parentName="p">{`Your prayers are such an encouragement—and your support makes this Gospel work possible.`}</strong>{` Thank you for your heart to reach people all over the world with God’s truth.`}</p>
      </div>
      <div>{`
    `}<p parentName="div"><br parentName="p"></br>{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/pro/2020/09/FG-signature.jpg",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham`}<br parentName="p"></br>{`President & CEO`}</p>
      </div>
      <div {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
    `}</h4>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world.`}</p>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`GOSPEL TV SPOTS`}</span>{` | So many are living without the hope that comes from Jesus Christ. Your gift to `}<strong parentName="p">{`Gospel TV Spots`}</strong>{` will help air the Good News through TV ads all across America and offer a 24/7 prayer line for people who want to talk or pray with someone.`}</p>{`
    `}<p parentName="div"><span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM MINISTRY`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.`}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      