import React from 'react';
import { Block, Heading } from '@bgea-js/react-ui';

const customConfirmationPorto = () => (
  <div>
    <Heading>Obrigado!</Heading>
    <Block>
      <p>A sua candidatura está completa.</p>
    </Block>
  </div>
);

export default customConfirmationPorto;
