import React from 'react';

const donationHeaderPrayScriptureChildren = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <br />
      <p>
        We praise God for your heart to pray for your children. We pray that
        this guide encourages you to{' '}
        <em>
          "trust in the Lord with all your heart, and do not lean on your own
          understanding. In all your ways acknowledge him, and he will make
          straight your paths"
        </em>{' '}
        (Proverbs 3:5-6, ESV).
      </p>
      <p>
        <strong>
          Before you go, there are a few things we’d like you to know…
        </strong>
      </p>
      <p>
        God is using friends like you to reach people with His love in a variety
        of ways through the Billy Graham Evangelistic Association, including a
        24-hour prayer line, online Gospel resources, and Billy Graham Rapid
        Response Team (BG-RRT) chaplains, who comfort and pray with hurting
        people in the midst of crisis.
      </p>
      <p>
        This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <p>
        When you make a gift today, you can reach people across the globe with
        the message of God's love. In a time when so many are overcome with
        anger, fear, or hopelessness, the Good News of Jesus Christ is exactly
        what they need to hear.
      </p>
      <p>
        <strong>Will you help share it with them?</strong>
      </p>
      <blockquote>
        Your gift of $45 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderPrayScriptureChildren;
