import React from 'react';

const donationHeaderWordOfWisdom = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God that you want to grow in your relationship with Him, and
        we hope this resource strengthens your faith and encourages you to share
        Jesus with others.
      </p>
      <p>
        <strong>
          Before you go, there are a few things we’d like you to know…
        </strong>
      </p>
      <p>
        The Billy Graham Evangelistic Association (BGEA) is able offer Gospel
        resources like the one you’re receiving because of friends who
        generously make this ministry possible. The Lord is using the gifts and
        prayers of believers like you to reach people across the globe with His
        love—and there are so many more who need to hear the Good News.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote>
        Your gift of $50 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Jesus Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderWordOfWisdom;
