import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="text-center">
      <h5>From Mexico City to London to Rome: ‘One of the Busiest Seasons We’ve Ever Had’</h5>
      <p>“And as we look forward to 2024 it's going to be another jam-packed year, as we start in Mexico City and then go up 
the US-Mexico border. We call it the Frontera tour. And we're going to be taking the Gospel all year long to
 various cities that we've never preached in before.</p>
      <p>“Thank you for your prayers, and for your support.”</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      