import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "main-wrap"
    }}>{`
    `}<div parentName="div" {...{
        "className": "content-wrapper"
      }}>{`
        `}<div parentName="div" {...{
          "className": "margins"
        }}>{`
            `}<div parentName="div" {...{
            "className": "content-container english"
          }}>{`
            `}<h1 parentName="div" {...{
              "className": "top-title"
            }}><span parentName="h1">{`Lootuse Aeg`}</span></h1>{`
            `}<h1 parentName="div" {...{
              "className": "bottom-title"
            }}>{`koos Will Grahamiga`}</h1>{`
                `}<div parentName="div" {...{
              "className": "text"
            }}>{`
                    `}<p parentName="div">{`Festival “Lootuse aeg” toimub Tallinnas Tondiraba Jäähalli peaareenil laupäeval ja pühapäeval, 18.‒19. oktoobril 2025. Lootuse sõnumit jagab evangelist Will Graham ja toimub ka tasuta kontsert parimate kristlike muusikute osalusel. Festivali “Lootuse aeg” korraldamine toimub koostöös erinevate kirikute ja kogudustega üle kogu Eesti.
                    `}</p>{`
                    `}<div parentName="div" {...{
                "className": "event-date"
              }}>{`
                        `}<p parentName="div"><span parentName="p">{`18.-19.`}</span></p>{`
                        `}<p parentName="div">{`Oktoober`}</p>{`
                        `}<p parentName="div">{` `}</p>{`
                        `}<p parentName="div">{`2025`}</p>{`
                    `}</div>{`
                    `}<p parentName="div" {...{
                "className": "text-location"
              }}>{`Tondiraba Jäähalli peaareen, Tallinn`}</p>{`
                `}</div>{`
            `}</div>{`
            `}<div parentName="div" {...{
            "className": "feat-img",
            "id": "desktop"
          }}>{`
                `}<img parentName="div" {...{
              "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/10/will-graham.png",
              "alt": "Will Graham"
            }}></img>{`
            `}</div>{`
        `}</div>{`
    `}</div>{`
    `}<div parentName="div" {...{
        "className": "pattern-block",
        "id": "desktop"
      }}>{` `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      