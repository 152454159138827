import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
      <h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rrt"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>
      </h3>
      <h4 parentName="div">{`Overjoyed by Grace`}</h4>
      <p parentName="div">{`Our chaplains are still ministering in five states following the hurricanes, including hard-hit areas in North Carolina and Florida. One of our chaplains recently met Greg,* a Vietnam veteran who had been affected by Hurricane Milton. During a conversation, `}<strong parentName="p">{`he explained that he believed in some sort of God—but he’d had a hard life and didn’t know much about faith or salvation.`}</strong></p>
      <div parentName="div" {...{
        "className": "image-float-right"
      }}>
        <img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/dec/rrt-boone-prayer.jpg",
          "alt": ""
        }}></img>
        <figcaption parentName="div" {...{
          "className": "category"
        }}><strong parentName="figcaption">{`OUR CHAPLAINS`}</strong>{` offer a listening ear and prayer to residents during every deployment. 
`}</figcaption>
      </div>
      <p parentName="div">{`Our chaplains used “Steps to Peace With God” to explain the Gospel: `}<em parentName="p">{`“This is eternal life, that they may know You, the only true God, and Jesus Christ whom You have sent”`}</em>{` (John 17:3, NKJV). The Holy Spirit worked in Greg’s heart, and he began to understand the truth. “It’s so simple, [but] good works must be a part of it,” he said. When the chaplains responded that good works are the result of salvation—but never the cause of it—`}<strong parentName="p">{`Greg was overjoyed. He prayed to place his faith and trust in Jesus Christ.`}</strong>{` We give God all the glory for drawing this man—and so many others—to Himself through these deployments.`}</p>
      <h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "tv-spots"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`GOSPEL TV SPOTS`}</span>
      </h3>
      <h4 parentName="div">{`New Gospel Spot Airs Across THE COUNTRY`}</h4>
      <p parentName="div">{`I recently recorded a new Gospel TV spot, and it aired on networks across the U.S., including ABC during `}<em parentName="p">{`World News Tonight`}</em>{` and `}<em parentName="p">{`Good Morning America`}</em>{`. Whenever they run, our Billy Graham 24/7 Prayer Line is flooded with calls from people wanting someone to pray with and many who made decisions for Jesus Christ. We praise God for each life changed for eternity. `}</p>
      <p parentName="div">{`Michael called from the nursing home where he lives. He had seen the TV spot, and he wanted to know more. Our representative listened compassionately as Michael shared more about his life. He cannot walk and expressed his desire to ask God for healing. When their conversation turned more specifically to spiritual matters, he shared that he is Jewish, believes in God, and prays—but he has no idea where he will spend eternity. Using Scripture, our team member shared Jesus Christ’s promise to all who place their faith in Him: `}<em parentName="p">{`“My sheep hear My voice, and I know them, and they follow Me. And I give them eternal life, and they shall never perish”`}</em>{` (John 10:27–28, NKJV).`}</p>
      <p parentName="div">{`The Holy Spirit worked in Michael’s heart, and he prayed to surrender his life to the Lord in repentance and faith. After he prayed, he told our team member that his decision to accept Jesus had left him feeling amazed. The two prayed together for Michael’s physical healing and his new faith. Will you pray for him too? Please pray that God will strengthen him during this difficult time and help him become a faithful witness for Christ.`}</p>
      <h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "celebration"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`WILL GRAHAM CELEBRATION`}</span>
      </h3>
      <h4 parentName="div">{`Thousands hear the Gospel in Canada`}</h4>
      <p parentName="div">{`My son Will recently preached the Good News in `}<strong parentName="p">{`Grande Prairie, Canada, a small city in Alberta.`}</strong>{` Over the course of two days, more than 9,000 people filled Bonnetts Energy Centre, where they heard a clear presentation of the Good News that Will shared from the gospels. `}<strong parentName="p">{`We are so grateful that hundreds turned to Him in repentance and faith through the Celebration.`}</strong></p>
      <div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/dec/grand-pr-counsel.jpg",
          "alt": ""
        }}></img>{`
    `}</div>{`
    `}<p parentName="div">{`Rita* was in the crowd with her aging parents. She was a believer, but her mom and dad were never open to the Gospel. “I’ve been praying for my parents every day for the better part of 30 years,” she told one of our prayer counselors. When Will gave the invitation, Rita watched her mom and dad walk forward to place their faith in Jesus Christ.`}</p>{`
    `}<p parentName="div">{`“It was a pretty moving time,” she shared. “It’s been scary thinking I will lose my parents in the greatest sense of the word. Now I know I won’t.” We give God all the glory! Will you pray for each of these brothers and sisters? Ask the Lord to strengthen them and help them reach even more people in Alberta with His truth.`}</p>
      <div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/dec/grand-pr-crowd.jpg",
          "alt": ""
        }}></img>{`
    `}<figcaption parentName="div" {...{
          "className": "category"
        }}><strong parentName="figcaption">{`WILL GRAHAM PREACHED TO THOUSANDS`}</strong>{` in Grande Prairie, Alberta, a city of more than 60,000 people.`}</figcaption>
      </div>
      <h4 parentName="div">{`Celebrating Our Savior’s Birth`}</h4>
      <p parentName="div">{`In a few weeks, Christians around the world will celebrate the glorious truth that `}<em parentName="p">{`“unto us a Child is born, unto us a Son is given; and the government will be upon His shoulder. And His name will be called Wonderful, Counselor, Mighty God, Everlasting Father, Prince of Peace”`}</em>{` (Isaiah 9:6, NKJV). I’m praying that you and your loved ones are blessed by His presence this Christmas season.`}</p>
      <p parentName="div">{`Thank you for your commitment to evangelism. `}<strong parentName="p">{`Friends like you are making it possible to proclaim the Good News of Jesus Christ all across the globe, and I praise the Lord for you.`}</strong></p>
      <p parentName="div">{`May God richly bless you,`}</p>
      <img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
    `}<p parentName="div">{`Franklin Graham`}</p>{`
    `}<p parentName="div">{`President & CEO`}</p>{`
    `}<p parentName="div">{`*Names changed to protect privacy`}</p>{`
         `}<hr parentName="div"></hr>{`
    `}<div parentName="div" {...{
        "className": "give-background",
        "id": "december-give"
      }}>{`
        `}<h4 parentName="div" {...{
          "className": "cta projects"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
        `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
        GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
        GOSPEL.
        `}</h4>{`
        `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world.
        `}</p>{`
        `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the upcoming Franklin Graham Festival in Can Tho, Vietnam.
        `}</p>{`
         `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
        `}</p>{`
        `}<p parentName="div">{`
        `}<span parentName="p" {...{
            "className": "options"
          }}>{`GOSPEL TV SPOTS`}</span>{` | So many are living without the hope that comes from Jesus Christ. Your gift to `}<strong parentName="p">{`Gospel TV Spots`}</strong>{` will help air the Good News through TV ads all across America and offer a 24/7 prayer line for people who want to talk or pray with someone.
        `}</p>{`
    `}</div>{`
        
`}</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      