import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="columns is-hidden featured-pages downloads">
    <div className="column"><h3 id="white-button">Resource Name</h3><a>DOWNLOAD</a></div>
    <div className="column"><h3 id="orange-button">Resource Name</h3><a>DOWNLOAD</a></div>
    <div className="column"><h3 id="blue-button">Resource Name</h3><a>DOWNLOAD</a></div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      