import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationHymns = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
  firstName
}) => (
  <div>
    <h1 className="conf-title"> Great News {firstName}! Your copy of <em>Hymns for the Soul</em> should arrive 
    in 2 to 4 weeks.</h1>
    <Block>We praise the Lord for your heart for worship and we hope 
      this classic resource will encourage you to share your faith as you 
      “<em>sing of the steadfast love of the Lord forever</em>” (Psalm 89:1, ESV). 
    </Block>
    <Block>
    We are grateful for your financial support. Your transaction will be 
    processed shortly and will appear on your next statement. An acknowledgment
    will be emailed to you.
    </Block>
    <Block>
      The confirmation number for your{' '}
      {showRecurrenceText && <strong>{recurrenceText}</strong>} donation is{' '}
      <strong>{transactionId}</strong> for the amount of $
      {total.toFixed(2) ?? 'NEED AMOUNT'}.
    </Block>
    <Block>
      If you have any questions, call us at 1-877-247-2426. Please have your
      confirmation number ready and indicate that you are contacting us about a
      donation made through the Billy Graham Evangelistic Association website.
    </Block>
    <Block>
      To keep you updated on the ministry, we would like to send you our prayer
      letter, <i>From the Desk of Franklin Graham</i>, featuring biblical truths
      about current events, news of his travels and updates on the work of BGEA
      with thousands of people around the world. You can opt-out from receiving
      these emails anytime.
    </Block>
  </div>
);

export default customConfirmationHymns;