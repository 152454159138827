import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationVnResponse = () => (
  <div>
    <Block>
      <p>Cám ơn! Mẫu của bạn đã được gởi thành công.</p>
    </Block>
  </div>
);

export default customConfirmationVnResponse;
