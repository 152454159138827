import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
        `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGnPqou4wn/xAAZEAADAAMAAAAAAAAAAAAAAAABAgMAERL/2gAIAQEAAQUCs/OF6LRTtaSFMWPIA0P/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAQEAAwAAAAAAAAAAAAAAEQAhEDJx/9oACAEBAAY/AhLtk2wvvH//xAAbEAACAgMBAAAAAAAAAAAAAAAAARFRITFBgf/aAAgBAQABPyFth0V3Jz1wirCLJp2JZKnoxB//2gAMAwEAAgADAAAAEPMP/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBB/9oACAEDAQE/EEbN/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qh//EABsQAQEBAAIDAAAAAAAAAAAAAAERACGxQWGh/9oACAEBAAE/EHYPiRfmVQQK4h7HGM0FxAAPA6xwcJAA0INnqb//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "crying man",
              "title": "crying man",
              "src": "/static/5b45d3a2e47db6ad380e87b4a77a4b1b/6a068/crying-man.jpg",
              "srcSet": ["/static/5b45d3a2e47db6ad380e87b4a77a4b1b/f93b5/crying-man.jpg 300w", "/static/5b45d3a2e47db6ad380e87b4a77a4b1b/b4294/crying-man.jpg 600w", "/static/5b45d3a2e47db6ad380e87b4a77a4b1b/6a068/crying-man.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
        `}<figcaption parentName="div">{`EVERY PERSON who prayed to receive Christ was given a Bible and discipleship materials to help them grow closer to the Lord.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{`
         At our third stop in `}<strong parentName="p">{`Laredo`}</strong>{`, I was deeply touched
        watching 840 people—including entire families—make
        life-changing decisions for Jesus Christ together. Ines*
        brought her three grandchildren to the event so they
        could hear the Gospel. She is a strong Christian—but
        her sons are not, and they have not raised their children
        to know the Lord. `}<strong parentName="p">{`Ines’ prayers were answered when her
        15-year-old granddaughter and her two grandsons, ages
        8 and 12, all decided to place their faith in Jesus Christ`}</strong>{`. Tears streamed down her granddaughter’s
        face as she talked to one of our prayer counselors and accepted a brand-new Bible. 
    `}</p>{`
    `}<p parentName="div">{`
         At the fourth stop in `}<strong parentName="p">{`Eagle Pass`}</strong>{`, 3,100 people attended the tour—that represents over 10% of
        the town’s population! One official told us he had never seen a crowd that large in Eagle Pass and
        more than 360 people made decisions for the Lord. 
    `}</p>{`
    `}<p parentName="div">{`
         `}<strong parentName="p">{`Del Rio`}</strong>{` is another small, rural Texas community—but 4,600 people came to our event there, and
        hundreds of them told us they made decisions for the Lord, including Maria.* Rosalind, one of our
        prayer volunteers, noticed her crying as she hugged her teenage son Rafael* as I gave the invitation. `}<strong parentName="p">{`“I
        need Jesus in my heart,” Maria told her`}</strong>{`. 
        `}</p><div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
            `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFBAb/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGjldOHohAn/8QAHBAAAgICAwAAAAAAAAAAAAAAAQMAAgQiERIh/9oACAEBAAEFAnN6Grb2g9GQNjrEHlP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAADAAEFAAAAAAAAAAAAAAAAARECEBIxQXH/2gAIAQEABj8ClgmnPe9KbceDE//EABwQAQACAwADAAAAAAAAAAAAAAEAESExUUFhcf/aAAgBAQABPyGtq6WYORl8BHQ9gnv3cSsRv9jMeT//2gAMAwEAAgADAAAAEAQP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREQMf/aAAgBAwEBPxCMIdz/xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/ECl2P//EABsQAQEBAAIDAAAAAAAAAAAAAAERAEFRMXGR/9oACAEBAAE/EJ9Q3gGWWs7E+jorwuigMwi2ddaJFAxq+yu85zv/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "counseler 2",
              "title": "counseler 2",
              "src": "/static/31a3c941c5fb590f34b106250a21140e/6a068/counseler-2.jpg",
              "srcSet": ["/static/31a3c941c5fb590f34b106250a21140e/f93b5/counseler-2.jpg 300w", "/static/31a3c941c5fb590f34b106250a21140e/b4294/counseler-2.jpg 600w", "/static/31a3c941c5fb590f34b106250a21140e/6a068/counseler-2.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
            `}<figcaption parentName="div">{`BEFORE THE TOUR BEGAN, thousands of believers took the Christian Life and Witness Course to learn how to grow in their faith and share it with others. Many of them served as prayer counselors at the stop in their city.`}</figcaption>{`
        `}</div>{`
         After she prayed to surrender her life to the Lord, she thanked
        Rafael—who is already a believer—for asking her to attend. Maria had brought four friends with her,
        and we praise God that they also prayed to repent of their sin and put their faith in Jesus Christ.
        `}<strong parentName="div">{`“I didn’t know Jesus loved me so much,”`}</strong>{` one said. We gave all five of them brand-new Bibles.
    `}<p parentName="div"></p>{`
    `}<p parentName="div">{`
         Next I preached in `}<strong parentName="p">{`Presidio`}</strong>{`, the smallest and most remote town on the tour, where 1,250
        people came out to hear a message from God’s Word. I had never heard of the town until I rode
        my motorcycle through it several years ago. When we
        began planning for the tour, I just knew we had to
        bring it here, and I’m so glad we did! One young
        man said the stop was an answer to prayer. “It
        was surprising something like this came to this
        town,” he told us. Praise God that over 130
        people surrendered their lives to Jesus Christ! 
    `}</p>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAF8Yr0VJgX/AP/EABwQAAICAgMAAAAAAAAAAAAAAAABAgMREhMiMv/aAAgBAQABBQK7uuOo0qJTePIm5H//xAAXEQADAQAAAAAAAAAAAAAAAAABAhEA/9oACAEDAQE/AWaC4JRv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAECIf/aAAgBAgEBPwFToz//xAAbEAACAQUAAAAAAAAAAAAAAAAAAUEQERIhMf/aAAgBAQAGPwJZO5pEic06f//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExQf/aAAgBAQABPyFAcm17FG0T0vkqrOIpFeMya2nuwS1Zk//aAAwDAQACAAMAAAAQaB//xAAYEQEBAAMAAAAAAAAAAAAAAAABACGh8P/aAAgBAwEBPxBFBkiBe1f/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QNBssUv/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQVFhkf/aAAgBAQABPxA7FDqgPPEBkigUPaIw7Bxv7B/otI0DCwZua6slMB6Hmp//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "brownsville tx crowd",
            "title": "brownsville tx crowd",
            "src": "/static/06e4ab739bf6700ace52030be90d4ed4/6a068/brownsville-tx-crowd.jpg",
            "srcSet": ["/static/06e4ab739bf6700ace52030be90d4ed4/f93b5/brownsville-tx-crowd.jpg 300w", "/static/06e4ab739bf6700ace52030be90d4ed4/b4294/brownsville-tx-crowd.jpg 600w", "/static/06e4ab739bf6700ace52030be90d4ed4/6a068/brownsville-tx-crowd.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`MARCOS WITT, TAYA, and DENNIS AGAJANIAN led the crowd in worship during every event, and Jeremy Camp joined us for three of the stops.`}</figcaption>{`
    `}<p parentName="div">{`
         We wrapped up our Texas stops in `}<strong parentName="p">{`El Paso`}</strong>{`. The Holy Spirit worked in hundreds of hearts in this
        sprawling city. Kim* was changed by God’s promise `}<em parentName="p">{`“that a person is not justified by works of the
        law but through faith in Jesus Christ”`}</em>{` (Galatians 2:16, ESV). `}<strong parentName="p">{`The 18-year-old told us that she used
        to think that God only loved “good people.”`}</strong>{` “Recently my life has been going downhill, and I feel
        that I reached the peak of my weakness,” she said. But when she heard the Good News that night,
        something changed. Kim prayed to receive Jesus Christ in repentance and faith. `}<strong parentName="p">{`“Now I feel that
        God loves me just the way I am,”`}</strong>{` she said before explaining that she wants a fresh start—and plans
        to start attending a local church.
    `}</p>{`
    `}<p parentName="div">{`
         In `}<strong parentName="p">{`Tucson, Arizona`}</strong>{`, 11,600 people packed into Kino Veterans Memorial Stadium. I preached
        from Luke 17, and God drew more than 700 men, women, and children to faith in Jesus Christ.
        While we were in the state’s second-largest city, `}<strong parentName="p">{`we also held a special appreciation dinner for
        Border Patrol agents and other law enforcement`}</strong>{`. Officers all over our country are under so much
        pressure—and many in this city have an even harder task as they serve along the border. It was a
        blessing to honor these men and women—and their spouses—and give them dedicated time for
        fellowship and encouragement from the Scriptures.
    `}</p>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFEc1J5TD//xAAZEAEBAQADAAAAAAAAAAAAAAABABICESH/2gAIAQEAAQUCOFhsXtkQDr//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAAAEDEhQf/aAAgBAQAGPwLqi0h//8QAGRABAQEBAQEAAAAAAAAAAAAAAREAIUGB/9oACAEBAAE/IezQ5g9hnr1mp2fMpFJhxn//2gAMAwEAAgADAAAAEITv/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EIiI/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/ELqv/8QAGxABAQEAAwEBAAAAAAAAAAAAAREAITFBUXH/2gAIAQEAAT8QrQDqRx2V+ENJHV7VuhQUHGUXHlmLDifW7//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mccallen crowd",
            "title": "mccallen crowd",
            "src": "/static/ee3e9aabe61862ec7f1aa511e8f66ecb/6a068/mccallen-crowd.jpg",
            "srcSet": ["/static/ee3e9aabe61862ec7f1aa511e8f66ecb/f93b5/mccallen-crowd.jpg 300w", "/static/ee3e9aabe61862ec7f1aa511e8f66ecb/b4294/mccallen-crowd.jpg 600w", "/static/ee3e9aabe61862ec7f1aa511e8f66ecb/6a068/mccallen-crowd.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`FRANKLIN PREACHES—AND DAVID RUIZ TRANSLATES—at the second stop on the Frontera Tour in McAllen, Texas.`}</figcaption>{`
    `}<p parentName="div">{`
         `}<strong parentName="p">{`Yuma`}</strong>{` marked our second stop in Arizona and the next-to-last of the entire tour. Toward the
        end of the evening, we heard from a prayer counselor who connected with `}<strong parentName="p">{`Alvaro,* who had come
        across the border from Mexico for the night to attend`}</strong>{`. Alvaro’s girlfriend—already a believer—had
        invited him. When I explained how to embrace salvation in Jesus Christ, the young man eagerly
        prayed out loud to receive Him in repentance and faith. Our prayer team counselor prayed for
        Alvaro, who he described as `}<strong parentName="p">{`“awed and overcome and happy!”`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
         Another Yuma volunteer shared her story with us. She had been praying for the opportunity
        to reach others with God’s love during the tour. `}<strong parentName="p">{`The Lord answered her prayers when she was
        called on to talk to three young women`}</strong>{` who had just prayed to receive Jesus Christ. They told her
        `}<strong parentName="p">{`they had been involved in satanism—and they had felt like they were under attack`}</strong>{`. She explained
        Scripture’s promise that `}<em parentName="p">{`“you who once were far off have been brought near by the blood of Christ”`}</em>{`
        (Ephesians 2:13, ESV) and took them over to a group of local church leaders who were able to pray
        with them and will help guide them in their new faith. I’m thankful to God for working in the hearts
        of more than 370 people who made decisions for Christ that evening.
    `}</p>{`
    `}<p parentName="div">{`
         Our final stop was `}<strong parentName="p">{`Chula Vista, California`}</strong>{`. More than 5,700 people packed into the city’s North
        Island Credit Union Amphitheatre, and `}<strong parentName="p">{`I told them about the value of a soul—and the great price
        Jesus Christ paid for our redemption`}</strong>{`. At the invitation, over 400 people came forward to place their
        faith and trust in Him. We thank God for each person who received salvation in Chula Vista—and on
        the tour. As we prepare to celebrate our Savior’s glorious victory over death and sin in the coming
        days, will you pray for these new brothers and sisters?
    `}</p>
      </div>
      <h3 {...{
        "className": "specialstyling mt-6",
        "id": "evangelistic-crusades"
      }}><span parentName="h3" {...{
          "className": "category"
        }}>{`ON THE GOD LOVES YOU TOUR`}</span></h3>
      <h4>{`Testimonies from those who worked and prayed before the tour—and will now disciple new believers`}</h4>
      <div {...{
        "className": "testimonies"
      }}>{`
    `}<div parentName="div" {...{
          "className": "text-callout"
        }}>{`
        `}<div parentName="div" {...{
            "className": "scripture"
          }}>{`
          `}<div parentName="div" {...{
              "className": "quote"
            }}>{`
            `}<strong parentName="div">{`
                `}<div parentName="strong" {...{
                  "className": "small-quote-1"
                }}>{`“`}</div>{`We’ve been praying for revival in our community—and in America—for 18 years. This has
                been such an answer to prayer, especially to watch pastors and leaders come together to
                share the Gospel. The presence of the Holy Spirit was palpable during the event and it was
                clear God was moving.`}<div parentName="strong" {...{
                  "className": "small-quote-2"
                }}>{`”`}</div>{`
            `}</strong>{`
          `}</div>{`
        `}</div>{`
        `}<p parentName="div" {...{
            "className": "verse"
          }}>{`—PRAYER GROUP LEADER, LAREDO, TX`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
          "className": "text-callout"
        }}>{`
        `}<div parentName="div" {...{
            "className": "scripture"
          }}>{`
          `}<div parentName="div" {...{
              "className": "quote"
            }}>{`
            `}<strong parentName="div">{`
                `}<div parentName="strong" {...{
                  "className": "small-quote-1"
                }}>{`“`}</div>{`We are overlooked, we are underserved, we are underfunded, so a visit of this caliber is
                vital ... to keep doing hard work. … It was a big encouragement to the local community, to
                our local faith community. Thank you!`}<div parentName="strong" {...{
                  "className": "small-quote-2"
                }}>{`”`}</div>{`
            `}</strong>{`
          `}</div>{`
        `}</div>{`
        `}<p parentName="div" {...{
            "className": "verse"
          }}>{`—CHURCH LEADER, McALLEN, TX`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
          "className": "text-callout"
        }}>{`
        `}<div parentName="div" {...{
            "className": "scripture"
          }}>{`
          `}<div parentName="div" {...{
              "className": "quote"
            }}>{`
            `}<strong parentName="div">{`
                `}<div parentName="strong" {...{
                  "className": "small-quote-1"
                }}>{`“`}</div>{`Reaching Tucson by myself is impossible. Doing it even with my church is technically
                impossible, so having Franklin Graham come and preach the Gospel directly leads people
                to start searching for churches and give their lives over to the Lord in a massive way. As a
                pastor, bringing my church to minister, they are encouraged seeing people saved and seeing
                people follow the Lord and now we are super excited to follow up.`}<div parentName="strong" {...{
                  "className": "small-quote-2"
                }}>{`”`}</div>{`
            `}</strong>{`
          `}</div>{`
        `}</div>{`
        `}<p parentName="div" {...{
            "className": "verse"
          }}>{`—PASTOR, TUCSON, AZ`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
          "className": "text-callout"
        }}>{`
        `}<div parentName="div" {...{
            "className": "scripture"
          }}>{`
          `}<div parentName="div" {...{
              "className": "quote"
            }}>{`
            `}<strong parentName="div">{`
                `}<div parentName="strong" {...{
                  "className": "small-quote-1"
                }}>{`“`}</div>{`Lord, the world knows Eagle Pass because of the border. Please Lord, let the world know
                this town stands for Jesus.`}<div parentName="strong" {...{
                  "className": "small-quote-2"
                }}>{`”`}</div>{`
            `}</strong>{`
          `}</div>{`
        `}</div>{`
        `}<p parentName="div" {...{
            "className": "verse"
          }}>{`—CHURCH LEADER, EAGLE PASS, TX`}</p>{`
      `}</div>{`
      `}<div parentName="div" {...{
          "className": "text-callout"
        }}>{`
        `}<div parentName="div" {...{
            "className": "scripture"
          }}>{`
          `}<div parentName="div" {...{
              "className": "quote"
            }}>{`
            `}<strong parentName="div">{`
                `}<div parentName="strong" {...{
                  "className": "small-quote-1"
                }}>{`“`}</div>{`We’ve been pastors in Del Rio for 12 years and we’ve never seen an event like this, where
                pastors come together and churches come together. Not only that, but people who have
                never been exposed to the Gospel, they come to an event like this and just have an encounter
                with Christ. It was phenomenal to see so many people stand up. I can’t wait to see the …
                harvest of the souls that just came to Christ tonight.`}<div parentName="strong" {...{
                  "className": "small-quote-2"
                }}>{`”`}</div>{`
            `}</strong>{`
          `}</div>{`
        `}</div>{`
        `}<p parentName="div" {...{
            "className": "verse"
          }}>{`—CHURCH LEADER, DEL RIO, TX`}</p>{`
      `}</div>{`
      `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAWbISHQMxv/EABsQAQEAAgMBAAAAAAAAAAAAAAIBAAMEERIx/9oACAEBAAEFAluiwchLLvsr+650AvZ//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAaEAEBAAIDAAAAAAAAAAAAAAABABBhESIy/9oACAEBAAY/AjtaMG4R93Lf/8QAHBABAAICAwEAAAAAAAAAAAAAAQAhETFRYXGR/9oACAEBAAE/IRUo6iLwbCJByekprWlkR5t8xMSNz//aAAwDAQACAAMAAAAQbx//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQMBAT8QDC6u/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EBjYX//EAB4QAQACAgEFAAAAAAAAAAAAAAERIQAxQVFhcZHB/9oACAEBAAE/EGnITFgmfl5zXYrrUW83rKqhi2++ScQC7jzHvDSWBKSTo71jjjquf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "prayer",
            "title": "prayer",
            "src": "/static/baf55e10fa5440d7736a472556f9e02f/6a068/prayer.jpg",
            "srcSet": ["/static/baf55e10fa5440d7736a472556f9e02f/f93b5/prayer.jpg 300w", "/static/baf55e10fa5440d7736a472556f9e02f/b4294/prayer.jpg 600w", "/static/baf55e10fa5440d7736a472556f9e02f/6a068/prayer.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </div>
      <div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "evangelistic-crusades"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`EVANGELISTIC CRUSADES`}</span></h3>{`
    `}<h4 parentName="div">{`UPCOMING GOSPEL MINISTRY IN EUROPE`}</h4>{`
    `}<p parentName="div">{`
         As you read this, I’m preparing to preach in `}<strong parentName="p">{`Kraków, Poland`}</strong>{`, which is often called the country’s
        City of Churches. There are well over a hundred church buildings and cathedrals packed into
        Kraków—but so many of them sit empty on Sunday mornings. The vast majority of residents claim
        Christianity, but they don’t have a genuine, life-changing relationship with the Savior. Tens of thousands
        of Ukrainian refugees have fled to Kraków in the past couple of years and now call the city home.
        The spiritual need in this city is deep. `}<strong parentName="p">{`Will you pray that many people will come to the Festival, listen
        to the Gospel with an open heart, and respond by placing their trust in Jesus Christ?`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
         My son Will’s Celebration in Porto, Portugal, is also just a few weeks away. Pray that God will
        call many `}<em parentName="p">{`“into the fellowship of his Son, Jesus Christ our Lord”`}</em>{` (1 Corinthians 1:9, ESV).
    `}</p>
      </div>
      <br></br>
      <div>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham`}</p>{`
    `}<p parentName="div">{`President & CEO`}</p>{`
    `}<br parentName="div"></br>{`
    `}<p parentName="div">{`
        P.S. A couple of months ago, I had the privilege of recording a special Gospel message in Jerusalem.
        This TV program—which also features moving music from my friend Michael W. Smith—will air at noon
        EDT on Easter on Fox News, 2 p.m. EDT on TBN, and throughout the afternoon and evening on select
        local channels; it will be available to watch online through April 28 as well. Will you plan to watch with
        your family and friends—especially those who don’t have a relationship with Jesus Christ? You can
        find local listings or see a trailer online at `}<a parentName="p" {...{
            "href": "https://billygraham.org/Israel?utm_source=&utm_medium=link&utm_campaign=direct+mail+april+prayer+landing+page&utm_content=BL244L0000&SOURCE=BL244L0000",
            "target": "_blank"
          }}><strong parentName="a">{`BillyGraham.org/Israel`}</strong></a>{`.
    `}</p>{`
    `}<p parentName="div">{`*Names changed to protect privacy`}</p>
      </div>
      <hr></hr>
      <div {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`GOD LOVES YOU TOURS`}</span>{` | Through your support, the `}<strong parentName="p">{`God Loves You Tours`}</strong>{` can help reach people across our nation with the Good News of Jesus Christ. These powerful, one-night events feature a clear presentation of the Gospel from Franklin Graham. 
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the upcoming Franklin
    Graham Festival in Kraków, Poland, and Will Graham Celebration in Porto, Portugal.
    `}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      