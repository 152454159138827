import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import customConfirmationUM from "../../../../src/content/urgent-mission/customConfirmation";
import "../../../../src/content/urgent-mission/urgent-mission.scss";
import TextPart from "../../../../src/content/urgent-mission/text.mdx";
import * as React from 'react';
export default {
  SingleDonationForm,
  customConfirmationUM,
  TextPart,
  React
};