import React from 'react';
import '../../styles/global.module.scss';

const customModalTextPFI = () => (
  <>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      <em>Pray for Israel</em> is on its way to your inbox.
    </h2>
    <div>
      <p>
        We appreciate your heart for prayer, and we hope this resource helps you
        <em>
          “devote yourselves to prayer, keeping alert in it with an attitude of
          thanksgiving”
        </em>{' '}
        (Colossians 4:2, NASB).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        God is reaching people with His love in a variety of ways through the
        Billy Graham Evangelistic Association, including a 24-hour prayer line,
        online Gospel resources, and Billy Graham Rapid Response Team (BG-RRT)
        chaplains, who comfort and pray with hurting people in the midst of
        crisis. You can learn more about these powerful ministries{' '}
        <a href="https://billygraham.org/" target="_blank" rel="noreferrer">
          HERE
        </a>
        . We are grateful for you!
      </p>
    </div>
  </>
);

export default customModalTextPFI;
