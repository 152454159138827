import "../../../../src/content/japan-celebration/japan.scss";
import { GetInvolved } from '@bgea-js/form-templates';
import privacyPolicyCopyJp from "../../../../src/content/japan-celebration/privacyPolicyJp";
import CustomConfirmationJp from "../../../../src/content/japan-celebration/customConfirmationJP";
import * as React from 'react';
export default {
  GetInvolved,
  privacyPolicyCopyJp,
  CustomConfirmationJp,
  React
};