import React from 'react';

const donationHeaderEasterDevo = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your free Easter devotional is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart to grow closer to Him this Easter, and we hope this devotional will encourage you with the Good News that &#8220;<em>God raised the Lord and will also raise us up by his power</em>&#8221; (1 Corinthians 6:14, ESV).
      </p>
      <p>Before you go, there are a few things we’d like you to know&nbsp;…</p>
      <p>
        The Billy Graham Evangelistic Association is able to offer materials like this because of friends like you. The Lord is using the gifts and prayers of believers like you to reach people across the globe with His love—and there are so many more who need to hear the Good News.
      </p>
      <p>
        Will you give them the opportunity to hear it?
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift today will help share the Gospel with people around the world who are far from God and equip them with powerful Biblical resources to become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderEasterDevo;
