import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "columns top-header m-0"
    }}>{`
    `}<div parentName="div" {...{
        "className": "top-bar"
      }}>{`
        `}<div parentName="div" {...{
          "className": "language-bar"
        }}>{`
            `}<a parentName="div" {...{
            "href": "/lootuse-aeg"
          }}>{`
                `}<img parentName="a" {...{
              "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/may/translate-icon.svg",
              "alt": "language icon"
            }}></img>{`  Eesti Keel `}</a>{`
                `}<a parentName="div" {...{
            "href": "/lootuse-aeg-en"
          }}>{`| English`}</a>{`
        `}</div>{`
    `}</div>{`
    `}<img parentName="div" {...{
        "src": "/e91d61f86bd9b93d6ad41ca2c0df5112/logo-tallinn-estonia.svg",
        "alt": "logo",
        "id": "header-logo"
      }}></img>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      