import React from 'react';

const customConfirmationDO = ({ firstName }) => (
  <div>
    <h2>
      Great news {firstName}! Your 6 Free Decision Magazine subscription will
      begin delivery soon.
    </h2>
    <p>
      Thank you for your passion to stand strong for Biblical truth in an
      increasingly secular culture. We hope the articles in these issues will
      encourage you to stand strong in a culture hostile to God’s Word.
    </p>
  </div>
);

export default customConfirmationDO;
