import React from 'react';
import { Block, Heading } from '@bgea-js/react-ui';

const privacyPolicyCopyJp = () => (
  <div>
    <Heading subtitle textSize={4}>
      情報提供の同意
    </Heading>
    <Block textSize={6}>
      登録いただいた個人情報（宗教に関する意見を含む）は西日本ウィルグラハム・セレブレーションやビリーグラハム伝道協会（BGEA）の主催するミニストリーおよび活動に関しての情報を提供するため、またBGEAとの協力関係を管理するために利用いたします。上記目的のために、アメリカ合衆国のBGEA及び・又は情報収集された国（及び該当国内における協力団体）において提供いただいた個人情報を保管いたします。プライバシー保護の範囲の設定及びBGEA
      のプライバシーポリシー（個人情報保護方針）は、
      <a href="https://billygraham.org/privacy-policy/">
        BillyGraham.org/privacy
      </a>
      . のページよりご確認ください。
    </Block>
  </div>
);

export default privacyPolicyCopyJp;
