import React from 'react';

const customConfirmationCross = () => (
  <div>
    <h2
      style={{
        textAlign: 'center',
      }}
    >
      Thank You!
    </h2>
    <p>
      We praise God for your decision to follow Jesus Christ as your Lord and
      Savior. The materials you requested will be shipped soon, and we pray that
      God will use them to help you grow in your faith.
    </p>
  </div>
);

export default customConfirmationCross;
