import './biblical-guidance.scss';

const BiblicalGuidenceCustomMarkup = {
  markup: `<p className="p-content">
  We hope this resource helps you and your family grow closer to God who is “<em>our refuge and strength, a very present help in trouble</em>” (Psalm 46:1-3, NKJV).
  </p>
  <p style="margin-bottom:3rem;">
  One per Household - Shipping to US only
  </p>
  `,
};

export default BiblicalGuidenceCustomMarkup;
