import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "god-loves-you-tour"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`GOD LOVES YOU TOUR`}</span></h3>{`
    `}<h4 parentName="div">{`Sharing the Gospel on the Border`}</h4>{`
    `}<p parentName="div">{`At the end of February, the `}<strong parentName="p">{`God Loves You Frontera Tour will kick off in Texas.`}</strong>{` The 10 communities we are going to along the border are struggling—from poverty and food insecurity in Texas to addiction and homelessness in California—and I’m eager to tell residents there about `}<em parentName="p">{`“the grace of the Lord Jesus Christ and the love of God”`}</em>{` (2 Corinthians 13:14, ESV).`}</p>{`
    `}<p parentName="div"><strong parentName="p">{`Since November, over 2,000 Christians have gone through our Christian Life and Witness Course training to grow in their faith and learn to boldly share it with others.`}</strong>{` Will you pray for these believers as they invite their lost loved ones to the tour? Also pray for the leaders who have worked so hard to mobilize their churches and communities for this Gospel outreach. Many of the pastors who are participating in the tour work another job to make ends meet, on top of leading their churches—supporting their families and serving the body of Christ.`}</p>
      </div>
      <div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "christmas-at-the-library"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`CHRISTMAS AT THE LIBRARY`}</span></h3>{`
    `}<h4 parentName="div">{`Celebrating the Savior of the World`}</h4>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
    `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABS2fDVQoL/8QAGhABAAIDAQAAAAAAAAAAAAAAAQACAxMhMf/aAAgBAQABBQIZbI2m6VBfZQ5//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAES/9oACAEDAQE/AWY//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAESIf/aAAgBAgEBPwHVpf/EABkQAQACAwAAAAAAAAAAAAAAAAABERAhUf/aAAgBAQAGPwK3G0qx/8QAHBABAAICAwEAAAAAAAAAAAAAAQARIUExUWGB/9oACAEBAAE/IXqtEfNScMc+Ma0e8MQQLPs3VaZ//9oADAMBAAIAAwAAABAk/wD/xAAXEQEAAwAAAAAAAAAAAAAAAAAAASEx/9oACAEDAQE/EK1A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAgEBPxA6BkXL/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITGRQVFx/9oACAEBAAE/EDJQOS9r5LS84TLfcLCmuLTcJlyxTb1MVnB1hNZeAOcdT//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Visitors line along fence to observe the Live Nativity on their way into the Library.",
              "title": "Visitors line along fence to observe the Live Nativity on their way into the Library.",
              "src": "/static/625533960fdc9a2c22e6ee327949a55a/6a068/catl_nativity_231208_3716.jpg",
              "srcSet": ["/static/625533960fdc9a2c22e6ee327949a55a/f93b5/catl_nativity_231208_3716.jpg 300w", "/static/625533960fdc9a2c22e6ee327949a55a/b4294/catl_nativity_231208_3716.jpg 600w", "/static/625533960fdc9a2c22e6ee327949a55a/6a068/catl_nativity_231208_3716.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div" {...{
            "className": "category"
          }}>{`FAMILIES ACROSS THE REGION have made `}<em parentName="figcaption">{`Christmas at the Library`}</em>{` a yearly tradition as they celebrate the birth of Jesus Christ.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{`As I write, we are in the final days of `}<em parentName="p">{`Christmas at the Library`}</em>{`, our annual celebration of Jesus Christ’s birth. Thousands of guests enjoyed the festivities—and heard a clear presentation of the Gospel. Drew* was among them. The 48-year-old from Pennsylvania wasn’t really sure how he ended up there. He had a great deal of respect for my father, and he decided to visit on a whim. He had also felt convicted for some time after he heard one of my messages on YouTube. Drew was living a homosexual lifestyle—and the burden of guilt was weighing heavily on him. As soon as he finished `}<em parentName="p">{`The Journey of Faith`}</em>{` tour, he found one of our volunteers and said he was ready to receive Jesus Christ in repentance and faith. She connected him with one of our staff members, who listened as `}<strong parentName="p">{`Drew poured his heart out—and then prayed to surrender his life to the Lord.`}</strong>{` Our staff member gave Drew a new Bible, discipleship materials, and the number for our Billy Graham 24/7 Prayer Line so that he can continue to find support in his new faith. Please pray for this new brother and for the many others who received the greatest gift of all, Jesus Christ, during `}<em parentName="p">{`Christmas at the Library`}</em>{`.`}</p>
      </div>
      <div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "christmas-at-the-library"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`EVANGELISTIC CRUSADES`}</span></h3>{`
    `}<h4 parentName="div">{`Hundreds Respond to the Good News`}</h4>{`
    `}<p parentName="div">{`My son Will recently `}<strong parentName="p">{`shared the Gospel during a massive celebration in Budapest, Hungary`}</strong>{`, which marked the 500`}<sup parentName="p">{`th`}</sup>{` anniversary of the beginning of Baptist church ministry and evangelism in the country. More than 8,000 people listened as he `}<em parentName="p">{`“preached boldly in the name of Jesus”`}</em>{` (Acts 9:27, ESV)—and hundreds responded by making life-changing decisions for Him, including Anna.*`}</p>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAGbXloqkZD/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIREiIx/9oACAEBAAEFAkOUcdRti5Z//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEx/9oACAEDAQE/AdR//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAESIf/aAAgBAgEBPwFYUz//xAAXEAADAQAAAAAAAAAAAAAAAAABECBh/9oACAEBAAY/AoOL/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAEhMWERUf/aAAgBAQABPyGCRHq/ejWxp3hYDZyf/9oADAMBAAIAAwAAABDQ/wD/xAAXEQEBAQEAAAAAAAAAAAAAAAABADFR/9oACAEDAQE/EEMSet//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAWH/2gAIAQIBAT8Qoyj/xAAcEAEBAQACAwEAAAAAAAAAAAABEQAhQTFhgfD/2gAIAQEAAT8QIRYOMCAeezPbaSvOnLg/WK0tFPf65Dkl6N//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "budapest crowd wg 231102 6074",
            "title": "budapest crowd wg 231102 6074",
            "src": "/static/e574727ca035061c9d7d142aca2d647c/6a068/budapest_crowd_wg_231102_6074.jpg",
            "srcSet": ["/static/e574727ca035061c9d7d142aca2d647c/f93b5/budapest_crowd_wg_231102_6074.jpg 300w", "/static/e574727ca035061c9d7d142aca2d647c/b4294/budapest_crowd_wg_231102_6074.jpg 600w", "/static/e574727ca035061c9d7d142aca2d647c/6a068/budapest_crowd_wg_231102_6074.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`AFTER GIVING AN INVITATION to receive Christ to more than 8,000 in attendance in Budapest, Hungary, Will Graham prayed with those who streamed forward to respond.`}</figcaption>{`
    `}<p parentName="div"><strong parentName="p">{`The teenager rushed to the front with a group of friends as soon as Will gave the invitation—but her decision to embrace salvation in Jesus Christ was all her own.`}</strong>{` “She was really touched when [Will] was talking about sin,” an interpreter told us. “She felt that she should come to the front. She also felt that her sins were forgiven.” Anna went on to share that she finally had hope for the future—and assurance that God would be with her every step of the way. Please pray for her and for the many new believers in Hungary.`}</p>
      </div>
      <div>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling mt-6",
          "id": "christmas-at-the-library"
        }}><span parentName="h3" {...{
            "className": "category"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span></h3>{`
    `}<h4 parentName="div">{`Ministry in Mexico`}</h4>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
    `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHU3IspAK//xAAaEAACAwEBAAAAAAAAAAAAAAABAgADEhEx/9oACAEBAAEFAhUWVLSs6TGfItUZHn//xAAXEQEAAwAAAAAAAAAAAAAAAAAAERJS/9oACAEDAQE/AYys/8QAGBEAAgMAAAAAAAAAAAAAAAAAAQIQISL/2gAIAQIBAT8BD3qP/8QAGxAAAgIDAQAAAAAAAAAAAAAAABEBUQIQMWH/2gAIAQEABj8CmWqFQ0Riuj91/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/IXk6YL6wa+UjXt0j1Bv3yMQAfE4T/9oADAMBAAIAAwAAABBA7//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QWG8Nu//EABgRAAMBAQAAAAAAAAAAAAAAAAABESFx/9oACAECAQE/EFuNLpT/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhQVExccH/2gAIAQEAAT8QGEcgflh3QkyVbsb3kFABjxrCdBdX0yNFbAWnOKAGgDzn/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Two Rapid Response Team Chaplains praying with a woman.",
              "title": "Two Rapid Response Team Chaplains praying with a woman.",
              "src": "/static/f8c99027d5f4102fba627617185eadb3/6a068/rrt_acapulco_231108.jpg",
              "srcSet": ["/static/f8c99027d5f4102fba627617185eadb3/f93b5/rrt_acapulco_231108.jpg 300w", "/static/f8c99027d5f4102fba627617185eadb3/b4294/rrt_acapulco_231108.jpg 600w", "/static/f8c99027d5f4102fba627617185eadb3/6a068/rrt_acapulco_231108.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div" {...{
            "className": "category"
          }}>{`OUR CHAPLAINS are sharing the compassion of Jesus Christ after a Category 5 hurricane destroyed homes and claimed lives in Acapulco, Mexico.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{`Last year, God drew thousands to Himself during our historic Festival in Mexico City, one of the world’s largest metropolitan areas, and I’ve been looking forward to returning ever since.`}</p>{`
    `}<p parentName="div"><strong parentName="p">{`Nearly 2,000 churches are working tirelessly to make this outreach possible.`}</strong>{` In the coming weeks, church leaders will meet to pray and arrange last-minute details for the many buses reserved to bring people from all across this massive city to the Festival. I am grateful for all of them—and for our ministry underway elsewhere in Mexico.`}</p>{`
    `}<p parentName="div">{`Our `}<strong parentName="p">{`Billy Graham Rapid Response Team chaplains have been ministering in Acapulco`}</strong>{`, where thousands are still reeling from Hurricane Otis, a Category 5 storm that claimed dozens of lives and destroyed countless homes.`}</p>{`
    `}<p parentName="div">{`A couple of the chaplains recently met Geraldo,* who still had physical scars from the hurricane. He shared the remarkable story of how he had survived, and then the conversation turned to faith. Geraldo was open to hearing the Gospel, but he said he wasn’t ready to make a decision. He took the “Steps to Peace With God” booklet the chaplains offered him and promised to read it later. The very next day, `}<strong parentName="p">{`Geraldo came back to the chaplains’ base of operation and excitedly told them that he had decided to surrender his life to the Lord`}</strong>{` the previous night. His entire demeanor had changed, and he gave our chaplain a hug and thanked him for sharing the Good News.`}</p>{`
    `}<p parentName="div">{`Please pray for Geraldo and the dozens of other people who have told us they made decisions for Jesus Christ during this deployment. Please also lift up each believer who is working now to prepare for the Mexico City Festival.`}</p>{`
    `}<p parentName="div">{`Before 2023 is over, `}<strong parentName="p">{`will you prayerfully consider a special year-end gift so that many more can hear the Gospel of Jesus Christ`}</strong>{` and find lasting hope in Him?`}</p>{`
    `}<p parentName="div">{`Thank you for your prayers, your support, and your heart to share God’s love with people far from Him. You are crucial to this ministry, and I am grateful for you. I pray that the Lord blesses you with wisdom, strength, and boldness to tell others about His love in the coming year.`}</p>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham`}</p>{`
    `}<p parentName="div">{`President & CEO`}</p>{`
    `}<p parentName="div">{`*Names changed to protect privacy`}</p>
      </div>
      <hr></hr>
      <div {...{
        "className": "give-background"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM LIBRARY`}</span>{` | We hear from Library guests daily about how God has touched their lives—or changed their hearts—during their visit. Your gift to the `}<strong parentName="p">{`Billy Graham Library`}</strong>{` helps keep admission free, sharing the Good News of Christ with thousands of people who otherwise might not visit.
    `}</p>{`
    `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the Franklin Graham Festivals in Mexico City, Mexico, and Kraków, Poland, next year.
    `}</p>{`
        `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
    `}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      