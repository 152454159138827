import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationHurricane = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
}) => (
  <div>
    <h2>
      Thank You for Helping Comfort the Hurting
    </h2>
    <p>
      We are grateful for your heart to respond to the emotional and spiritual needs of those reeling from hurricanes Helene and Milton.
    </p>
    <p>
      Your generous gift helps support crisis-trained Billy Graham Rapid Response Team chaplains who are offering people impacted a shoulder to lean on in times of uncertainty.
    </p>
    <p>
      Please keep these communities—and our chaplains—in prayer as they face the days ahead.
    </p>
    <Block>
    We are grateful for your financial support. Your transaction will be 
    processed shortly and will appear on your next statement. An acknowledgment
    will be emailed to you.
    </Block>
    <Block>
      The confirmation number for your{' '}
      {showRecurrenceText && <strong>{recurrenceText}</strong>} donation is{' '}
      <strong>{transactionId}</strong> for the amount of $
      {total.toFixed(2) ?? 'NEED AMOUNT'}.
    </Block>
    <Block>
      If you have any questions, call us at 1-877-247-2426. Please have your
      confirmation number ready and indicate that you are contacting us about a
      donation made through the Billy Graham Evangelistic Association website.
    </Block>
    <Block>
      To keep you updated on the ministry, we would like to send you our prayer
      letter, <i>From the Desk of Franklin Graham</i>, featuring biblical truths
      about current events, news of his travels and updates on the work of BGEA
      with thousands of people around the world. You can opt-out from receiving
      these emails anytime.
    </Block>
  </div>
);

export default customConfirmationHurricane;
