import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "content"
    }}>{`
  `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>{`
  `}<div parentName="div" {...{
        "id": "They-Went-Back-to-Check-Again",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`They Went Back to Check Again ...`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`NORTH CAROLINA`}</span>{`
    `}</h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
    `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHIkSTLVHiaijyqgV//xAAaEAADAQEBAQAAAAAAAAAAAAAAAQIRAxIx/9oACAEBAAEFAsFJg/imvD0jnHlS2q5zs0Ohs//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAAEEAwAAAAAAAAAAAAAAAAABICExEBFB/9oACAEBAAY/AmbxJNJR1n//xAAbEAADAQEBAQEAAAAAAAAAAAAAAREhMUFRkf/aAAgBAQABPyFYENMb3hhcEEWx/fRK6SGlJfscLDxv0SUMRjdP/9oADAMBAAIAAwAAABAzAc//xAAXEQEBAQEAAAAAAAAAAAAAAAABEBEx/9oACAEDAQE/EGYPZ//EABgRAQADAQAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/ENlaM4p//8QAHBABAAMBAAMBAAAAAAAAAAAAAQARIUExYYFR/9oACAEBAAE/ECLPf2Ewchlljs9ZDyGl+A9fZs9bsUtfMupaNNIKrav5FOSvBk3UOe4jWT//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "241008 ps1 9897",
              "title": "241008 ps1 9897",
              "src": "/static/3f4ba33c2fd516681bebb0e3d1180ed7/c08c5/241008_ps1_9897.jpg",
              "srcSet": ["/static/3f4ba33c2fd516681bebb0e3d1180ed7/f93b5/241008_ps1_9897.jpg 300w", "/static/3f4ba33c2fd516681bebb0e3d1180ed7/b4294/241008_ps1_9897.jpg 600w", "/static/3f4ba33c2fd516681bebb0e3d1180ed7/c08c5/241008_ps1_9897.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`During every deployment, `}</strong>{`chaplains pray with residents
      reeling from disaster—and point them to the only One who offers lasting
      hope.
    `}</figcaption>{`
  `}</div>{`
      Our chaplains were on their way to a worksite in Burnsville, North Carolina,
    after Hurricane Helene when they met Gio,* a construction worker. He was
    helping direct traffic around washed-out roads, which gave a couple of our
    Spanish-speaking chaplains time to chat with him. During their conversation,
    Gio shared how much he was struggling—he was divorced and his ex-wife had
    custody of their son. The two of them lived across the country. `}<strong parentName="div">{`Gio said
     he knew he would go to the “bad place” when he died.`}</strong>{`
    Our chaplains shared the Gospel, but he couldn’t bring himself to embrace
    it. The next day, the chaplains returned to the construction site to check
    on him. The Holy Spirit had been working in his heart, and this time, Gio
    wanted to respond to the Good News. Through an emotional prayer,
    `}<strong parentName="div">{`he received Jesus Christ in repentance and faith.`}</strong>{`
    Afterward, he told our chaplains he planned on finding a Bible-teaching
    church near his home, and they joyfully presented him with a
    Spanish-language Bible. Please pray that Gio will grow closer to the Lord
    and `}<em parentName="div">{`“be filled with the knowledge of His will in all wisdom and spiritual
    understanding`}</em>{`” (Colossians 1:9, NKJV).
  `}</div>{`
  `}<div parentName="div" {...{
        "id": "I-have-been-waiting-for-you",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`‘I’ve been waiting for you ...’`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`NORTH CAROLINA`}</span>{`
    `}</h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAHTFlNiawr/xAAbEAACAgMBAAAAAAAAAAAAAAABAgADBBESE//aAAgBAQABBQJsggu46NoMsUCWpzPLU//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AYj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAETH/2gAIAQIBAT8BuK//xAAcEAACAgIDAAAAAAAAAAAAAAAAASExAkERUYH/2gAIAQEABj8CSog34Y8IU76LP//EABoQAQEBAQADAAAAAAAAAAAAAAERACFBYXH/2gAIAQEAAT8hboW9TuddS8yl7+s3EVmWktnAieHrf//aAAwDAQACAAMAAAAQKN//xAAWEQEBAQAAAAAAAAAAAAAAAAARARD/2gAIAQMBAT8QUc//xAAXEQADAQAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EIOl/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITGBkf/aAAgBAQABPxAD6F5VY5FrVHB07sZts4oEEUTSG9CVmAgIy89h294wh8n/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "2493 us a6 074",
              "title": "2493 us a6 074",
              "src": "/static/bc7540c6b6d00a3b9296935311b3ad76/6a068/2493-us-a6-074.jpg",
              "srcSet": ["/static/bc7540c6b6d00a3b9296935311b3ad76/f93b5/2493-us-a6-074.jpg 300w", "/static/bc7540c6b6d00a3b9296935311b3ad76/b4294/2493-us-a6-074.jpg 600w", "/static/bc7540c6b6d00a3b9296935311b3ad76/6a068/2493-us-a6-074.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div">{`
        `}<strong parentName="figcaption">{`OUR CHAPLAINS `}</strong>{`have been faithfully ministering in the
        Appalachian Mountains of Tennessee and North Carolina following
        Hurricane Helene. Here, Samaritan's Purse board member and BG-RRT
        chaplain Jim Oliver prays with a storm survivor.
      `}</figcaption>{`
    `}</div>{`
     June* lives in the mountains of North Carolina and was deeply affected by
    Hurricane Helene. She was thrilled to see our chaplains when they arrived at
    her home. “They told me you were coming, and I’ve been waiting for you. I’m
    ready for you,” she said. When their conversation turned to faith, she
    explained that she had overcome a painful addiction to alcohol.
    `}<strong parentName="div">{`
      She was celebrating 12 years sober—but she felt like something was still
      missing.
    `}</strong>{`
    June paid close attention as our chaplains shared that only a relationship
    with Jesus Christ could fill the emptiness in her heart. After they shared
    the Gospel, she surrendered her life to Jesus Christ, embracing His promise
    that `}<em parentName="div">{`“the thief comes only to steal and kill and destroy. I came that they may have life and have it abundantly`}</em>{`” (John 10:10, ESV).
    `}<strong parentName="div">{`Once she had finished praying, she couldn’t hide her excitement.
    `}</strong>{`Before the chaplains left, she thanked them. Will you ask the Lord to help her become a bold witness for Him?
  `}</div>{`
  `}<div parentName="div" {...{
        "id": "I-am-ready",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`‘I am ready!’`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`OKLAHOMA`}</span>{`
    `}</h3>{`
     After deadly tornadoes swept through Oklahoma City, Oklahoma, our chaplains
    deployed to minister to all those affected. A couple of chaplains met Nic*
    and Sienna,* a young couple searching for scrap metal in the wreckage left
    by the tornadoes.
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAYDMzNaFif/EABkQAAIDAQAAAAAAAAAAAAAAAAECABETIv/aAAgBAQABBQIaUGjt0CclY6UJ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPwHWHl//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIRITH/2gAIAQEABj8CyxpazqJF2PD/xAAdEAEBAQABBQEAAAAAAAAAAAABEQAxIUFRYYGh/9oACAEBAAE/IQmmvfYzIzxR/c5LQwFHrefmDFUw7VN5d//aAAwDAQACAAMAAAAQQ/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAB/9oACAEDAQE/EHEIv//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEUH/2gAIAQIBAT8Q0FOSIb//xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMUFRwf/aAAgBAQABPxA6JjwX0NYlFwbRX4MeQQGq7ntxm4wVvs4+PYKPN1k8QhWq5//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "oklahoma city v3",
              "title": "oklahoma city v3",
              "src": "/static/6b615e973b5687543e4474aa524ceeec/6a068/oklahoma_city_v3.jpg",
              "srcSet": ["/static/6b615e973b5687543e4474aa524ceeec/f93b5/oklahoma_city_v3.jpg 300w", "/static/6b615e973b5687543e4474aa524ceeec/b4294/oklahoma_city_v3.jpg 600w", "/static/6b615e973b5687543e4474aa524ceeec/6a068/oklahoma_city_v3.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div">{`
        `}<strong parentName="figcaption">{`CHAPLAINS SHARE THE LOVE `}</strong>{` of Jesus Christ with
        residents experiencing life-altering crises.
      `}</figcaption>{`
    `}</div>{`
    Our chaplains chatted with them for a few minutes before asking if they
    would like prayer. Afterward, both husband and wife were open to hearing
    more about the Gospel of Jesus Christ.
    `}<strong parentName="div">{`
      Nic and Sienna followed along as chaplains explained that Jesus Christ is
      “`}<em parentName="strong">{`the way, and the truth, and the life`}</em>{`” (John 14:6, ESV).
    `}</strong>{`
     When our chaplains asked each of them if they wanted a relationship with
    the Savior, Sienna quickly said, “I am ready!” Nic followed up, saying he
    was ready too.
    `}<strong parentName="div">{`
      Both prayed to repent of sin and place their trust in the Lord.
    `}</strong>{`
    Please pray for this new brother and sister in Christ!
  `}</div>{`
  `}<div parentName="div" {...{
        "id": "The-Power-of-the-Gospel",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`The Power of the Gospel Overcomes Hinduism’s Empty Promises`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`GEORGIA`}</span>{`
    `}</h3>{`
     When Hurricane Debby flooded hundreds of homes in coastal Georgia, chaplains
    deployed to minister to all those affected. A couple of chaplains met Advik*
    and Veda,* a husband and wife whose home had been damaged in the storm. The
    two talked openly about their Hindu faith.
    `}<strong parentName="div">{`
      They shared that they worked hard to accumulate good deeds in this life,
      with the hope of being reincarnated after death.
    `}</strong>{`
    One of our chaplain leaders asked them if he could explain what Christians
    believe. Advik and Veda listened closely as he told them about Jesus
    Christ—and that “`}<em parentName="div">{`there is salvation in no one else, for there is no other name under heaven given among men by which we must be saved`}</em>{`” (Acts 4:12, ESV).
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABaWOlOuKS/8QAGhABAAMAAwAAAAAAAAAAAAAAAgABAxESIf/aAAgBAQABBQJbWoNonzZgNdqPn//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8Bacwp/8QAGBEAAgMAAAAAAAAAAAAAAAAAABICESH/2gAIAQIBAT8Bgt6Kf//EABkQAAIDAQAAAAAAAAAAAAAAAAIQAAEhMf/aAAgBAQAGPwIh6s2W/wD/xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhMWGx/9oACAEBAAE/IVAQPXmQYqNyrADi5NHzdxzcTTbv/9oADAMBAAIAAwAAABBsP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxB2oLL/xAAXEQADAQAAAAAAAAAAAAAAAAAAARFB/9oACAECAQE/EIajt0//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMVFhcYH/2gAIAQEAAT8QNlxpqGX7MBkBBOQ9ec5ngTOGbhgDxF/c49CCWQweiXWy5//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "rrt prayer counsel porch",
              "title": "rrt prayer counsel porch",
              "src": "/static/57062fdbd09c109954f550a3bf594db0/6a068/rrt_prayer_counsel_porch.jpg",
              "srcSet": ["/static/57062fdbd09c109954f550a3bf594db0/f93b5/rrt_prayer_counsel_porch.jpg 300w", "/static/57062fdbd09c109954f550a3bf594db0/b4294/rrt_prayer_counsel_porch.jpg 600w", "/static/57062fdbd09c109954f550a3bf594db0/6a068/rrt_prayer_counsel_porch.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div">{`
        `}<strong parentName="figcaption">{`MANY OF THOSE `}</strong>{` whom our chaplains minister to have lost
        homes, family members, pets, or their livelihoods.
      `}</figcaption>{`
    `}</div>{`
    He went on to explain that He paid for our sins on the cross and that faith
    in Him is the only way to Heaven.
    `}<strong parentName="div">{`
      Advik and Veda both began to cry as they realized their need for the
      Savior.
    `}</strong>{`
    They shared their eager desire to know and obey Him as Lord of their lives
    and prayed in repentance and faith. Please lift them up in prayer as they
    learn more about the truth of God’s Word and begin new lives with Him.
  `}</div>{`
  `}<br parentName="div"></br>{`
  `}<div parentName="div" {...{
        "id": "She-Knew-Her-Life-Was-Spared",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`She Knew Her Life Was Spared`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`FLORIDA`}</span>{`
    `}</h3>{`
       Chaplains met Hannah* in Charlotte County, Florida, which was hit hard by
      both hurricanes Helene and Milton. She was deeply traumatized by the
      destruction she had witnessed. Her neighbors had just finished cleaning up
      from Helene when Milton made landfall and once again flooded the
      community. Hannah explained that she had a very diverse religious
      background—she was Jewish but had attended Catholic schools all throughout
      her childhood. As an adult, she had studied with Jehovah’s Witnesses.
      `}<strong parentName="div">{`
        She was deeply moved that God had spared her life so many times over the
        previous weeks—and she was open to hearing more about Him from our
        chaplains. 
      `}</strong>{`  Using the Scriptures found in our `}<strong parentName="div">{`“Steps to Peace With God” `}</strong>{`booklet, the chaplains shared the Good News that “`}<em parentName="div">{`the Word became flesh and dwelt among us, and we have seen his glory,  glory as of the only`}</em>{`
      `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABYwsRIZFTL//EABsQAQACAgMAAAAAAAAAAAAAAAIAAwEREiEi/9oACAEBAAEFArrGY3jjV6OTuXVEo9T/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8BrH//xAAcEAACAAcAAAAAAAAAAAAAAAAAIQIQESJCUWH/2gAIAQEABj8CUFvTGuhIckf/xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMWFB/9oACAEBAAE/IeBeisswWhtJS8J41XqLlfTJrGn/2gAMAwEAAgADAAAAEJ8//8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAEDAQE/EALb/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAgEBPxBpcEx//8QAGxABAQEAAwEBAAAAAAAAAAAAAREAITFBUaH/2gAIAQEAAT8QseQIS3yZ9HOABT4XIrFgRHoxRjdlDCAspHyJuPAcvw3/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "241006 lr 09113",
              "title": "241006 lr 09113",
              "src": "/static/a7694ccea00c25fc614c743a8499bb20/6a068/241006_lr_09113.jpg",
              "srcSet": ["/static/a7694ccea00c25fc614c743a8499bb20/f93b5/241006_lr_09113.jpg 300w", "/static/a7694ccea00c25fc614c743a8499bb20/b4294/241006_lr_09113.jpg 600w", "/static/a7694ccea00c25fc614c743a8499bb20/6a068/241006_lr_09113.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
        `}<figcaption parentName="div"><strong parentName="figcaption">{`AFTER FLORIDA WAS HIT `}</strong>{`by back-to-back hurricanes, our chaplains deployed to share the hope of Jesus Christ with hurting residents.
        `}</figcaption>{`
      `}</div>{`
        `}<em parentName="div">{`Son from the Father, full of grace and truth`}</em>{`” (John 1:14, ESV).
      `}<strong parentName="div">{`
        Hannah listened with an open heart and then enthusiastically prayed to
        place her faith and trust in Jesus Christ.
      `}</strong>{`
      `}<br parentName="div"></br>
        <div parentName="div" {...{
          "className": "quote-wrapper"
        }}>{`
    `}<div parentName="div" {...{
            "className": "quote-container"
          }}>{`
      `}<p parentName="div" {...{
              "className": "quote-text"
            }}>{`
        GOD IS OUR REFUGE AND STRENGTH, A VERY PRESENT HELP IN TROUBLE.
      `}</p>{`
      `}<p parentName="div" {...{
              "className": "quote-reference"
            }}>{`—PSALM 46:1, NKJV`}</p>{`
    `}</div>
        </div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "id": "learn-how",
        "className": "piece"
      }}>{`
    `}<h4 parentName="div">{`
      You can bring the love and hope of Jesus Christ to people enduring
      tragedy.
    `}</h4>{`
    `}<div parentName="div">{`
      `}<h5 parentName="div">{`PRAY`}</h5>{`
      `}<p parentName="div">{`
        Please pray for `}<strong parentName="p">{`people across our nation `}</strong>{`and around the
        world who are suffering in the wake of natural and man-made disasters.
        Will you ask God to give
        `}<strong parentName="p">{` BG-RRT chaplains strength and wisdom`}</strong>{` as they minister
        in Jesus’ Name to these men and women? Please also
        `}<strong parentName="p">{`
          lift up in prayer the `}<em parentName="strong">{`Sharing Hope in Crisis`}</em>{` trainings
        `}</strong>{`
        taking place across the globe—and online.
      `}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
      `}<h5 parentName="div">{`GIVE`}</h5>{`
      `}<p parentName="div">{`
        Your gift to the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps
        comfort the hurting with the hope of Christ by training and deploying
        chaplains into crisis situations throughout the U.S. and around the
        world.
      `}</p>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      