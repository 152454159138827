import React from 'react';

const iiaCopyBo = () => (
  <div>
    <b>Andrés en Acción</b>
    <br/><br/>
    <p>
      <strong>Andrés en Acción</strong> es un compromiso de pasar tiempo con
      Jesús diariamente, y hacer prioridad en tu vida el compartir las Buenas
      Nuevas:
    </p>
    <p>
      <b>ORAR</b> - Escribe los nombres de personas a quienes conoces y que
      necesitan a Jesús, y ora por ellos cada día.
    </p>
    <p>
      <b>COMPARTIR</b> - Pasa tiempo con ellos y encuentra oportunidades de
      testificar acerca de Jesús.
    </p>
    <p>
      <b>TRAER</b> - Trae las personas en tu lista al evento. Si responden al
      llamado de Cristo o muestran un interés en el Evangelio, van a necesitar
      que los animes regularmente.
    </p>
    <p>
      <b>DAR SEGUIMIENTO</b> - Invítalos a que te acompañen a la iglesia. Dios
      te puede usar a ti y a tu iglesia para ayudarlos a tener una relación más
      profunda con Jesús.
    </p>
  </div>
);

export default iiaCopyBo;