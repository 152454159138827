import React from 'react';

const DailyDevotionalCustomConfirmation = () => (
  <div>
    <p>
      Great News! Billy Graham’s Daily Devotional <em>Hope for Each Day</em> will soon be delivered to your
      inbox.
    </p>
    <p>
      We praise God for your heart to grow in your faith. It’s our prayer that
      this resource will encourage you in your walk with the Lord.
      <em>
        “But seek first the kingdom of God and his righteousness, and all these
        things will be added to you.”
      </em>{' '}
      —Matthew 6:33, ESV
    </p>
    <p><strong>Before you go, there are a few things we’d like you to know…</strong></p>
    <p>God is reaching people with His love in a variety of ways through the Billy Graham Evangelistic Association, 
      including a 24-hour prayer line, online Gospel resources, and Billy Graham Rapid Response Team (BG-RRT) chaplains, 
      who comfort and pray with hurting people in the midst of crisis. You can learn more about these powerful
       ministries <a href="https://billygraham.org/">HERE</a>.
       We are grateful for you!</p>
  </div>
);

export default DailyDevotionalCustomConfirmation;
