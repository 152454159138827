import { NewNames as NewNamesForm } from '@bgea-js/form-templates';
import "../../../../src/content/biblical-guidance/biblical-guidance.scss";
import BiblicalGuidenceCustomMarkup from "../../../../src/content/biblical-guidance/customMarkup";
import customModalHeaderBGF from "../../../../src/content/biblical-guidance/customModalHeader";
import * as React from 'react';
export default {
  NewNamesForm,
  BiblicalGuidenceCustomMarkup,
  customModalHeaderBGF,
  React
};