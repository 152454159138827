import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<img parentName="div" {...{
        "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/nov/rrt-pray-porch.jpg",
        "alt": ""
      }}></img>{`
  `}<figcaption parentName="div">{`Our chaplains are still deployed to communities impacted by Helene and Milton. Every day, they pray with residents and point them to the peace found in Jesus Christ.`}</figcaption>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rrt"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`Sharing God’s Love in the Wake of Helene and Milton`}</h4>{`
  `}<p parentName="div">{`Although hurricanes Helene and Milton are no longer in the national spotlight, families across Western North Carolina and Florida are still hurting. It has been heartbreaking to see the total devastation from these massive storms. `}<strong parentName="p">{`We have been ministering in communities across the Southeast, from Florida’s Gulf Coast to remote communities deep in the Appalachian Mountains.`}</strong></p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/nov/rrt-chaplains-nc.jpg",
          "alt": ""
        }}></img>{`
  `}<figcaption parentName="div">{`Western North Carolina`}</figcaption>{`
  `}</div>{`
  `}<p parentName="div">{`Our Billy Graham Rapid Response Team chaplains are serving alongside Samaritan’s Purse disaster relief workers, offering a listening ear, prayer, and God’s promise that `}<em parentName="p">{`“the Lord is near to the brokenhearted and saves the crushed in spirit”`}</em>{` (Psalm 34:18, ESV). Many of our chaplains talk to individuals who have lost everything—homes, cars, livelihoods, and even family members and friends. In the midst of overwhelming destruction, many have found hope for eternity by receiving Jesus Christ in repentance and faith.`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/nov/rrt-prayer.jpg",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<p parentName="div"><strong parentName="p">{`Maria* is among these new brothers and sisters.`}</strong>{` She met a couple of our chaplains when she went to pick up clean water from a church in Buncombe County—one of the worst-hit areas in North Carolina. Our chaplains helped her load the water into her car and then asked if they could pray for her. She explained that she missed her family in Mexico and was struggling with the challenges of recovering from Helene.`}</p>{`
  `}<p parentName="div">{`As they prayed, Maria wept. She was overwhelmed with gratitude—and with conviction from the Holy Spirit. She told the chaplains that she knew she was far from God, but she wanted to begin a relationship with Jesus Christ. `}<strong parentName="p">{`With tears running down her face, she prayed to place her faith and trust in Him.`}</strong>{` Before their time together ended, our chaplains gave Maria the number for our prayer line and helped her find some local Bible-teaching churches where she can meet other believers and grow in her faith. We give God all the glory! Please join me in praying for her and for all those who have professed faith during these ongoing deployments.`}</p>{`
  `}<img parentName="div" {...{
        "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/nov/rrt-truck.jpg",
        "alt": ""
      }}></img>{`
  `}<figcaption parentName="div">{`Chaplains speak with a resident in Asheville, North Carolina, outside the Mobile Ministry Center.`}</figcaption>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "nlem"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`NATIONAL LAW ENFORCEMENT MINISTRY`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`Turning from Heartbreak to Hope in Jesus Christ`}</h4>{`
  `}<p parentName="div">{`Just a couple of weeks ago, `}<strong parentName="p">{`we hosted a Law Enforcement Appreciation Retreat for officers and their spouses in California.`}</strong>{` This two-day event was designed to give these brave men and women a getaway from the intense pressure of their calling, to rest, enjoy each other’s company, and hear powerful Biblical encouragement from experienced speakers. We praise God for the 175 who attended.`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://s3.theark.cloud/static-billygraham-org/sites/pages.billygraham.org/2024/nov/lear-california.jpg",
          "alt": ""
        }}></img>{`
  `}<figcaption parentName="div">{`Every Law Enforcement Appreciation Retreat features worship, Biblical messages, and opportunities for fellowship.`}</figcaption>{`
  `}</div>{`
  `}<p parentName="div"><strong parentName="p">{`Lewis* and Jenny* were among those who left the retreat with changed hearts. Lewis serves as an officer, but the burden on his heart was his own 27-year-old son.`}</strong>{` The couple walked forward when one of our speakers gave an invitation to respond to the Gospel. They told one of our staff members that they were deeply concerned for their son, who is addicted to drugs.`}</p>{`
  `}<p parentName="div">{`Our chaplain was able to minister to the couple in a special way, since he lost his own son to an overdose. The three talked for some time and prayed together—and `}<strong parentName="p">{`then Lewis and Jenny said they wanted to fully surrender their lives to the Lord in repentance and faith.`}</strong>{` After the retreat ended, Lewis shared that the time away “helped me make a decision to turn to Christ.” Please pray for this couple, their son, and all those who attended the retreat.`}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "gospel-tv-spots"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`GOSPEL TV SPOTS`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`Pointing North America to the Savior`}</h4>{`
  `}<p parentName="div">{`I’m grateful for the opportunity to once again share the Gospel of Jesus Christ through short TV spots. `}<strong parentName="p">{`I just recorded a new ad that will air soon on national networks.`}</strong></p>{`
  `}<p parentName="div">{`Each time these spots are broadcast, thousands of people from all walks of life across our nation call our toll-free Billy Graham 24/7 Prayer Line at 1-855-255-PRAY (7729). Some are desperate to talk or pray with someone, while others are ready to respond to the Gospel by surrendering to the Lord in repentance and faith. We give God all the glory for each person He calls to Himself through this ministry.`}</p>{`
  `}<p parentName="div"><strong parentName="p">{`You are helping change the lives of people around the world through your prayerful support and none of this work would be possible without friends like you.`}</strong>{` As we prepare to celebrate Thanksgiving, I’m so grateful for you—and I pray that you and your family will have a joyful holiday, remembering the many blessings of the Lord in your life.`}</p>{`
  `}<p parentName="div">{`May the Lord richly bless you,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/pro/2020/09/FG-signature1.jpg",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Franklin Graham`}</p>{`
  `}<p parentName="div">{`President & CEO`}</p>{`
  `}<p parentName="div">{`Names *and photos changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<div parentName="div" {...{
        "className": "give-background"
      }}>{`
  `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
  `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
  `}</h4>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` enables crisis-trained chaplains to bring comfort in Jesus Christ’s Name to hurting communities and broken lives.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`NATIONAL LAW ENFORCEMENT MINISTRY`}</span>{` | Every year, hundreds of law enforcement officers and their spouses attend appreciation dinners, retreats, and weeklong Marriage Resiliency Week events in Alaska. Your support makes it possible to bless them with this time away and strengthen them in their calling as they sacrificially serve our nation. 
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`GOSPEL TV SPOTS`}</span>{` | So many are living without the hope that comes from Jesus Christ. Your gift to `}<strong parentName="p">{`Gospel TV Spots`}</strong>{` will help broadcast the Good News through radio and TV ads all across North America and offer a 24/7 prayer line for people who want to talk or pray with someone. 
  `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      