import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div {...{
        "className": "crusadesupdate"
      }}>{`
    `}<p parentName="div" {...{
          "className": "update-month no-indent"
        }}>{`June 2024`}</p>{`
    `}<h1 parentName="div" {...{
          "className": "screen-reader-text"
        }}>{`International Crusades Update`}</h1>{`
    `}<h2 parentName="div" {...{
          "className": "screen-reader-text"
        }}>{`Your prayers and gifts in action`}</h2>{`
    `}<p parentName="div" {...{
          "className": "no-indent"
        }}>{`Dear Friend,`}</p>{`
    `}<p parentName="div"><strong parentName="p">{`We give God all the glory for what He has done so far in 2024`}</strong>{`—and I’m grateful for friends like you who have helped make this life-changing ministry possible. Since February, my son Will and I have had the opportunity to proclaim `}<em parentName="p">{`“the salvation which is in Christ Jesus”`}</em>{` (2 Timothy 2:10, NKJV) in `}<strong parentName="p">{`Mexico City, Mexico; Kraków, Poland; Porto, Portugal; and 10 cities along the southern U.S. border.`}</strong></p>{`
    `}<div parentName="div" {...{
          "className": "image-float-left-fg franklin-img"
        }}>{`
            `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/FG_MexicoCity_240211_TP2_1045_INET.jpg",
            "alt": ""
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{`During these outreaches, tens of thousands heard the Good News of Jesus Christ—and thousands responded by placing their faith and trust in Him. But these outreaches are much more than one or two-night events. We praise God that believers in each of these cities were also equipped to continue sharing His truth with their neighbors through our Christian Life and Witness Course.`}</p>{`
    `}<p parentName="div">{`In the coming months, God has opened more doors to preach His Gospel. In a few days, I’ll share the Good News in Birmingham, England, and Glasgow, Scotland, and then in September I’ll preach in `}<strong parentName="p">{`Naples, Italy`}</strong>{`. Will is scheduled to hold Celebrations in `}<strong parentName="p">{`northern Colorado`}</strong>{` in October and `}<strong parentName="p">{`Grande Prairie, Canada`}</strong>{`, in November. Please join us in dedicated prayer for people in each of these regions. Ask the Lord `}<em parentName="p">{`“to open their eyes, so that they may turn from darkness to light and from the power of Satan to God”`}</em>{` (Acts 26:18, ESV).`}</p>{`
    `}<p parentName="div" {...{
          "className": "no-indent"
        }}>{`Thank you again for your commitment to evangelism.`}</p>{`
    `}<p parentName="div" {...{
          "className": "no-indent"
        }}>{`May God richly bless you,`}</p>{`
     `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": ""
        }}></img>{`
     `}<p parentName="div" {...{
          "className": "no-indent"
        }}>{`Franklin Graham | President & CEO`}</p>{`
     `}<p parentName="div" {...{
          "className": "footnote no-indent"
        }}>{`*Names and photos changed to protect privacy`}</p>
      </div>
      <div {...{
        "className": "topic-one",
        "id": "im-asking-god-for-help"
      }}>{`
    `}<h2 parentName="div">{`MEXICO CITY, MEXICO`}</h2>{`
    `}<h3 parentName="div">{`‘I’m Asking God for Help …’`}</h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/MexicoCity_emotion-man_counsel_240211_SC1_4002_INET.jpg",
            "alt": ""
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{`Sirilo* and his mom rode a bus from her church to Franklin Graham’s Festival in Mexico City. He listened carefully as Franklin explained the freedom and forgiveness that Jesus Christ alone offers. “Tonight, you can be cleansed,” he told the crowd. “Tonight, you can be set free.” When Franklin gave the invitation, `}<strong parentName="p">{`Sirilo immediately walked forward. He sobbed as he prayed`}</strong>{` to place his faith and trust in `}<em parentName="p">{`“our Savior Christ Jesus, who abolished death and brought life and immortality to light through the gospel”`}</em>{` (2 Timothy 1:10, ESV). “I’m asking God for help and forgiveness,” he told a prayer volunteer. `}<strong parentName="p">{`“I don’t want to disappoint Him.”`}</strong>{` Another volunteer assured Sirilo that the Lord had already forgiven him. We praise God for drawing Sirilo to Himself!`}</p>
      </div>
      <div {...{
        "className": "topic-two",
        "id": "from-sobriety-to-true-freedom"
      }}>{`
    `}<h2 parentName="div">{`KRAKÓW, POLAND`}</h2>{`
    `}<h3 parentName="div">{`From Sobriety to True Freedom`}</h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/Krakow-counsel_guy_240401_TP2_2099_INET.jpg",
            "alt": ""
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{`Aron* had just experienced a landmark year when he attended Franklin’s Festival in Kraków, Poland. `}<strong parentName="p">{`The 24-year-old had stopped using drugs for 12 months—but he still didn’t feel free.`}</strong>{` He listened eagerly as Franklin preached the Good News that `}<em parentName="p">{`“Christ Jesus has set you free from the law of sin and of death”`}</em>{` (Romans 8:2, NASB). At the end of the service, `}<strong parentName="p">{`Aron joyfully prayed to receive Jesus in repentance and faith.`}</strong>{` “I’ve been clean from drugs for a year now, but I am addicted to social media,” he explained to one of our team members. “I need Jesus to help me.” A prayer volunteer gave Aron discipleship materials to strengthen his new faith. Will you pray for him and for all those who made decisions in Kraków?`}</p>
      </div>
      <div {...{
        "className": "topic-two",
        "id": "he-believed-that-the-lord-is-able-to-free-him"
      }}>{`
    `}<h2 parentName="div">{`KRAKÓW, POLAND`}</h2>{`
    `}<h3 parentName="div">{`‘He Believed That the Lord Is Able to Free Him ...’`}</h3>{`
    `}<p parentName="div">{`Jacob* was `}<strong parentName="p">{`stuck in a vicious struggle with alcohol`}</strong>{` when he came to the Festival in Kraków, Poland. The 31-year-old was deeply touched by the Gospel message that Franklin preached. He walked forward at the invitation, where he met one of our prayer volunteers and prayed to place his faith in Jesus Christ. Our volunteer said afterward, `}<strong parentName="p">{`“Today, he came to commit his life to the Lord. He believed that the Lord is able to free him from alcohol.”`}</strong>{` Will you pray that God will help him obey Jesus’ call to `}<em parentName="p">{`“take up his cross daily and follow me”`}</em>{` (Luke 9:23, ESV)?`}</p>{`
    `}<div parentName="div" {...{
          "className": "image-center image-one"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/CrowdInvite_FG-stage_240401_TP1_0608_INET.jpg",
            "alt": ""
          }}></img>{`
        `}<p parentName="div" {...{
            "className": "scripture no-indent"
          }}>{`“FOR GOD SO LOVED THE WORLD, THAT HE GAVE HIS ONLY SON, THAT WHOEVER BELIEVES IN HIM SHOULD NOT PERISH BUT HAVE ETERNAL LIFE.”`}<br parentName="p"></br><span parentName="p">{`—JOHN 3:16, ESV`}</span></p>{`
        `}<figcaption parentName="div" {...{
            "className": "number1"
          }}><span parentName="figcaption" {...{
              "className": "location"
            }}>{`KRAKÓW, POLAND`}</span><span parentName="figcaption" {...{
              "className": "subtitle"
            }}>{`Festival With Franklin Graham`}</span>{`
        MORE THAN 13,000 PEOPLE heard the Gospel of Jesus Christ and more than 730 of them responded by making decisions for Him during the Festival in Kraków, Poland.
        `}</figcaption>{`
    `}</div>
      </div>
      <div {...{
        "className": "topic-two",
        "id": "abundant-life-with-the-savior"
      }}>{`
    `}<h2 parentName="div">{`PORTO, PORTUGAL`}</h2>{`
    `}<h3 parentName="div">{`Abundant Life With the Savior`}</h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/Porto-counsel_lady_240402_LR2_3980_INET.jpg",
            "alt": ""
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{`When Will Graham offered the Gospel invitation during his Porto Celebration, Matilde* was one of the first people to walk forward. `}<strong parentName="p">{`She sank to her knees, crying as she prayed 
    to surrender her life to the Lord in repentance and faith.`}</strong>{` Maria,* a prayer volunteer, connected with her and listened compassionately as Matilde shared that she wanted to get closer to God. The two talked about the freedom that comes through a relationship with Jesus Christ. “She understood the difference between living with God and living without God,” Maria explained. `}<strong parentName="p">{`“She knew that coming to Him was the only way for her to be complete.”`}</strong>{` Please pray for her as she begins her walk with the Lord.`}</p>{`
    `}<div parentName="div" {...{
          "className": "image-center image-two"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/Porta_WG-crowd_240402_RN1_3752_INET.jpg",
            "alt": ""
          }}></img>{`
        `}<figcaption parentName="div" {...{
            "className": "number2"
          }}>{`“MY FRIENDS, JESUS CAME TO SAVE YOU from eternal separation from God,” Will Graham told the crowd of over 6,100 in Porto, Portugal. More than 480 people indicated decisions for the Lord.`}</figcaption>{`
    `}</div>
      </div>
      <div {...{
        "className": "topic-two",
        "id": "jesus-forgave-me"
      }}>{`
    `}<h2 parentName="div">{`PORTO, PORTUGAL`}</h2>{`
    `}<h3 parentName="div">{`‘Jesus Forgave Me’`}</h3>{`
    `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
        `}<img parentName="div" {...{
            "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2024/06/Huan_Porto_INET.jpg",
            "alt": ""
          }}></img>{`
    `}</div>{`
    `}<p parentName="div">{`Huan* attended Will’s Celebration in Porto, Portugal, in April. The young man listened with an open heart and mind as Will shared the Gospel. `}<strong parentName="p">{`“No other king died for you,” Will said.`}</strong>{` “Allow Jesus to be the new King in your life.” The Holy Spirit worked in Huan’s heart and he decided to do just that. “I felt it was necessary to go forward,” he told a team member after he prayed to place his faith in Jesus Christ. “I felt that Jesus forgave me.” We praise God for what He did in Huan’s heart. Will you pray that he will learn `}<em parentName="p">{`“to walk in a manner worthy of the Lord”`}</em>{` (Colossians 1:10, ESV)?`}</p>
      </div>
      <div>
        <section parentName="div" {...{
          "className": "how-to-help topic-one",
          "id": "crusades-how-to-help"
        }}>{`
    `}<h3 parentName="section" {...{
            "className": "h3-portion"
          }}>{`Crusades `}<span parentName="h3" {...{
              "className": "steps"
            }}></span><span parentName="h3" {...{
              "className": "smallportion"
            }}>{` How you can help`}</span></h3>{`
    `}<p parentName="section" {...{
            "className": "no-indent"
          }}><strong parentName="p"><em parentName="strong">{`God works through people like you to change the world by the power of the Gospel.`}</em></strong></p>{`
    `}<p parentName="section" {...{
            "className": "no-indent"
          }}><span parentName="p" {...{
              "className": "step"
            }}>{`PRAY: `}</span>{`You are a part of the life-changing work of Gospel proclamation through your prayers. Please ask God to draw people to Himself in each area where we hold Festival and Celebration events.`}</p>{`
    `}<p parentName="section" {...{
            "className": "no-indent"
          }}><span parentName="p" {...{
              "className": "step"
            }}>{`GIVE: `}</span>{`It is only through the generosity of friends like you that we can continue reaching people far from God with the Good News of Jesus Christ. Your support helps share life-changing hope with spiritually hungry people across the globe.`}</p>
        </section>
        <br parentName="div"></br>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      