import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContent from "../../../../src/content/prayer-letter/main-content.md";
import LearnMore from "../../../../src/content/prayer-letter/learn-more-details.md";
import "../../../../src/styles/prayer-letter.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContent,
  LearnMore,
  React
};