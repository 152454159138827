import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
  <p>Dear Friend,</p>
  <p>
    &emsp;After months of preparation, I’m now in the United Kingdom preparing to preach the Gospel of Jesus Christ in <strong>Birmingham, England,</strong> and <strong>Glasgow, Scotland.</strong> 
  </p>
  <p>
    &emsp;Since 2021, the Lord has worked mightily through our God Loves You Tours across the U.K. Tens of thousands of people from diverse backgrounds have heard the Good News—and thousands have <em>“repented and sought God earnestly”</em> (Psalm 78:34, ESV). I’m asking Him to do it again in these vastly different cities. <strong>Friends like you have joined us in prayer for months, and now I am grateful for the opportunity to watch the Holy Spirit move in hearts and minds.</strong>
  </p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      