import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<div parentName="div" {...{
        "className": "image-float-left-small is-hidden"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygrahamlibrary.org/uploads/prod/2023/08/RRT-FG-headshot-17683FLAT.jpg",
          "alt": "Fraklin Graham"
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`In a few short weeks I will return to Italy to share the Gospel in Naples, along the country’s southern coast.`}</p>{`
  `}<p parentName="div">{`Most people in Naples have heard of Jesus Christ—but the vast majority don’t know that He alone has `}<em parentName="p">{`“the words of eternal life”`}</em>{` (John 6:68, ESV). Tens of thousands are unaware—or unconcerned—about the depth of their need for Him. They have never heard that He is their only hope—in this life and the next.`}</p>{`
  `}<p parentName="div">{`Eternity is at stake for countless men and women, and it is an honor to `}<em parentName="p">{`“make known with boldness the mystery of the gospel”`}</em>{` (Ephesians 6:19, NASB). Will you pray that many will respond by surrendering their lives to the Lord? Please also lift up all of the churches and pastors involved in the Festival. So many evangelical leaders are excited for the opportunity to come together and share God’s truth with their neighbors. Pray that the Lord will strengthen and embolden them as they serve Him.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      