import React from 'react';
import { useLocation } from '@reach/router';

function User(props) {
  const location = useLocation();
  const token = location.search.split("?USER=").pop();

  return <a className="button" href={props.linkUrl+`?USER=`+token}>{props.linkText}</a>;
}

export default User;
