import React from 'react';

const ruEstoniaPrivacyPolicyCopy = () => (
  <div>
    <p>
      Предоставляя нам свои персональные данные (в том числе о ваших религиозных
      взглядах), вы даете согласие на то, чтобы мы использовали их для вашего
      информирования о фестивале «Время надежды» и других служениях BGEA;
      использовали эти данные для вашего участия в мероприятиях BGEA. Эти данные
      будут храниться в BGEA в США и/или ЕЭ (и могут быть переданы для обработки
      нашим партнерам по служению в вашей стране, таких как Center of Hope MTÜ)
      только для вышеуказанных целей. Чтобы настроить обработку ваших
      персональных данных и больше узнать о том, как мы их обрабатываем,
      ознакомьтесь с политикой конфиденциальности BGEA, см.
      <a
        href="https://billygraham.org/privacy-russian/"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Политику конфиденциальности BGEA.
      </a>
      .
    </p>
  </div>
);

export default ruEstoniaPrivacyPolicyCopy;
