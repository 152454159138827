import React from 'react';

const decisionMagazineHardCopy = ({ firstName }) => (
  <div>
    <h2
     className='confirmation-hc'
    >
      Good News {firstName}! Your "Decision Magazine Election Issue" will be delivered to your mailbox soon.
    </h2>
  </div>
);

export default decisionMagazineHardCopy;
