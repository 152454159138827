import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div {...{
        "className": "content-quote"
      }}>{`
    `}<p parentName="div">{`
        “IN OUR CHALLENGING TIMES CHRISTIANS AND CHURCHES MUST JOIN HANDS FOR SPREADING THE GOOD NEWS OF JESUS CHRIST ALL OVER EUROPE AGAIN. I AM LOOKING FORWARD TO THE UPCOMING EUROPEAN CONGRESS ON EVANGELISM AND TRUST IT WILL TRIGGER ENTHUSIASM AND A FRESH COMMITMENT TO DO SO” 
    `}</p>{`
    `}<p parentName="div" {...{
          "className": "author"
        }}>{`
        `}<span parentName="p" {...{
            "className": "has-text-weight-bold"
          }}>{`—ULRICH PARZANY`}</span><br parentName="p"></br>{` Germany
    `}</p>
      </div>
      <div {...{
        "className": "content-quote mt-6"
      }}>{`
    `}<p parentName="div">{`
        “THE CHURCH IN EUROPE NEEDS TO RISE UP WITH FRESH CONFIDENCE.” 
    `}</p>{`
    `}<p parentName="div" {...{
          "className": "author"
        }}>{`
        `}<span parentName="p" {...{
            "className": "has-text-weight-bold"
          }}>{`—DR. HUGH OSGOOD`}</span>{`, `}<br parentName="p"></br>{` Churches in Communities International `}<br parentName="p"></br>{` UK
    `}</p>
      </div>
      <div {...{
        "className": "small-img"
      }}>{`
    `}<figure parentName="div" {...{
          "className": "image"
        }}>{`
        `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "104.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBAH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHmvj0KYDyAoH//xAAbEAABBQEBAAAAAAAAAAAAAAABAAIREiEDEP/aAAgBAQABBQK2G0CXKyPZ0aj7/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRAAAgMBAAAAAAAAAAAAAAAAADEBEBEg/9oACAEBAAY/AkKDafP/xAAdEAEBAAEEAwAAAAAAAAAAAAABABEhMUFhUYGh/9oACAEBAAE/IR+T3Ep+8g4Rr3mIw70hwN7NMLYg7v/aAAwDAQACAAMAAAAQkAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxABAAMBAQEBAAAAAAAAAAAAAQARITFBUXH/2gAIAQEAAT8Q0q36FIiMOkGW787CMcPyOEhCltlKMBR0jEBBvhHHm9yKlqX9n//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "1966 congress large",
              "title": "1966 congress large",
              "src": "/static/54c8c0a04dc93bc333574fa9a2f230b4/e5166/1966_congress_large.jpg",
              "srcSet": ["/static/54c8c0a04dc93bc333574fa9a2f230b4/f93b5/1966_congress_large.jpg 300w", "/static/54c8c0a04dc93bc333574fa9a2f230b4/b4294/1966_congress_large.jpg 600w", "/static/54c8c0a04dc93bc333574fa9a2f230b4/e5166/1966_congress_large.jpg 1200w", "/static/54c8c0a04dc93bc333574fa9a2f230b4/d9c39/1966_congress_large.jpg 1800w", "/static/54c8c0a04dc93bc333574fa9a2f230b4/df51d/1966_congress_large.jpg 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</figure>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      