import { NewNames as NewNamesForm } from '@bgea-js/form-templates';
import RedeemedBookCustomMarkup from "../../../../src/content/redeemed-book/customMarkup";
import RedeemedBookConfirmation from "../../../../src/content/redeemed-book/customConfirmation";
import "../../../../src/content/redeemed-book/redeemed-book.scss";
import * as React from 'react';
export default {
  NewNamesForm,
  RedeemedBookCustomMarkup,
  RedeemedBookConfirmation,
  React
};