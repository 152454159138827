import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationBFV = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
}) => (
  <div>
    <h4>Thank You for Spreading God’s Word in Vietnam</h4>
    <Block>
      Thanks to your generous gift, new Christians in Vietnam will receive their own copy of God’s Word to help them grow in their faith. Pray that as these believers open their Bibles—many for the first time—God will help them understand and apply Scripture to their daily lives.
    </Block>
    <Block>
      The confirmation number for your{' '}
      {showRecurrenceText && <strong>{recurrenceText}</strong>} donation is{' '}
      <strong>{transactionId}</strong> for the amount of $
      {total.toFixed(2) ?? 'NEED AMOUNT'}.
    </Block>
    <Block>
      If you have any questions, call us at 1-877-247-2426. Please have your
      confirmation number ready and indicate that you are contacting us about a
      donation made through the Billy Graham Evangelistic Association website.
    </Block>
    <Block>
      To keep you updated on the ministry, we would like to send you our prayer
      letter, <i>From the Desk of Franklin Graham</i>, featuring biblical truths
      about current events, news of his travels and updates on the work of BGEA
      with thousands of people around the world. You can opt-out from receiving
      these emails anytime.
    </Block>
  </div>
);

export default customConfirmationBFV;
