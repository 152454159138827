import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { GetInvolved as GetInvolvedForm } from '@bgea-js/form-templates';
import ruEstoniaPrivacyPolicyCopy from './ruEstoniaPrivacyPolicyCopy';
import ruEstoniaConfirmation from './ruEstoniaConfirmation';
import ruEstoniaIAACopy from './ruEstoniaIAACopy';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="content-wrapper" id="eeGetInvolvedCopy">
    <div className="margins">
        <div className="content-container">
            <h1 className="top-title">ПРИМИТЕ УЧАСТИЕ</h1>
        </div>
        <div className="text content-container">
            <p>Чтобы привести людей ко Христу, нужны добровольцы, сердца которых горят желанием послужить тем, кто придет на собрания. Добровольцы будут помогать гостям в самых разных сферах. В период подготовки к собраниям добровольцы вместе с другими верующими Эстонии помогают распространять информацию о предстоящем евангелизационном мероприятии.</p>
        </div>
    </div>
    </div>
    <div className="content-wrapper" id="eeGetInvolvedForm">
    <div className="margins">
        <div id="get-involved-form">
            <GetInvolvedForm churchAddressVisibleList={['street', 'stateProvince', 'cityTownRegion', 'zipPostalCode']} churchPhoneType="BUS" collectionType={471} country={['EE']} destination={3} disclaimerOptInText="Настоящим вы даете свое согласие получать регулярные сообщения от ЕАБГ. От них можно отказаться в любое время." displayList={['Email', 'Phone', 'SocialPhones']} emailId="" eventId={"62"} formAttribute="1574" hidePrayerHatOffer={true} hideUserAddressSection iaaAttrValue="1580" languageCode="DEFLANG" lng="ru" optInAttrValue="2100" optInAttribute="2100" orgRoles={{
            '': 'Выберите один',
            'CSP': 'Пастор',
            'CYP': 'Лидер молодежного служения',
            'CHUR/MEM': 'Член церкви'
          }} nameTitles={{
            '': 'Выберите один',
            'Dr.': 'Доктор',
            'Pastor': 'Пастор',
            'Reverend': 'Отец'
          }} phoneRequired={false} phoneType="CELL" requiredChurchList={['OrganizationName', 'OrganizationRole', 'Address']} showAndrewSection={true} showPrefix={false} socialPhones={[{
            isRequired: false,
            label: 'Whatsapp',
            type: 'WHATSAPP'
          }]} useMobilizationAddress={true} useOptinSocialPhoneFields={true} volunteerAttrValue="1232" customConfirmation={ruEstoniaConfirmation} iaaCopy={ruEstoniaIAACopy} privacyPolicyCopy={ruEstoniaPrivacyPolicyCopy} logicIds={['CellToMobile']} mdxType="GetInvolvedForm" />
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      