import { ButtonCollapsible } from '@bgea-js/design-library';
import { LegacyPlanning } from '@bgea-js/form-templates';
import CustomConfirmationLP from "../../../../src/content/legacy-planning-page/CustomConfirmation";
import MainContentLegacy from "../../../../src/content/legacy-planning-page/main-content-legacy.mdx";
import "../../../../src/content/legacy-planning-page/legacy-planning.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  LegacyPlanning,
  CustomConfirmationLP,
  MainContentLegacy,
  React
};