import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentNov from "../../../../src/content/prayer-letter-november/main-content-nov.md";
import LearnMoreNov from "../../../../src/content/prayer-letter-november/learn-more-details-nov.md";
import "../../../../src/styles/prayer-letter-november.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentNov,
  LearnMoreNov,
  React
};