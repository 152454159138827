import React from 'react';

const donationHeaderProdigalChildren = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your "Prodigal Children" is on its way to your
      inbox.
    </h2>
    <br></br>
    <div>
    <p>
      We praise God for your heart to pray for your prodigal children and all
      those far from Him. We pray that these poems encourage you to “
      <i>
        trust in the Lord with all your heart, and do not lean on your own
        understanding. In all your ways acknowledge him, and he will make
        straight your paths
      </i>
      ” (Proverbs 3:5-6, ESV).
    </p>
    <br></br>
    <p>Before you go, there are a few things we’d like you to know…</p>
    <br></br>
    <p>
      God is using friends like you to reach people with His love in a variety
      of ways through the Billy Graham Evangelistic Association, including a
      24-hour prayer line, online Gospel resources, and Billy Graham Rapid
      Response Team (BG-RRT) chaplains, who comfort and pray with hurting people
      in the midst of crisis.
    </p>
    <br></br>
    <p>
      This Gospel ministry relies on friends like you who make evangelistic
      outreaches possible through your prayers and financial support.
    </p>
    <br></br>
    <p>
      When you make a gift today, you can reach people across the globe with the
      message of God’s love. In a time when so many are overcome with anger,
      worry, or hopelessness, the Good News of Jesus Christ is exactly what they
      need to receive. Will you help share it with them?
    </p>
    <br></br>
  </div>
  </div>
);

export default donationHeaderProdigalChildren;