import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
  <p>Dear Friend,</p>
  <p>
    &emsp;Hope is in short supply in our world today. People search for it in money, fame, or power, and they try to fill the void in their lives with possessions or relationships.
  </p>
  <p>
    &emsp;God’s Word is clear, however. Real hope doesn’t come from the things of this world but from being fully surrendered to Jesus Christ. In Romans 15:13, Paul wrote, <em>“Now may the God of hope fill you with all joy and peace in believing, that you may abound in hope by the power of the Holy Spirit”</em> (NKJV).
  </p>
  <p>
    &emsp;Through your prayers and sacrificial support, we’re proclaiming the Good News in the United States and around the world. Thank you for coming alongside us in this eternally important mission—we couldn’t do this without you.
  </p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      