import React from 'react';
import hpCustomModal from "./customModalText"

const customModalHeaderTCRuth = ({ firstName }) => (
  <div>
  <h2>
    Good news{' '} {firstName}! Your  set of Bible trivia cards should deliver in 4 to 6 weeks.
  </h2>
  {/* <br></br> */}
  {hpCustomModal}
</div>
);

export default customModalHeaderTCRuth;
