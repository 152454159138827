import React from 'react';

const eeEstoniaIAA = () => (
  <div>
    <b>Ma olen nagu ANDREAS</b>
    <p>
      Ma soovin olla nagu Andreas ja pühendun ajale koos Jeesusega ning sean
      evangleeliumi jagamise oma elus esikohale. Ma luban Issanda ees:
    </p>
    <p>
      <b>PALVETADA</b> - Kirjutan üles sõbra nime, kes vajab Jeesust, ja
      palvetan tema eest igal päeval.
    </p>
    <p>
      <b>JAGADA</b> - Veedan oma sõbraga koos aega. See süvendab minu sõprust ja
      loob võimalusi Jeesusest rääkimiseks.
    </p>
    <p>
      <b>KAASA VÕTTA</b> - Võtan sõbra festivalile “Lootuse aeg” kaasa. Kui ta
      vastab Kristuse kutsele või ilmutab huvi evangeeliumi vastu, julgustan
      teda jätkuvalt.
    </p>
    <p>
      <b>JÄTKATA</b> - Kutsun sõbra endaga kirikusse kaasa. Jumal võib kasutada
      mind ja minu kogudust selleks, et tugevdada sõbra suhet Jeesusega.
    </p>
  </div>
);

export default eeEstoniaIAA;
