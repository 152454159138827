import React from 'react';

const donationHeaderLastFlightOut = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News! Your link to watch this inspiring Billy Graham film,{' '}
      <em>Last Flight Out</em>, is on its way to your inbox, {firstName}.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart for His truth, and we hope this program
        will fill you with{' '}
        <em>“righteousness and peace and joy in the Holy Spirit”</em> (Romans
        14:17, NASB).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer spiritual
        resources because of friends like you. The Lord is using the gifts and
        prayers of people like you to reach people across the globe with His
        love—and there are so many more who need to hear the Good News.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote className="modalBlockquote">
        Your gift will help share the Gospel with people around the world who
        are far from God and equip them with powerful Biblical resources to
        become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderLastFlightOut;
