import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div id="clwc-section" className="is-hidden">
    <div className="content-wrapper">
        <div className="margins">
            <div className="clwc-content-container">
                <div className="content-container">
                    <h1 className="bottom-title">«ХРИСТИАНСКАЯ ЖИЗНЬ И СВИДЕТЕЛЬСТВО»</h1>
                    <h1 className="top-title">КУРС</h1>
                </div>
                <div className="content-container">
                    <p>Присоединяясь у этому бесплатному курсу (три встречи по одному часу каждая), вы:</p>
                    <ul>
                        <li>освоите библейские принципы, которые помогут вам жить богоугодной жизнью и тем самым свидетельствовать о Боге другим</li>
                        <li>получите практические советы и рекомендации о том, как проповедовать Евангелие</li>
                        <li>узнаете, как вдохновить и поддержать новых христиан в их духовном росте и изучении Слова Божия.</li>
                    </ul>
                    <p>Курс доступен для всех желающих.</p>
                    <p>Встречи проводятся по разным адресам, участие бесплатно.</p>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/d/embed?mid=1bmqCyvk1p14-i0kURL2jOsHSS-Ij1II&ehbc=2E312F" width="640" height="480"></iframe>
        </div>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      