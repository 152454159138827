import './ruth-graham-trivia-cards.scss';

const TriviaCardsCustomMarkup = {
  markup: `<h3 style="text-align:center;">Deepen Your Love for God’s Word</h3>
  <h4 style="text-align:center;">Get your free set of Bible trivia cards inspired by Ruth Bell Graham.</h4>
  <p className="p-content">
  As a lifelong student of God’s Word, Ruth Bell Graham created handwritten trivia 
  cards to teach Scripture to her children. This new resource, based on her cards, 
  will help you point your loved ones to the unchanging truth of the Bible.
  </p>
  <p lassName="p-content">
  When you fill out the form below, we’ll send you a free set of your own Bible trivia cards. 
  We hope this special gift will help you and your family grow closer to God as you “<em>let the 
  word of Christ dwell in you richly</em>” (Colossians 3:16, ESV). 
  </p>
  <p style="margin-bottom:3rem;">
  One per Household - Shipping to US only
  </p>
  `,
};

export default TriviaCardsCustomMarkup;
