import React from 'react';
import hpCustomModal from "./customModalText"

const customModalHeaderHP = ({ firstName }) => (
  <div>
  <h2>
    Good news! Your link to watch <em>The Hiding Place</em> is on its way to your inbox,{' '} {firstName}.
  </h2>
  {/* <br></br> */}
  {hpCustomModal}
</div>
);

export default customModalHeaderHP;
