import React from 'react';

const donationHeaderRecipeCards2 = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your "A Ministry of Hospitality" is on its way to
      your mailbox.
    </h2>
    <p>*Please allow 2-4 weeks for delivery.</p>
    <br></br>
    <div>
      <p>
        We praise God for your heart to serve others through Gospel-centered
        kindness and hospitality. It’s our prayer that this guide will encourage
        you to <em>“serve one another humbly in love”</em> (Galatians 5:13,
        NIV).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer the hope of
        Jesus Christ through materials like this because of friends like you.
        The Lord is using the gifts and prayers of other believers to reach
        people across the globe with His love—and there are so many more who
        need to hear the Good News. Will you give them the opportunity to hear
        it?
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift today will help share the Gospel with people around the world
        who are far from God and equip them with powerful Biblical resources to
        become faithful followers of Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderRecipeCards2;
