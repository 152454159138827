import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "logo"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2020/07/An-Extraordinary-Journey_Logo.png",
        "alt": "Billy Graham- An Extraordinary Journey"
      }}></img>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      