import { GetInvolved as GetInvolvedForm } from '@bgea-js/form-templates';
import ruEstoniaPrivacyPolicyCopy from "../../../../src/content/estonia-host-page/ruEstoniaPrivacyPolicyCopy";
import ruEstoniaConfirmation from "../../../../src/content/estonia-host-page/ruEstoniaConfirmation";
import ruEstoniaIAACopy from "../../../../src/content/estonia-host-page/ruEstoniaIAACopy";
import * as React from 'react';
export default {
  GetInvolvedForm,
  ruEstoniaPrivacyPolicyCopy,
  ruEstoniaConfirmation,
  ruEstoniaIAACopy,
  React
};