import React from 'react';

const cbConfirmation = () => (
  <div>
    <h1 className="title">¡Gracias!</h1>
    <div className="block">
      <p>Tu información ha sido recibida.</p>
    </div>
  </div>
);

export default cbConfirmation;
