import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<span parentName="div" {...{
        "className": "image-float-left-first"
      }}>{`
        `}<span parentName="span" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "400px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABcBAQEBAQAAAAAAAAAAAAAAAAECAAP/2gAMAwEAAhADEAAAAbc0BrFkxlvkq6KxJn//xAAdEAACAgIDAQAAAAAAAAAAAAABAgADETIEEjEz/9oACAEBAAEFAlwtJBdbdjyTh261sweAy7YGJLPonn//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAdEAACAgIDAQAAAAAAAAAAAAAAAQIRECESMVFB/9oACAEBAAY/ApS8LnK8cYx0/Svr7LTEUjQh4//EAB0QAQABBQADAAAAAAAAAAAAAAEAEBEhMXGBkcH/2gAIAQEAAT8haAupj8iDyTVz7NTFz1MJcnpHgSWl5dI23YvNO6x//9oADAMBAAIAAwAAABAk8f3/xAAZEQADAAMAAAAAAAAAAAAAAAAAAREQMUH/2gAIAQMBAT8QUjo9ncf/xAAWEQEBAQAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QRhk//8QAIRAAAgIABwADAAAAAAAAAAAAAREAITFBUWFxocGR0fD/2gAIAQEAAT8QAiJXbo0KjcYACxmVtcFfvijsAgxMoroR/oALyfQlK1NFZPs3GQ+C4QKSoAnmye4oAgEnTzs+CYnJn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "wg 230701 rn2 6396 inet",
            "title": "wg 230701 rn2 6396 inet",
            "src": "/static/f0a90ecae122b285d5f4804d68238986/066f9/wg_230701_rn2_6396_inet.jpg",
            "srcSet": ["/static/f0a90ecae122b285d5f4804d68238986/f93b5/wg_230701_rn2_6396_inet.jpg 300w", "/static/f0a90ecae122b285d5f4804d68238986/066f9/wg_230701_rn2_6396_inet.jpg 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</span>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`
        In a matter of weeks, we’ll celebrate the most pivotal moment in history: Christ’s triumphant victory over the grave. 
        The pain and despair of the world fades in the light of the Savior.
    `}</p>{`
    `}<p parentName="div">{`
        Many people have never heard this Good News and are looking for hope in the things of this world. Only God can satisfy 
        the deepest longings of their hearts. Without Him, there is no true peace.
    `}</p>{`
    `}<p parentName="div">{`
        The need for the Gospel is great, and our Savior, Jesus Christ, is the only hope. 
    `}</p>{`
    `}<p parentName="div">{`
        `}<strong parentName="p">{`God is at work today transforming hearts with the power of His love, and we’re grateful for your vital partnership.`}</strong>{`
    `}</p>{`
    `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "god-loves-you-tour"
      }}><span parentName="h3" {...{
          "className": "category"
        }}>{`GOD LOVES YOU TOUR`}</span></h3>{`
     `}<h4 parentName="div">{`Sharing Hope Along the Border`}</h4>{`
    `}<p parentName="div">{`
      As you read this, my father, Franklin Graham, is in the middle of `}<strong parentName="p">{`the God Loves You Frontera Tour. This 10-city outreach 
      along our southern border`}</strong>{` could not have come at a better time. In the midst of chaos and hopelessness on both sides, the Gospel
       is going forward and many are finding the truth of the Savior. 
    `}</p>{`
      `}<p parentName="div">{`
        Will you join me in praying for the thousands of people who are hearing the Gospel at this moment 
        as the Frontera Tour continues from Texas to California?
    `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      