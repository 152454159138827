import React from 'react';

const donationHeaderFearlessSignup = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your "Fearless" is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart to stand boldly for Him, and we pray that{' '}
        <i>Fearless</i> will encourage you to have fearless faith in a
        compromising culture. Your first email should arrive in your inbox in
        the next couple of weeks.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer materials
        like this because of friends like you. The Lord is using the gifts and
        prayers of believers like you to reach people across the globe with His
        love—and there are so many more who need to hear the Good News.
      </p>
      <p>
        This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <p>Will you give them the opportunity to hear it?</p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift of $50 today will help share the Gospel with people around the
        world who are far from God and equip them with powerful Biblical
        resources to become faithful followers of Christ.
      </blockquote>
      <br></br>
      <p>
        “<i>The people who know their God shall stand firm and take action.</i>”
        –Daniel 11:32, ESV
      </p>
    </div>
  </div>
);

export default donationHeaderFearlessSignup;
