import React from 'react';
import './redeemed-book.scss';

const RedeemedBookConfirmation = () => (
  <div className='callout'>
    <p>
      <strong>Thank you for completing the form!</strong> 
    </p>
    <p>
       You should receive <em>Redeemed</em> in 4 to 6 weeks.
    </p>
    <p>
      May the Lord richly bless you.
    </p>
  </div>
);

export default RedeemedBookConfirmation;
