import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentMay from "../../../../src/content/prayer-letter-may-2024/main-content-may.md";
import LearnMoreMay from "../../../../src/content/prayer-letter-may-2024/learn-more-details-may.md";
import "../../../../src/content/prayer-letter-may-2024/prayer-letter-may.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentMay,
  LearnMoreMay,
  React
};