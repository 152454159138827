import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div id="clwc-section" className="is-hidden">
    <div className="content-wrapper">
        <div className="margins">
            <div className="clwc-content-container">
                <div className="content-container">
                    <h1 className="bottom-title">KRISTLIKU ELU JA TUNNISTUSE</h1>
                    <h1 className="top-title">KURSUS</h1>
                </div>
                <div className="content-container">
                    <p>Tasuta kolmest õppesessioonist koosneva kursuse jooksul:</p>
                    <ul>
                        <li>õpitakse tundma Piibli põhimõtteid, kuidas elada Jumalale meelepärast elu ja juhtida inimesi Jeesuse juurde;</li>
                        <li>antakse praktilisi nõuandeid evangeeliumi kuulutamiseks;</li>
                        <li>õpitakse toetama ja julgustama uusi kristlasi, et nad kasvaksid Jumala sõna alusel.</li>
                    </ul>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/d/embed?mid=1bmqCyvk1p14-i0kURL2jOsHSS-Ij1II&ehbc=2E312F" width="640" height="480"></iframe>
        </div>      
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      