import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<span parentName="div" {...{
        "className": "image-float-left-first"
      }}>{`
        `}<span parentName="span" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "400px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "110.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUEAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABmUJ+0lPSzX0rII//xAAaEAADAQEBAQAAAAAAAAAAAAABAgMAEhMi/9oACAEBAAEFApqHe8JiempDuWMeclvNan563//EABURAQEAAAAAAAAAAAAAAAAAACAh/9oACAEDAQE/AYP/xAAVEQEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAgEBPwGj/8QAHBAAAQQDAQAAAAAAAAAAAAAAAQACEBESITFR/9oACAEBAAY/Ag20SzogEbpOs9MHEb9TY//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExEGFxgf/aAAgBAQABPyFjUFyy1r1u+NzxdEMD1GJeMIBxSXVx8fSf/9oADAMBAAIAAwAAABBv1/8A/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIRD/2gAIAQMBAT8QWNKWrn//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAgEBPxBquEZI3M//xAAdEAEAAwEAAwEBAAAAAAAAAAABABEhQTFx4YGx/9oACAEBAAE/EOhIjwdlTLRqodv5GodaiQ213+yrJUcYPE7h5lOFP7GjC6LQrK9MyNpS319jWUIrgs//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "fg 240901 rp2 521",
            "title": "fg 240901 rp2 521",
            "src": "/static/4c99448b29fd0446b9e96ca416c8e892/066f9/fg-240901_rp2_521.jpg",
            "srcSet": ["/static/4c99448b29fd0446b9e96ca416c8e892/f93b5/fg-240901_rp2_521.jpg 300w", "/static/4c99448b29fd0446b9e96ca416c8e892/066f9/fg-240901_rp2_521.jpg 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</span>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`
         What a thrill it was to see more than 21,000 people pack into the historic Piazza del Plebiscito in Naples, Italy, during the recent Noi Festival.
    `}</p>{`
    `}<p parentName="div">{`
         I told the thousands of men, women, and children gathered that they face a simple choice: to embrace salvation in Jesus Christ or to reject Him. There is no middle ground, and “`}<em parentName="p">{`there is no other name under heaven that has been given among mankind by which we must be saved`}</em>{`” (Acts 4:12, NASB). Hundreds responded to the Gospel by surrendering their lives to the Lord.
    `}</p>{`
    `}<p parentName="div">{`
         Maddalena was among them. She had come with her boyfriend and their young son. Although the couple had to work the day of the Festival, she prayed that they would finish in time to attend. `}<strong parentName="p">{`For weeks, Maddalena had been curious about Jesus Christ.`}</strong>{` They had recently been visiting a local church at the invitation of their friend Enzo, who was serving as a prayer counselor at the Festival. 
    `}</p>{`
    `}<p parentName="div">{`
          She listened to the message closely, and the Holy Spirit worked in her heart. 
    `}<span parentName="p" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEEA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGXRziIiX//xAAZEAADAQEBAAAAAAAAAAAAAAABAgMAIzH/2gAIAQEAAQUCnDBVKkK2jzWjY+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BrX//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAGxAAAgMAAwAAAAAAAAAAAAAAABEBAiEQMUH/2gAIAQEABj8Cl9VMyENQW9Fx/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBcf/aAAgBAQABPyE7W+lexKH2bi3MIX1jQ3Tuy3BQVkWp/9oADAMBAAIAAwAAABCgL//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QSEDOl//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPxACybf/xAAdEAEAAgEFAQAAAAAAAAAAAAABABEhMUFRYXGB/9oACAEBAAE/EGhuaVu2PJUQHbwjEixFJVT0ccS/dqqvlRQBMgdkdwGs/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "naples counsel 240901 6074 web",
              "title": "naples counsel 240901 6074 web",
              "src": "/static/23167412c3b8d7356e7bf1933abaf49c/6a068/naples_counsel_240901_6074_web.jpg",
              "srcSet": ["/static/23167412c3b8d7356e7bf1933abaf49c/f93b5/naples_counsel_240901_6074_web.jpg 300w", "/static/23167412c3b8d7356e7bf1933abaf49c/b4294/naples_counsel_240901_6074_web.jpg 600w", "/static/23167412c3b8d7356e7bf1933abaf49c/6a068/naples_counsel_240901_6074_web.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</span>{`
        “Will you come to Christ tonight?” I asked the audience. Along with many others, she walked forward and prayed to receive Jesus Christ in repentance and faith. “`}<strong parentName="p">{`I felt light and free, and really felt God forgave my sins,” she said with a smile on her face.`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
          Will you pray for her and for each person who made a decision for the Lord? Please also pray for the hundreds of churches that are working hard to disciple these new believers.
    `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      