import { GetInvolved as GetInvolvedForm } from '@bgea-js/form-templates';
import eeEstoniaPrivacyPolicyCopy from "../../../../src/content/estonia-host-page/eeEstoniaPrivacyPolicyCopy";
import eeEstoniaConfirmation from "../../../../src/content/estonia-host-page/eeEstoniaConfirmation";
import eeEstoniaIAACopy from "../../../../src/content/estonia-host-page/eeEstoniaIAACopy";
import * as React from 'react';
export default {
  GetInvolvedForm,
  eeEstoniaPrivacyPolicyCopy,
  eeEstoniaConfirmation,
  eeEstoniaIAACopy,
  React
};