import React from 'react';
import './runningfromgod.scss';

const RFGpoConfirmation = ({ firstName }) => (
  <div className='callout'>
    <h4>
      Great News{' '} {firstName}!
    </h4>
    <p>
    You should receive your set of five copies of “Running From God?” in two to four weeks. We praise Him for your heart for Gospel evangelism and pray this resource will inspire and encourage you to tell others that Jesus Christ is “<em>the way, and the truth, and the life</em>” (John 14:6, ESV).
    </p>
  </div>
);

export default RFGpoConfirmation;
