import React from 'react';

const donationHeaderCLWC = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Good News {firstName}! Your "Christian Life and Witness" is on its way to
      your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God you want to deepen your faith in Him and learn to share it
        with others. It’s our prayer that this series helps you “
        <i>
          always be prepared to give an answer to everyone who asks you to give
          the reason for the hope that you have
        </i>
        ” (1 Peter 3:15, NIV).
      </p>
      <br></br>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <br></br>
      <p>
        God is using the Billy Graham Evangelistic Association and friends like
        you to reach people with His hope in a variety of ways, including a
        24-hour prayer line, online resources, and Billy Graham Rapid Response
        Team (BG-RRT) chaplains, who are ministering in communities impacted by
        natural and man-made disasters.
      </p>
      <br></br>
      <p>
        This Gospel ministry relies on friends like you who make outreaches like
        these possible through your prayers and financial support.
      </p>
      <br></br>
      <p>
        When you make a gift today, the Lord can use you to reach people across
        the globe with His love in tangible ways. There is no greater time than
        now to help provide for the needs of those most vulnerable and to share
        the “good news of His salvation” (Psalm 96:2, NKJV).
      </p>
      <br></br>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift of $50 today will help share the hope of the Gospel with
        people around the world who are far from God.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderCLWC;
