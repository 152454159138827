import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import customConfirmationFGT from "../../../../src/content/franklin-graham-thank-you-video-message-2023/customConfirmation";
import "../../../../src/content/franklin-graham-thank-you-video-message-2023/franklin-graham.scss";
import TextPart from "../../../../src/content/franklin-graham-thank-you-video-message-2023/text.mdx";
import * as React from 'react';
export default {
  SingleDonationForm,
  customConfirmationFGT,
  TextPart,
  React
};