import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><p>{`
    Just before leaving on the Frontera Tour, my father preached the Gospel to more than 43,000
    in Mexico City at an outreach called Esperanza CDMX (Festival of Hope, Feb. 17–18). This was 
    actually the second year in a row that he has proclaimed the Good News in Mexico City. The 2023 
    event was so large that many were unable to get into the overflow venue. When that happened, we 
    recognized the deep spiritual hunger in this world-class city and vowed to return with the hope 
    of the Gospel in 2024.
`}</p>
      <div {...{
        "className": "image-float-left"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABwiqNmDjL/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMEEiH/2gAIAQEAAQUCegzGxc2RxkDFz5P/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFH/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AW1//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERAhAx/9oACAEBAAY/AkoU8KjK1Of/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQTFh/9oACAEBAAE/IcOlmkGgBt1yaVCHJXK3LyF/Ii55mM//2gAMAwEAAgADAAAAEHPv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxAXLF1v/8QAFxEBAQEBAAAAAAAAAAAAAAAAEQABMf/aAAgBAgEBPxAxdi5f/8QAGxABAQACAwEAAAAAAAAAAAAAAREAQSExUWH/2gAIAQEAAT8QN4uo6dL6jiyAgCfRlICeE1hhBNvkDjAjGWyIGqaxoFkxwEz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mexcity counsel 240211 lr1 0586 inet",
            "title": "mexcity counsel 240211 lr1 0586 inet",
            "src": "/static/741e6e0bc13d4902abd29e1db8109995/6a068/mexcity_counsel_240211_lr1_0586_inet.jpg",
            "srcSet": ["/static/741e6e0bc13d4902abd29e1db8109995/f93b5/mexcity_counsel_240211_lr1_0586_inet.jpg 300w", "/static/741e6e0bc13d4902abd29e1db8109995/b4294/mexcity_counsel_240211_lr1_0586_inet.jpg 600w", "/static/741e6e0bc13d4902abd29e1db8109995/6a068/mexcity_counsel_240211_lr1_0586_inet.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      