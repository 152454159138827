import React from 'react';

const donationHeaderLovePoem = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your
      inbox.
    </h2>
    <br></br>
    <div>
      <p>
      We praise God that you want to honor Him in all your relationships and we pray this resource encourages you to love the people in your life with Christ’s generous love.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
      God is using people like you to reach others with His love in many ways through the Billy Graham Evangelistic Association, including a 24-hour prayer line, online resources, and Billy Graham Rapid Response Team (BG-RRT) chaplains, who comfort and pray with hurting people in the midst of crisis.
      </p>
      <p>
      This Gospel ministry relies on friends like you who make evangelistic outreaches possible through your prayers and financial support.
      </p>
      <p>
      When you make a gift today, you can reach people across the globe with the message of God’s love. In a time when so many are overcome with anger, worry, or hopelessness, the Good News of Jesus Christ is exactly what they need to receive. Will you help share it with them?
      </p>
      <blockquote className="modalBlockquote">
      Your gift today will help share the “<em>the everlasting gospel</em>” (Revelation 14:6, NKJV) with people around the world who are looking for hope—and equip them with powerful Biblical resources to become faithful followers of Jesus Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderLovePoem;