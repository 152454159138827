const DailyBiblePlanCustomMarkup = {
  markup: `<h3 style="text-align:center;">We'll send you a digital plan to help you read the Old Testament in one year.</h3>
  <p className="p-content">The Old Testament is filled with many promises on how God will work through your life when you read and study His Word. We have a simple plan that allows you to read the entire Old Testament in one year—and you can begin today.</p>
  <p>
  When you complete the form below, we'll send you a free digital reading plan. It's our prayer that this resource will help you experience more and more how God's Word <em>"is a lamp to my feet and a light to my path"</em> (Psalm 119:105, ESV).
  </p>
  <p style="margin-bottom:3rem;"><input type="checkbox"/> <b>I pledge to read my Bible each day.</b></p>
  `,
};

export default DailyBiblePlanCustomMarkup;
