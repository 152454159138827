import React from 'react';

const donationHeaderStepsToPeace = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart to tell others about the Good News of Jesus
        Christ. It’s our prayer that this practical resource will help equip you
        to share <em>“the word of truth, the gospel which has come to you”</em>{' '}
        (Colossians 1:5-6, NASB).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        God is using friends like you to reach people with His love in a variety
        of ways through the Billy Graham Evangelistic Association, including a
        24-hour prayer line, online Gospel resources, and Billy Graham Rapid
        Response Team (BG-RRT) chaplains, who comfort and pray with hurting
        people in the midst of crisis. This Gospel ministry relies on friends
        like you who make outreaches like these possible through your prayers
        and financial support.
      </p>
      <p>
        When you make a gift today, you can reach people across the globe with
        the message of God’s love. In a time when so many are overcome with
        anger, fear, or hopelessness, the Good News of Jesus Christ is exactly
        what they need to hear. Will you help share it with them?
      </p>
    </div>
  </div>
);

export default donationHeaderStepsToPeace;
